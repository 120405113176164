import { useState, useEffect } from 'react';
import axios from 'axios';

import opa_statistics from './opa_statistics.json'


const allServices = {
  mobile: false,
  interview: false,
  webserviceAssess: false,
  webserviceInterview: false,
}

const useDeployments = (production = false, showVersionInfo = false, refresh = null) => {
  const [deployments, setDeployments] = useState({ loading: true, data: [{ name: '...loading' }] });


  useEffect(() => {
    console.log('deployments triggered', production, refresh, showVersionInfo)

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACK_END_URL}/deployments`,
      headers: { 'Content-Type': 'application/json' },
      data: {
        production: production,
        includeVersion: showVersionInfo
      }

    }).then(res => {
      const handledResponse = handleOpaRestAPIResponse(res)

      let formatedData = [];
      if (!handledResponse.error && handledResponse.data) {
        formatedData = handledResponse.data.map(row => {
          const workspace = row.collections ? row.collections.reduce((previousValue, currentValue, currentIndex) => {
            if (currentIndex === 0) {
              return currentValue;
            } else {
              return previousValue + ', ' + currentValue;
            }
          }, '') : "None"

          const servicesAsString = row.services ? row.services.reduce((previousValue, currentValue, currentIndex) => {
            if (currentIndex === 0) {
              return currentValue;
            } else {
              return previousValue + ', ' + currentValue;
            }
          }, '') : "None"

          const individualServices = row.services ? row.services.reduce((previousValue, currentValue, currentIndex) => {

            return { ...previousValue, [currentValue]: true }
          }, allServices) : {}

          return { ...row, "workspace": workspace, "servicesAsString": servicesAsString, ...individualServices }
        })
      }

      setDeployments({ ...handledResponse, data: formatedData });

    })
  }, [production, refresh]);

  console.log('testing deployments value', deployments)

  return [deployments];
}

const useVersions = (prd, deployment, refresh = null) => {
  const [versions, setVersions] = useState(null);


  useEffect(() => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACK_END_URL}/deploymentVersions`,
      headers: { 'Content-Type': 'application/json' },
      data: {
        production: prd,
        deployment: deployment
      }

    }).then(res => {
      console.log('versions response', res.data)
      setVersions(handleOpaRestAPIResponse(res));
    })
  }, [prd, deployment]);


  return [versions];
}



const useWorkspaces = (prd = false, refresh = null) => {
  const [workspaces, setWorkspaces] = useState(null);


  useEffect(() => {

    if (!workspaces) {

      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BACK_END_URL}/workspaces`,
        headers: { 'Content-Type': 'application/json' },
        data: {
          production: prd,
        }

      }).then(res => {
        console.log('workspaces response', res.data)
        setWorkspaces(handleOpaRestAPIResponse(res));
      })
    }
  }, [prd]);


  return [workspaces];
}


const useStatistics = (metrics, timePeriod, groupBy, actionType, deployments, refresh = null) => {
  const [statistics, setStatistics] = useState({ loading: true, error: false, data: [] });


  useEffect(() => {

    if (metrics.length > 0 && timePeriod.length > 0 && groupBy.length > 0 && actionType.length > 0) {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BACK_END_URL}/statistics`,
        headers: { 'Content-Type': 'application/json' },
        data: {
          metrics: metrics,
          timePeriod: timePeriod,
          groupBy: groupBy,
          actionType: actionType,
          deployments: deployments
        }

      }).then(res => {
        // console.log('statistics response', res)
        setStatistics(handleOpaRestAPIResponse(res, true));
      }).catch(err => {
        setStatistics(handleError(err));
      })
    }
  }, [metrics, timePeriod, groupBy, actionType, deployments]);


  return [statistics];
}

const useOpaStatisticsData = (searchValue, metrics, groupBy, includedActionTypes, timePeriod) => {

  //  console.log('testing seearch values', searchValue, metrics, groupBy, includedActionTypes, timePeriod)
  const typeValues = opa_statistics[searchValue]?.values
  const filteredValues = typeValues?.filter(item => applyFilter(item, metrics, groupBy, includedActionTypes, timePeriod));

  return typeValues ? [{ ...opa_statistics[searchValue], "values": filteredValues }] : [{}];
}


const useOpaDeleteDeployments = (deployments) => {

  const [deletedDeloyments, setDeletedDeployments] = useState([]);


  useEffect(() => {
    console.log('testing the delete deployments hook', deployments)
    if (deployments.length > 0) {

      let transformedDeployments = (deployments.map(deploything => {

        return { ...deploything, waiting: true, error: false, success: false }
      }))

      console.log('testing the delete deployments transformer', transformedDeployments)
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BACK_END_URL}/deleteDeployment`,
        headers: { 'Content-Type': 'application/json' },
        data: {
          deployments: deployments
        }

      }).then(res => {
        console.log('delete response', res)
        setDeletedDeployments(res.data)
        // setDeletedDeployments(handleOpaRestAPIResponse(res, true));
      }).catch(err => {
        setDeletedDeployments(handleError(err));
      })
    }
  }, [deployments]);

  return [deletedDeloyments];
}

const useOpaUpdateDeployments = (deployments, workspace, services) => {

  const [updatedDeployments, setUpdatedDeployments] = useState({ data: [] });


  useEffect(() => {
    console.log('testing the update deployments hook', deployments)
    if (deployments.length > 0 && (workspace.length > 0 || services.length > 0)) {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BACK_END_URL}/updateDeployment`,
        headers: { 'Content-Type': 'application/json' },
        data: {
          deployments: deployments,
          workspaces: workspace,
          services: services
        }

      }).then(res => {
        console.log('update deployment response', res)
        if (res.data && res.data?.name === "Error") {
          setUpdatedDeployments({ error: true, deployments: deployments, data: [] });
        } else if (res.error) {
          setUpdatedDeployments({ error: true, deployments: deployments, data: [] });
        } else {
          setUpdatedDeployments(res);
        }
      }).catch(err => {
        setUpdatedDeployments(err);
      })
    }
  }, [deployments, workspace, services]);

  return [updatedDeployments];
}


const useFetchJson = (ID, ENV) => {

  const [json, setJson] = useState(null);


  useEffect(() => {
    console.log('testing the fetchJson hook', ID, ENV)

    if (ENV) {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BACK_END_URL}/fetchJSON`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          identifier: ID,
          environment: ENV

        }
      }).then(res => {
        console.log('response from fetchJSON', res);
        let formattedJson = JSON.parse(res.data.JSON.JSON_REQ);
        setJson(formattedJson);


      }).catch(thrown => {
        console.log('error', thrown)
      })
    }

  }, [ID, ENV]);

  return json;
}


const useServices = () => {
  return ({
    data: [
      { name: 'Mobile', value: 'mobile' },
      { name: 'Interview', value: 'interview' },
      { name: 'Web Service Assess', value: 'webserviceAssess' },
      { name: 'Web Service Interview', value: 'webserviceInterview' }
    ]
  })
}


const applyFilter = (currentValue, metrics, groupBy, includedActionTypes, timePeriod) => {


  let passedMetricValidation = false;
  let passedGroupByValidation = false;
  let passedIncludedActionTypes = false;
  let passedTimePeriodsValidation = false;

  if (currentValue.validations) {

    // console.log('testing metric valuation', currentValue.validations.find(o => o.metrics), metrics.length > 0)

    if (metrics.length > 0) {
      const metricValidation = currentValue.validations.find(o => o.metrics)
      if (metricValidation) {
        const found = metricValidation.metrics.some(v => metrics.includes(v))
        // console.log('validation test', currentValue, metrics, metricValidation, found)
        passedMetricValidation = found;
      } else {
        // no specificied validation
        passedMetricValidation = true
      }
    } else {
      // current now selection for metrics
      passedMetricValidation = true
    }

    if (groupBy.length > 0) {
      const groupByValidation = currentValue.validations.find(o => o.groupBy)
      // console.log('groupByValidation', groupByValidation, groupBy)
      if (groupByValidation) {
        const found = groupByValidation.groupBy.some(v => groupBy.includes(v))
        passedGroupByValidation = found;
      } else {
        // no specificied validation
        passedGroupByValidation = true;
      }

    } else {
      // current no selection for groupBy
      passedGroupByValidation = true;
    }

    if (includedActionTypes.length > 0) {
      const actionTypeValidation = currentValue.validations.find(o => o.includedActionTypes)
      // console.log('actionTypeValidation', groupByValidation, groupBy)
      if (actionTypeValidation) {
        const found = actionTypeValidation.includedActionTypes.some(v => includedActionTypes.includes(v))
        passedIncludedActionTypes = found;
      } else {
        // no specificied validation
        passedIncludedActionTypes = true;
      }

    } else {
      // current no selection for action type
      passedIncludedActionTypes = true;
    }

    if (timePeriod.length > 0) {
      const timePeriodValidation = currentValue.validations.find(o => o.timePeriod)
      // console.log('groupByValidation', groupByValidation, groupBy)
      if (timePeriodValidation) {
        const found = timePeriodValidation.timePeriod.some(v => timePeriod.includes(v))
        passedTimePeriodsValidation = found;
      } else {
        // no specificied validation
        passedTimePeriodsValidation = true;
      }

    } else {
      // current no selection for groupBy
      passedTimePeriodsValidation = true;
    }

  } else {


    return true
  }

  // console.log(currentValue, passedMetricValidation, passedGroupByValidation, passedIncludedActionTypes, passedTimePeriodsValidation);


  return (passedMetricValidation && passedGroupByValidation && passedIncludedActionTypes && passedTimePeriodsValidation)
}


const handleOpaRestAPIResponse = (res, charts = false) => {


  if (res.data.name === "Error" && res.data.message === "Request failed with status code 400") {

    return { error: true, message: 'Request failed with status code 400', translation: 'the format of the request was invalid' }
  }
  if (res.data.name === "Error") {

    console.log('testing response handler ERROR', res)
    return { error: true, message: 'Request failed with status code 400', translation: 'the format of the request was invalid' }
  } else {
    // console.log('testing response handler Success', { data: res.data, error: false }, { ...res.data, error: false})
    return (!charts ? { data: res.data, error: false } : { ...res.data, error: false })

  }

}

const handleError = error => {
  if (error.response) {
    // Request made and server responded
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    return ({ error: true, message: 'Request failed with status code 400', translation: 'the format of the request was invalid' })
  } else if (error.request) {
    // The request was made but no response was received
    console.log(error.request);
    return ({ error: true, message: 'Request failed because i got not response', translation: 'I heard nothing back' })
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    return ({ error: true, message: 'Request failed', translation: 'Possible Network Error' })
  }
}

const useDataModel = (serviceName, populateDefaultOutcomes = false, reset = false) => {
  const [dataModel, setDataModel] = useState({ loading: true });
  const [outcomes, setOutcomes] = useState([]);


  useEffect(() => {
    if (serviceName !== null && serviceName !== '') {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BACK_END_URL}/datamodel`,
        headers: { 'Content-Type': 'application/json' },
        data: {
          serviceName: serviceName,
          populateDefaultOutcomes: populateDefaultOutcomes
        }

      }).then(res => {
        console.log('testing datamodel response', res)
        if (populateDefaultOutcomes) {
          setDataModel(res.data.dataModel)
          setOutcomes(res.data.defaultOutcomes)
        } else {
          setDataModel(res.data);
        }

      })
    }
  }, [serviceName, populateDefaultOutcomes, reset]);


  return [dataModel, outcomes];


}


const useOpaInterview = (serviceName, investigateUrl, seedData, params, submission) => {


  const [interviewResponse, setInterviewResponse] = useState({
    investigateUrl: '',
    items: [],
    position: 0,
    loading: true,
  });

  useEffect(() => {
    if (serviceName !== null && serviceName !== '') {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BACK_END_URL}/chatinterview`,
        headers: { 'Content-Type': 'application/json' },
        data: {
          investigateUrl: investigateUrl,
          submission: submission,
        }

      }).then(res => {
        console.log('testing interview response', res)
        if (res.status = 200) {
          setInterviewResponse({
            investigateUrl: res.data.investigateUrl,
            items: res.data?.items ? res.data.items : [],
            position: res.data.position,
            loading: false,
          })

        }

      })
    }

  }, [serviceName, investigateUrl]);

  return [interviewResponse]
}

export { useOpaInterview, useDeployments, useWorkspaces, useStatistics, useOpaStatisticsData, useVersions, useOpaDeleteDeployments, useOpaUpdateDeployments, useServices, useFetchJson, useDataModel }