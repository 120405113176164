import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import Grow from '@mui/material/Grow';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import WorkspaceMenu from './WorkspaceMenu';
import ServiceMenu from './ServicesMenu';




// import GroupWorkIcon from '@mui/icons-material/GroupWork';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

export default function DeploymentDrawerListItems(props) {

    const [bulkChange, setBulkChange] = React.useState(false);
    const [massDelete, setMassDelete] = React.useState(false);
    const [massUpdateWorkspace, setMassUpdateWorkspace] = React.useState(false);
    const [massUpdateServices, setMassUpdateServices] = React.useState(false);
    const [versionInfo, setVersionInfo] = React.useState(false);
    const [individualService, setIndividualService] = React.useState(false);
    const [hasWorkspaceValue, setHasWorkspaceValue] = React.useState(false);
    const [hasServiceValue, setHasServiceValue] = React.useState(false);


    const handleBulkChangeToggle = () => {
        setBulkChange(!bulkChange);
        props.setBulkUpdateFlag(!bulkChange)

    }

    const handleDeleteToggle = () => {
        setMassDelete(!massDelete);
        setMassUpdateWorkspace(false);
        setMassUpdateServices(false);

    }

    const handleUpdateWorkspaceToggle = () => {
        setMassUpdateWorkspace(!massUpdateWorkspace);
        setMassDelete(false);
        props.setSelectedWorkspace([]);
        // setMassUpdateServices(false);

    }

    const handleUpdateServicesToggle = () => {
        setMassUpdateServices(!massUpdateServices);
        setMassDelete(false);
        props.setSelectedServices([]);
        // setMassUpdateWorkspace(false);

    }

    const handleSetVersionChangeToggle = () => {
        console.log('setting show version stuff', !versionInfo)
        setVersionInfo(!versionInfo)
        props.setShowVersionInfo(!versionInfo)
    }

    const handleSetShowIndividualServicesChangeToggle = () => {
        console.log('setting show individual services', !individualService)
        setIndividualService(!individualService)
        props.setShowIndividualServices(!individualService)
    }
    

    return (

        <Box sx={{ flexGrow: 1, paddingTop: '1em' }}>
            <React.Fragment>
                <ListSubheader component="div" inset>
                    <Typography>Actions</Typography>
                </ListSubheader>
                <ListItem sx={{ pl: 4 }}>
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={bulkChange} onChange={handleBulkChangeToggle} value />} label="Bulk Update" />
                    </FormGroup>
                </ListItem>
                <Divider sx={{ my: 1, display: bulkChange ? 'flex' : 'none' }} />
                <ListSubheader component="div" sx={{ display: bulkChange ? 'flex' : 'none' }}>
                    
                    <Typography>Bulk Action Types:</Typography>
                </ListSubheader>
                <Grow in={bulkChange} mountOnEnter unmountOnExit>

                    <ListItem sx={{ pl: 4 }}>

                        <FormGroup>
                            <FormControlLabel control={<Switch checked={massDelete} onChange={handleDeleteToggle} />} label="Delete" />
                            <FormControlLabel control={<Switch checked={massUpdateWorkspace} onChange={handleUpdateWorkspaceToggle} />} label="Change Workspace" />
                            <FormControlLabel control={<Switch checked={massUpdateServices} onChange={handleUpdateServicesToggle} />} label="Change Services" />
                        </FormGroup>

                    </ListItem>
                </Grow>
                <Grow in={massUpdateWorkspace} mountOnEnter unmountOnExit>
                    <ListItem>
                        <WorkspaceMenu setSelectedWorkspace={props.setSelectedWorkspace} setHasWorkspaceValue={setHasWorkspaceValue}/>
                    </ListItem>
                </Grow>
                <Grow in={massUpdateServices} mountOnEnter unmountOnExit>
                    <ListItem>
                        <ServiceMenu setSelectedServiceValues={props.setSelectedServices} setHasServiceValue={setHasServiceValue}/>
                    </ListItem>
                </Grow>

            </React.Fragment>
            <Grow in={massDelete || massUpdateWorkspace || massUpdateServices} mountOnEnter unmountOnExit>
                <Grid
                    container
                    columns={1}
                    spacing={1}
                    direction="column"
                    justifyContent="center"
                    alignItems="center">
                    <Grow in={massDelete} mountOnEnter unmountOnExit>
                        <Grid item style={{ width: "80%" }} >
                            <Button
                                variant="contained"
                                tooltip='Click to delete selected deployments'
                                disabled={!props.hasItems}
                                fullWidth
                                style={{ paddingLeft: '1em', paddingRight: '1em', width: '100%', minWidth: '5%' }}
                                onClick={() => props.deleteDeploymentsFunction()}
                            >
                                {!props.isDrawerOpen ?
                                    <div align='center'><DeleteIcon /></div> :
                                    <Typography align='center'>DELETE Deployments</Typography>
                                }
                            </Button>
                        </Grid>
                    </Grow>
                    <Grow in={massUpdateWorkspace || massUpdateServices} mountOnEnter unmountOnExit>
                        <Grid item style={{ width: "80%" }} >
                            <Button
                                variant="contained"
                                tooltip='Click to update workspace or service for selected deployments'
                                disabled={!props.hasItems || (massUpdateWorkspace && !hasWorkspaceValue) || (massUpdateServices && !hasServiceValue)}
                                fullWidth
                                style={{ paddingLeft: '1em', paddingRight: '1em', width: '100%', minWidth: '5%' }}
                                onClick={() => props.updateDeploymentWorkspaceFunction()}
                            >
                                {!props.isDrawerOpen ?
                                    <div align='center'><AddIcon /></div> :
                                    <Typography align='center' >Update Deployments</Typography>
                                }
                            </Button>
                        </Grid>
                    </Grow>
                </Grid>
            </Grow>
            <Divider sx={{ my: 1 }} />
            <React.Fragment>
                <ListSubheader component="div" inset>
                    <Typography>Display</Typography>
                </ListSubheader>
                <ListItem sx={{ pl: 4 }}>
                    <FormGroup>
                        <FormControlLabel control={<Switch defaultChecked checked={individualService} onChange={handleSetShowIndividualServicesChangeToggle} />} label="Individual Services" />
                        <FormControlLabel control={<Switch checked={versionInfo} onChange={handleSetVersionChangeToggle} />} label="Latest Version" />
                    </FormGroup>
                </ListItem>
            </React.Fragment>

        </Box>




    );
}
