import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MaterialTable from '@material-table/core';
import CaseDetails from './CaseDetails'
import FileDownload from '../FileDown/FileDownload'





function CaseSearch(props) {
    //   const classes = useStyles();

    const columns = [
        {
            field: 'download', title: 'ID', render: (rowData) => <FileDownload JSON_ID={rowData.ID} CASE_NAME={rowData.CASE_NAME} ENV={props.ENV} PROGRAM={rowData.PGM_CODE} />

        },
        { title: 'Includes Response', field: 'HAS_RESPONSE'},
        { title: 'Serial Number', field: 'SERIAL_NUM_IDENTIF' },
        { title: 'Case Name', field: 'CASE_NAME' },
        { title: 'Program', field: 'PGM_CODE' },
        { title: 'Created On', field: 'CREATED_ON', type: "datetime", dateSetting: { locale: 'en-US' } },
        { title: 'Begin Date', field: 'BEG_DATE', type: "date", dateSetting: { locale: 'en-US' } },
        { title: 'End Date', field: 'END_DATE', type: "date", dateSetting: { locale: 'en-US' } },
        { title: 'Error', field: 'ERROR_DESCR'},


    ];


    // console.log('testing data from prop', props.data['newJson']);

    let testIsMatched = (currentListOfMatchedAttributes, runningSearchResults, attribute) => {

        console.log('is match test', currentListOfMatchedAttributes, runningSearchResults, attribute)

        let uniqueInstancesForSearchAttribute = Object.keys(runningSearchResults[attribute]).reduce((previous, current) => {
            console.log('testing uniqueInstancesForSearchAttribute', previous, runningSearchResults[attribute][current])
            return [...previous, runningSearchResults[attribute][current].parentId]

        }, []);

        console.log('did we reduce the list of parentIds?', uniqueInstancesForSearchAttribute)

        let otherAttributesForSameParentSearchAttribute = Object.keys(runningSearchResults).reduce((previous, current) => {
            console.log('testing otherAttributesForSameParentSearchAttribute', previous, current, runningSearchResults[current])
            if(current === attribute) {
                console.log('we in here checking stuff', runningSearchResults[current])
                for(let instanceInAttribute in runningSearchResults[current]) {
                    console.log('we in here checking other stuff', runningSearchResults[current][instanceInAttribute], runningSearchResults[current][instanceInAttribute].parentId)
                    if(runningSearchResults[current][instanceInAttribute].parentId) {
                        if(uniqueInstancesForSearchAttribute.indexOf(runningSearchResults[current][instanceInAttribute].parentId) >= 0) {
                            return [...previous, current]
                        }
                    } else {
                        return previous
                    }

                }
            }else {
                return previous
            }
        }, []);

        console.log('did we reduce the list of other Attributes on the same parent?', otherAttributesForSameParentSearchAttribute)

        let listOfMatchedParents = [];
        otherAttributesForSameParentSearchAttribute.forEach( (attribute, index) => {
            if(index === 0) {
                listOfMatchedParents = Object.keys(currentListOfMatchedAttributes[attribute]).reduce((previous, match) => {
                    return [...previous,  currentListOfMatchedAttributes[attribute][match]]
                }, [])
            } else {
                let nextListOfParents = Object.keys(currentListOfMatchedAttributes[attribute]).reduce((previous, match) => {
                    return [...previous,  currentListOfMatchedAttributes[attribute][match]]
                }, [])

                listOfMatchedParents= listOfMatchedParents.filter(element => nextListOfParents.includes(element));
            }
            console.log('first collect all the first match parents for this attribute', index, listOfMatchedParents);

            
        })

        
        return listOfMatchedParents.length > 0;
    }

    let filteredCaseData = props.data.cases.filter(item => {
        // console.log('filter items', props.searchAttributes)
        // console.log('item', item)
        let allAttributes = item.CASE_DATA;
        let returnItem = false;

        let currentListOfMatchedAttributes = {};

        // want to keep track of what's been found.
        let runningSearchResults = {
            ...props.searchAttributes
        }

        for (let attr in allAttributes) {
            // console.log('logic test', props.searchAttributes[allAttributes[attr].Attribute])

            // check to see if item is in Attributes to be searched list
            // DO WE CARE
            if (props.searchAttributes[allAttributes[attr].Attribute]) {
                // found a matching attribute.  Need to make sure all searching attributes are matched.  
                // console.log('search Attribute values', props.searchAttributes[allAttributes[attr].Attribute]);

                // look through each instance of this attribute.  For now assume this is an OR
                // DOES IT MATCH
                loop2:
                for (let propValue in props.searchAttributes[allAttributes[attr].Attribute]) {
                    console.log('compare test', String(allAttributes[attr].Value).toLowerCase().indexOf(String(props.searchAttributes[allAttributes[attr].Attribute][propValue].value).toLowerCase()), String(props.searchAttributes[allAttributes[attr].Attribute][propValue].value), String(allAttributes[attr].Value))
                    if (String(allAttributes[attr].Value).toLowerCase().indexOf(String(props.searchAttributes[allAttributes[attr].Attribute][propValue].value).toLowerCase()) > -1) {
                        // no need to check further, this value did not match the search criteria
                        console.log('matched with this instance', allAttributes[attr])
                        let alreadyMatchedEntities = currentListOfMatchedAttributes[allAttributes[attr].Attribute] ? currentListOfMatchedAttributes[allAttributes[attr].Attribute] : [];



                        if (allAttributes[attr].parentId) {
                            currentListOfMatchedAttributes = {
                                ...currentListOfMatchedAttributes,
                                [allAttributes[attr].Attribute]: [...alreadyMatchedEntities, allAttributes[attr].parentId]
                            }

                            if (testIsMatched(currentListOfMatchedAttributes, runningSearchResults, allAttributes[attr].Attribute)) {
                                delete runningSearchResults[allAttributes[attr].Attribute];
                                break loop2;
                            }

                        } else {
                            currentListOfMatchedAttributes = {
                                ...currentListOfMatchedAttributes,
                                [allAttributes[attr].Attribute]: true
                            }

                            delete runningSearchResults[allAttributes[attr].Attribute];
                            break loop2;
                        }

                    }
                }

            } else {

                // console.log('testing match object builder', currentListOfMatchedAttributes, runningSearchResults);
                if (Object.keys(runningSearchResults).length === 0) {
                    return true
                }
            }
        }
        return returnItem
    })

    // console.log('testing filtered items', filteredCaseData)



    useEffect(() => {

        // console.log('looking for changed values', props.searchAttributes );

    }, []);

    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <MaterialTable
                        columns={columns}
                        title='EDBC Results'
                        data={filteredCaseData}
                        detailPanel={[
                            {
                                render: rowData => {
                            //    console.log("rowData: ",  props.ENV, rowData.rowData.ID);
                                    return (
                                        <CaseDetails
                                            json={rowData.rowData.CASE_DATA}
                                            service={props.service}
                                            environment={props.ENV}
                                            identifier={rowData.rowData.ID}
                                            program={rowData.rowData.PGM_CODE}
                                            filteringEnabled={props.filteringEnabled}
                                        />
                                    )
                                }
                            }

                        ]}

                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default CaseSearch;