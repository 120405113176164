import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
const axios = require('axios');

export default function FileDownload(props) {

    const downloadFile = ({ data, fileName, fileType }) => {
        // Create a blob with the data we want to download as a file
        const blob = new Blob([data], { type: fileType })
        // Create an anchor element and dispatch a click event on it
        // to trigger a download
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }


    let SearchJSONObject = ( json, value='CreatedBy') => {

        for(let key in json) {


            // console.log('testing value', key, json[key])

            if(key.indexOf(value) > -1 && props.PROGRAM != 'KEES_EDBC_CASH_ASSIST') {
                // console.log('found CreatedBy', json[key])
                json[key] = `${json[key]}`
            }

            if(key.indexOf('persKdolEmpSerialIdentif') > -1) {
                json[key] = `${json[key]}`
            }

            if(key.indexOf('persTalxEmployerFreqCde') > -1) {
                json[key] = `${json[key]}`
            }

            if(key.indexOf('lieapSeason') > -1) {
                json[key] = `${json[key]}`
            }

            if(Array.isArray(json[key])) {
                if(json[key].length > 0) {
                    for(let row in json[key]) {
                        // console.log('found a new entity', json[key][row])
                        json[key][row] = SearchJSONObject(json[key][row])
                    }
                }
            }
        }

        return json
    }


    const fetchJsonFromId = async (ID, ENV) => {

        
        axios({
        method: 'post',
        url: `${process.env.REACT_APP_BACK_END_URL}/fetchJSON`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            identifier: ID,
            environment: ENV

        }
        }).then(res => {
        console.log('response from fetchJSON', res);
        let formattedJson = JSON.parse(res.data.JSON.JSON_REQ);
            
        formattedJson.cases = formattedJson.cases.map(item => {
            // console.log(item);
            // if(item.programsAuthorizedEdbcs) {
            //     console.log("found auth edbc", item.programsAuthorizedEdbcs.length)
            //     if(item.programsAuthorizedEdbcs.length > 0) {
            //         item.programsAuthorizedEdbcs.map(auth => {
            //             let newVal = auth.pgmAuthEdbcCreatedBy
            //             auth.pgmAuthEdbcCreatedBy = `${newVal}`;
            //             console.log('new auth', auth)
            //         })
            //     }
            // }
            item = SearchJSONObject(item);
            return item
            })

        downloadFile({
            data: JSON.stringify(formattedJson),
            fileName: `${props.CASE_NAME}_${props.PROGRAM}.json`,
            fileType: 'text/json',
        })

        }).catch(thrown => {
        console.log('error', thrown)
        })
    }




    const exportToJson = async (e) => {
        e.preventDefault()
 
        // console.log(formattedJson);
        
        
        if(props.JSON) {
            let formattedJson = null
            formattedJson = JSON.parse(props.JSON);
            formattedJson.cases = formattedJson.cases.map(item => {
                // console.log(item);
                // if(item.programsAuthorizedEdbcs) {
                //     console.log("found auth edbc", item.programsAuthorizedEdbcs.length)
                //     if(item.programsAuthorizedEdbcs.length > 0) {
                //         item.programsAuthorizedEdbcs.map(auth => {
                //             let newVal = auth.pgmAuthEdbcCreatedBy
                //             auth.pgmAuthEdbcCreatedBy = `${newVal}`;
                //             console.log('new auth', auth)
                //         })
                //     }
                // }
                item = SearchJSONObject(item);
                return item
         })

            downloadFile({
                data: JSON.stringify(formattedJson),
                fileName: `${props.CASE_NAME}.json`,
                fileType: 'text/json',
            })

        } else {
            fetchJsonFromId(props.JSON_ID, props.ENV)
        }
        
    }
    return (

        <button
            type="button"
            onClick={exportToJson}
        >
           <DownloadIcon />
        </button>

    );
}