import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';

function preventDefault(event) {
  event.preventDefault();
}

export default function ReportStats(props) {

  const { statistics } = props



  const totalValues =  statistics?.data.reduce((previousValue, data) => { 
    return data.value + previousValue
  }, 0);


  const averageValues =  statistics?.data.filter(row => row.value > 0).reduce((previousValue, data) => { 
    return data.value + previousValue
  }, 0) / statistics?.data.filter(row => row.value > 0).length;
  
  // console.log('testing data', statistics)

  let earliest = '';
  let latest = '';

  const sorted = statistics?.data.sort( (record, otherRecord) =>  record.category > otherRecord.category )
  if(sorted.length > 0) {
    earliest = sorted[0].category;
    latest = sorted[sorted.length -1 ].category;
  }


  // console.log('total value test', totalValues, 'average', averageValues, earliest, latest)
  console.log(statistics)

  const shouldShowAverage = statistics.metricTitle.indexOf('rate') >= 0;

  const noDataText = sorted.length > 0;

  const errorHeadingText = 'N/A'

  const averageOrTotalHeadingText = shouldShowAverage ? "Average Rate:" + averageValues.toFixed(2) : "Total: " + totalValues.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;

  

  const errorRangeText = 'There is no data for these deployments'

  const rangeText = noDataText ?" between " + earliest + " and " + latest : errorRangeText

  const headingText = noDataText ? averageOrTotalHeadingText : errorHeadingText

  return (
    <React.Fragment>
      <Title>Calculations</Title>
      <Typography component="p" variant="h4">
        {headingText}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {rangeText}
      </Typography>
      {/* <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          View balance
        </Link>
      </div> */}
       <Typography color="text.secondary" sx={{ flex: 1 }}>
        {averageOrTotalHeadingText}
      </Typography>
    </React.Fragment>
  );
}
