import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import rulesData from "../../data/rulebases.json";


function RulebaseSelector(props) {


    const handleChangeService = (event, value) => {
        if (props.setRulebaseName) {
            if (value === null) {
                props.setRulebaseName("");
            } else {
                props.setRulebaseName(value.key);
            }
        }

    };



    return (
        <Box sx={{ minWidth: '10em', maxWidth: '25em' }}>
            <Box >
                <Autocomplete
                    disablePortal
                    id="combo-box-serv-one"
                    onChange={(event, value) => handleChangeService(event, value)}
                    isOptionEqualToValue={(option, value) => option.key === value.key}
                    options={[
                        ...rulesData[0].rulebases.map((rulebase) => (
                            {
                                "label": rulebase,
                                "key": rulebase
                            }
                        ))]
                    }
                    renderInput={(params) => <TextField {...params} label="Select Rulebase" />}

                >
                </Autocomplete>
            </Box>

        </Box>
    );
}

export default RulebaseSelector;