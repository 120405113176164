import React from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

let SizeSelector = (props) => {
   
    const [anchorElLimit, setAnchorElLimit] = React.useState(null);
    const openLimit = Boolean(anchorElLimit); 
    const [caseLimit, setCaseLimit] = React.useState(props.defaultStart);


    const handleClickLimit = (event) => {
        setAnchorElLimit(event.currentTarget);
    };

    const handleCloseLimit = () => {
        setAnchorElLimit(null);
    };

    const setCaseLimitFromLimit = (env) => {
        setCaseLimit(env);
        props.caseLimitSetter(env);
        handleCloseLimit();
      }

    return (

        <div style={{ display: 'flex' }} >

            <Button
                id="basic-limit"
                aria-controls="basic"
                aria-haspopup="true"
                aria-expanded={openLimit ? 'true' : undefined}
                disabled={props.disabled}
                onClick={handleClickLimit}
            >

                {`${props.beforeText ? props.beforeText : ''} ${caseLimit} ${props.text ? props.text : ''}`}
            </Button>

            <Menu
                style={{ alignItems: 'center', display: 'flex' }}
                id="basic-menu-limit"
                anchorEl={anchorElLimit}
                open={openLimit}
                onClose={handleCloseLimit}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => setCaseLimitFromLimit(200)}>200</MenuItem>
                <MenuItem onClick={() => setCaseLimitFromLimit(500)}>500</MenuItem>
                <MenuItem onClick={() => setCaseLimitFromLimit(1000)}>1000</MenuItem>
                <MenuItem onClick={() => setCaseLimitFromLimit(1500)}>1500</MenuItem>
                <MenuItem onClick={() => setCaseLimitFromLimit(2000)}>2000</MenuItem>
            </Menu>
        </div>
    )
}

export default SizeSelector;