import * as React from 'react';
import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const praseMonthFormattedDate = value => {
   
    return new Date(value+'-01T01:03:24');
}



export default function MonthPicker(props) {
    const { date, setNewValue, month, hour } = props
    const [value, setValue] = React.useState(month ? praseMonthFormattedDate(date) : date);

    const handleOnChange = newValue => {
        //  console.log('testing change', newValue)
        setValue(newValue)
        setNewValue(newValue)
    }


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            { !hour ?
            <DatePicker
                inputFormat={month ? "yyyy-MM" : "yyyy-MM-dd"}
                mask={"____-__-__"}
                views={month ? ['year', 'month'] : ['year', 'month', 'day']}
                label="Custom input"
                minDate={new Date().setFullYear(new Date().getFullYear() - 2)}
                maxDate={new Date()}
                value={value}
                onChange={(newValue) => handleOnChange(newValue)}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '.5em'}}>
                        <input ref={inputRef} {...inputProps} />
                        {InputProps?.endAdornment}
                    </Box>
                )}
            />
            :
            <DateTimePicker

            label="Custom input"
            views={['year','month', 'day', 'hours']}
            inputFormat='yyyy/MM/dd hh:mm'
            mask='____/__/__ __:__'
            openTo='day'
            minDate={new Date().getDay(new Date().getDay() - 30)}
            maxDate={new Date()}
            value={value}
            onChange={(newValue) => handleOnChange(newValue)}
            renderInput={({ inputRef, inputProps, InputProps }) => (
                <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '.5em'}}>
                    <input ref={inputRef} {...inputProps} />
                    {InputProps?.endAdornment}
                </Box>
            )}
        />

        
        }
        </LocalizationProvider>
    );
}
