import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SingleSelect(props) {

  const {  updateValue, values } = props;

  const [selection, setSelection] = React.useState('');


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    let selection = values.values.filter(row => row.value === value)
    setSelection(value);
    updateValue(selection);
  };


  const menuItems =
    values.values
      .map(item => {
        return (<MenuItem key={"menut_itm_" + item.value} value={item.value}>{item.name}</MenuItem>)
      })

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="simple-select-helper-label">{values.name}</InputLabel>
        <Select
          labelId="simple-select-helper-label"
          id="simple-select-helper"
          value={selection}
          label={'Select an item'}
          onChange={handleChange}
        >
          {menuItems}
        </Select>
        <FormHelperText>{values.helpText}</FormHelperText>
      </FormControl>
    </div>
  );
}
