import { useEffect, useState } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DataModel from '../DataModel/DataModel';
import AdvancedDifferenceViewer from './AdvancedDifferenceViewer';
import AdvancedServices from './AdvancedServices';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

export default function AdvancedDialog(
    { open,
        close,
        type,
        services,
        outcomes,
        setOutcomes, // setOutcomes if datamodel
        deploymentName,
        differences
    }) {



    const [collector, setCollector] = useState('');
    const [service, setService] = useState(null);
    const [changeService, setChangeService] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [fullWidth, setFullWidth] = useState(false);
    const [tempOutcomes, setTempOutcomes] = useState(outcomes);
    const [popDefault, setPopDefault] = useState(false);

    const handleClose = () => {
        close();
        setPopDefault(false);
    };

    const handleSave = () => {
        setOutcomes(tempOutcomes)
        setPopDefault(false)
        close();
    };

    const handleSetChangeService = (value) => {
        setChangeService(false)
        setService(value)
    }

    const handleChangeService = () => {
        setChangeService(true);
        setService(null)
    }

    const handleUpdateDefault = () => {
        console.log('yes you clicked the button', popDefault);
        setPopDefault(true);
    }

    useEffect(() => {
        console.log('look this thing changed!', popDefault)
    }, [popDefault])

    useEffect(() => {
        console.log('here we are in use effect', type)
        if (type === 'attribute') {
            // first identify which deployment to fetch a datamodel for
            if (service === null) {
                console.log('testing value of services', services)
                if (services.filter(serv => serv !== null)?.length === 1 && !changeService) {
                    handleSetChangeService(services.filter(serv => serv !== null)[0])
                } else {
                    setFullWidth(false);
                    setDialogTitle('Select which service to use');
                    setCollector(
                        <AdvancedServices services={services} setService={handleSetChangeService} />
                    )
                }

            }
            else {
                setFullWidth(true);
                setDialogTitle(
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            textTransform: 'capitalize',
                            '& > :not(style)': { m: 1 },
                        }}>
                        <Button startIcon={<EditIcon />} onClick={handleChangeService} />
                        <Typography>{`${service.toUpperCase()}`}</Typography>
                        <Button onClick={handleUpdateDefault}><Typography align='right'>Populate Default Outcomes</Typography></Button>
                    </Box>

                );
                setCollector(
                    <DataModel
                        service={service}
                        setService={setService}
                        outcomes={tempOutcomes}
                        setOutcomes={setTempOutcomes}
                        setSearchAttribute={{}} /* Need to actuall pass that in */
                        currentSearchAttributes={{}} /* Need to actuall pass that in */
                        populateDefaults={popDefault}
                        setPopulateDefaults={setPopDefault}
                    />
                )
            }

        } else if (type === 'extractData'){
            setFullWidth(true);
            setDialogTitle('Complex Differences Extraction');
            setCollector(
                <AdvancedDifferenceViewer
                    differences={differences}
                    services={services}
                />
            )
        }

    }, [type, services, service, popDefault]);



    return (
        <Dialog onClose={handleClose} open={open} maxWidth='xl' fullWidth={fullWidth}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <Divider />
            <DialogContent>
                {collector}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave}>Save</Button>   {/* add logic to disable if no changes selected  */}
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
