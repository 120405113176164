

import React, { forwardRef, useEffect, useState, useRef, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MaterialTable from '@material-table/core';
import { ExportCsv } from '@material-table/exporters';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ReactDiffViewer from 'react-diff-viewer-continued';

// import { bendiserverer } from '../data'
import { AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn } from "@mui/icons-material";
const axios = require('axios');
const CancelToken = axios.CancelToken;
let source = CancelToken.source();



const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function DatabaseCompare(props) {
    const [externalDiffs, setExternalDiffs] = useState([]);
    const [externalData, setExternalData] = useState([]);
    const [errorResponse, setErrorResponse] = useState(null);
    const [totalCases, setTotalCases] = useState(props.numberOfCases);
    const [nextButtonEnabled, setNextButtonEnabled] = useState(false)

    const [requestedCases, setRequestedCases] = useState(props.startLimit);

    const [expanded, setExpanded] = React.useState(false);

    const isMounted = useRef(false);

    const [selectedCaseRows, setSelectedCaseRows] = useState([]);
    const [selectedDiffRows, setSelectedDiffRows] = useState([]);

    const [fewerCasesThanRequested, setFewerCasesThanRequested] = useState(false);

    let [reqNum, setReqNum] = useState(1);


    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    let caseColumns = [
        { title: 'EDBC Id', field: 'ID' },
        { title: 'Case Name', field: 'CASE_NAME' },
        { title: 'Program Type', field: 'PGM_CODE' },
        { title: 'Serial Number', field: 'CASE_NUMBER' },
        { title: 'Benefit Month', field: 'BENEFIT_MONTH', type: "date", dateSetting: { locale: 'en-US' } },
        { title: 'Primary NOA Type', field: 'PRIMARY_NOA_TYPE' },
        { title: 'Primary NOA ID', field: 'PRIMARY_NOA_ID' },
        { title: 'Seconday NOA ID', field: 'SECONDARY_NOA_ID' },
    ];

    let diffColumns = [
        { title: 'Identifier', field: 'case_id' },
        { title: 'Case Name', field: 'caseName' },
        { title: 'Attribute', field: 'attribute', defaultGroupOrder: 0 },
        { title: 'Primary', field: 'lhs' },
        { title: 'Seconday', field: 'rhs' }
    ];

    const exportColumns = [
        {
            field: "caseName",
            tableData: {
                additionalWidth: 0,
                columnOrder: 0,
                filterValue: undefined,
                groupOrder: undefined,
                groupSort: "asc",
                id: 0,
                initialWidth: "calc((100% - 0px) / 4)",
                width: "calc((100% - 0px) / 4)",
                widthPx: NaN
            },
            title: "Case Name"
        },
        {
            field: "serialNum",
            tableData: {
                additionalWidth: 0,
                columnOrder: 1,
                filterValue: undefined,
                groupOrder: undefined,
                groupSort: "asc",
                id: 0,
                initialWidth: "calc((100% - 0px) / 4)",
                width: "calc((100% - 0px) / 4)",
                widthPx: NaN
            },
            title: "Serial Number"
        },
        {
            field: "case_id",
            tableData: {
                additionalWidth: 0,
                columnOrder: 2,
                filterValue: undefined,
                groupOrder: undefined,
                groupSort: "asc",
                id: 0,
                initialWidth: "calc((100% - 0px) / 4)",
                width: "calc((100% - 0px) / 4)",
                widthPx: NaN
            },
            title: "JSON ID"
        },
        {
            field: "attribute",
            tableData: {
                additionalWidth: 0,
                columnOrder: 3,
                filterValue: undefined,
                groupOrder: undefined,
                groupSort: "asc",
                id: 0,
                initialWidth: "calc((100% - 0px) / 4)",
                width: "calc((100% - 0px) / 4)",
                widthPx: NaN
            },
            title: "Attribute"
        }
    ]


    const callRulesService = (cases, returnAll, env, starting, ending, numDiffs, callback) => {
        console.log('calling strangle service!')
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACK_END_URL}/strangle`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                cases: cases,
                returnAll: returnAll,
                environment: env,
                starting: starting,
                caseLimit: ending,
                startingNumDiffs: numDiffs,
                reqNum: reqNum,
                documentDate: props.documentDate
            },
            cancelToken: source.token
        }).then(res => {
            //    console.log('all cases', res.data.cases);
            console.log('all Differences', res.data.differences);
            console.log('all Differences', res.data.cases);

            if (!res.data.errorResponse) {
                callback(res.data.differences, res.data.cases, cases, returnAll, env, starting, ending)
            }
            else {
                props.processingMode(false);
                setErrorResponse(res.data);
            }

            //  console.log("external diffs", res.data.differences)
        }).catch(thrown => {
            if (axios.isCancel(thrown)) {
                console.log('Request canceled', thrown.message)
            }
        })

    }


    let update = useCallback((newDifferences, newCases, cases, returnAll, env, starting, ending) => {

        let currentCounter = 0;
        let totalDiffs = 0;

        // console.log('cases', newCases);
        // console.log('diffs', newDifferences);
        setExternalDiffs(prevState => {
            totalDiffs = prevState.length + newDifferences.length;
            return [...prevState, ...newDifferences]
        });
        setExternalData(prevState => {
            //currentCounter = prevState.length + newCases.length;
            currentCounter = prevState.length + newCases.length;
            return [...prevState, ...newCases]
        });

        if (newCases.length < ending) {
            setFewerCasesThanRequested(true)
        }

        // console.log('counter check', 100 + externalData.length, totalCases);
        // if( 100 + previousCount <= totalCases && ( props.specificCases === null || props.specificCases === "" )) {
        //     // callRulesService(props.getServices, props.specificCases, !props.returnAllCases, props.environment, newCases.length + externalData.length, 100, props.rulebase, externalDiffs.length, update)
        // } else {

        // }

        setRequestedCases(currentCounter);

        // if the returned cases is less than the requested cases then we are out of cases to test against and we should NOT itterate // && newCases.length === ending
        if (currentCounter < totalCases && (cases === null || cases === "") && newCases.length >= ending) {
            // calling updateRulesServices Again
            callRulesService(cases, returnAll, env, currentCounter, ending, totalDiffs, update)
            setReqNum(reqNum++);
        } else {
            console.log('next button enabled!')
            props.processingMode(false);
            setNextButtonEnabled(true);
        }

    }, [setRequestedCases, totalCases, callRulesService, props, reqNum])

    useEffect(() => {

        console.log('use effect has fired', props.reset)
        if (props.cancelled) {
            source.cancel('Operation canceled');
            props.setIsCancelled(false);
            props.processingMode(false);
            setNextButtonEnabled(true);
            source = CancelToken.source();
            isMounted.current = true;
        }

        if (isMounted.current) {

            if (props.reset === true) {
                console.log('reset triggered')
                setFewerCasesThanRequested(false)
                setExternalDiffs([]);
                setExternalData([]);
                setTotalCases(props.numberOfCases)
                setErrorResponse(null);
                isMounted.current = false;
                setRequestedCases(props.startLimit);
                setNextButtonEnabled(false);
                props.setResetTable(false);
                // setLocalReset(false);

            }

        } else {
            isMounted.current = true;
            setSelectedCaseRows([]);
            setSelectedDiffRows([]);
            props.setResetTable(false);
            // setLocalReset(false)
            console.log('callRulesService Use Effect method called!')
            console.log('counter check', requestedCases, totalCases);

            callRulesService(props.specificCases, !props.returnAllCases, props.environment, requestedCases, props.casesPerQuery, externalDiffs.length, update);


        }


    }, [props.specificCases, props.returnAllCases, props.numberOfCases, props.environment, externalData.length, externalDiffs.length, requestedCases, setRequestedCases, totalCases, props.casesPerQuery, update, callRulesService]);



    const handlePullNextButtton = () => {
        console.log('calling next')
        props.processingMode(true)
        let startingPosition = totalCases + 0
        isMounted.current = false;
        setTotalCases(startingPosition + props.casesPerQuery);
        setNextButtonEnabled(false);
    }


    const handleCaseSelectionChange = (rows) => {

        console.log('testing case selection', rows)
        if (rows.length >= 1) {
            setSelectedDiffRows(externalDiffs.filter(diff => rows.map(row => row.ID).indexOf(diff.case_id) >= 0));
        }
        else {
            setSelectedDiffRows([]);
        }

    }

    const handleDiffSelectionChange = (rows) => {
        console.log('confirm this fires and does nothing', rows, externalData, externalData, externalData.filter(caseRow => rows.map(row => row.case_id).indexOf(caseRow.ID) >= 0));
        if (rows.length >= 1) {
            setSelectedCaseRows(externalData.filter(caseRow => rows.map(row => row.case_id).indexOf(caseRow.ID) >= 0));
        }
        else {
            setSelectedCaseRows([]);
        }
        console.log(selectedCaseRows);
    }

    if (!nextButtonEnabled && externalData.length === 0 && errorResponse === null && requestedCases === 0) {
        return (

            <div style={{ maxWidth: '100%' }}>
                <CircularProgress />
            </div>

        );

    } else if (externalData.length === 0 && errorResponse === null && requestedCases === 0) {
        return (
            <div style={{ maxWidth: '100%' }}>
                No Data Found
            </div>
        )
    } else if (errorResponse) {

        console.log(errorResponse);
        const responseMessage = errorResponse.errorResponse.map((error, index) => {
            const serviceMessage = error.service ? '<b> Error Calling Service</b>: {error.service}' : '';

            return (
                <div>
                    <Alert key={index} severity="error">{serviceMessage} <b key={index}> Message: </b> {error.error_detail} </Alert>
                </div>
            )
        });
        return responseMessage;

    } else {
        return (
            <div>
                <div style={{ display: 'flex' }}>
                    {  //  Label for number of cases found
                        !fewerCasesThanRequested ?
                            <Typography>{!props.specificCases?.length > 0 ? `Comparing: ${externalData.length} of ${totalCases}` : `Cases Found: ${externalData.length}`}</Typography>
                            : <Typography>{`Found ${externalData.length} EDBCs with a NOA.`}</Typography>
                    }   &nbsp;
                    {nextButtonEnabled && !props.specificCases && !fewerCasesThanRequested ?

                        <Button
                            style={{ padding: '0' }}
                            onClick={handlePullNextButtton}
                            endIcon={<NavigateNextIcon />} >
                            Pull Next
                        </Button> :
                        !props.specificCases && !fewerCasesThanRequested ?
                            <CircularProgress size={20} /> : ''
                    }

                </div>
                <div>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >

                            <ArrowForwardIosSharpIcon style = {expanded ? {transform: 'rotate(90deg)'} : {transform: ''}}/>
                            <Typography variant='body1' >Change Summary - </Typography>
                            {!expanded ?
                                <Typography variant='body1'> Expand to see all changed values</Typography> :
                                <Typography variant='body1'> Select attributes to filter cases in the table below</Typography>
                            }

                        </AccordionSummary>
                        <AccordionDetails style={{ display: 'block' }}>
                            <MaterialTable
                                title="All Differences"
                                icons={tableIcons}
                                columns={diffColumns}
                                data={selectedDiffRows.length >= 1 ? selectedDiffRows : externalDiffs}
                                options={{
                                    grouping: true,
                                    selection: true,
                                    exportMenu: [{
                                        label: 'Export CSV',
                                        exportFunc: (cols, datas) => {
                                            // console.log('columns', cols);
                                            ExportCsv([...exportColumns,
                                            {
                                                field: "lhs",
                                                tableData: {
                                                    additionalWidth: 0,
                                                    columnOrder: 3,
                                                    filterValue: undefined,
                                                    groupOrder: undefined,
                                                    groupSort: "asc",
                                                    id: 0,
                                                    initialWidth: "calc((100% - 0px) / 4)",
                                                    width: "calc((100% - 0px) / 4)",
                                                    widthPx: NaN
                                                },
                                                title: "Primary"
                                            },
                                            {
                                                field: "rhs",
                                                tableData: {
                                                    additionalWidth: 0,
                                                    columnOrder: 3,
                                                    filterValue: undefined,
                                                    groupOrder: undefined,
                                                    groupSort: "asc",
                                                    id: 0,
                                                    initialWidth: "calc((100% - 0px) / 4)",
                                                    width: "calc((100% - 0px) / 4)",
                                                    widthPx: NaN
                                                },
                                                title: "Secondary"
                                            }], externalDiffs, `"NOA_Differences_${Date.now()}`)
                                        }
                                    }]
                                }}
                                onSelectionChange={(rows) => handleDiffSelectionChange(rows)}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div style={{ maxWidth: '100%' }}>
                    <MaterialTable
                        // tableRef={tableReference}
                        columns={caseColumns}
                        data={
                            selectedCaseRows.length >= 1 ? selectedCaseRows : !props.returnAllCases ? (externalData.filter(caseRow => externalDiffs.map(diff => diff.case_id).indexOf(caseRow.ID) >= 0)) : externalData
                        }
                        title='Case Data & Compare'
                        type='boolean'
                        icons={tableIcons}
                        // onRowClick={(evt, selectedRow) => {
                        //     setSelectedRow(selectedRow.tableData.id);
                        //     console.log('selectedRow', selectedRow);
                        //     props.selectedCases(selectedRow.JSON_REQ);
                        // }
                        // }
                        options={{
                            rowStyle: rowData => ({
                                backgroundColor: props.returnAllCases ? (externalDiffs.some(diff => diff.case_id === rowData.tableData.ID) ? '#ae679c' : '#FFF') : '#FFF',  //   selectedRow === rowData.tableData.id ? '#67aeae' : '#FFF' ||

                            }),
                            selection: true
                        }}
                        onSelectionChange={(rows) => handleCaseSelectionChange(rows)}
                        detailPanel={[
                            {
                                icon: CompareArrowsIcon,
                                tooltip: 'compare results',
                                render: rowData => {

                                    console.log('testing row data values', rowData)

                                    return (

                                        <ReactDiffViewer
                                            oldValue={JSON.stringify(rowData.rowData.noavalues[0], null, '\t')}
                                            newValue={JSON.stringify(rowData.rowData.noavalues[1], null, '\t')}
                                            splitView={true}
                                            showDiffOnly={true}
                                            leftTitle={'Primary'}
                                            rightTitle={'Secondary'}
                                        />


                                    )
                                }
                            },

                        ]}
                    />
                </div>
            </div>

        );


    }
}



export default DatabaseCompare;
