import React, { useEffect } from 'react';
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';


function AdvancedServices({ services, setService }) {



    const handleSelectService = (event, value) => {
        console.log('testing value of service selected', value)
        setService(value.key)
    }

    useEffect(() => {
        console.log('testing value of services', services)

    }, []);

    return (
        services.find(serv => serv !== null)?.length > 0 ?
            <Box sx={{ minWidth: '25em', padding: '1em'}} >

                    <Autocomplete
                        disablePortal
                        id="combo-box-serv-one"
                        onChange={(event, value) => handleSelectService(event, value)}
                        isOptionEqualToValue={(option, value) => option.key === value.key}
                        options={services.filter(serv => serv !== null).map((serv) => (
                            {
                                "label": serv,
                                "key": serv
                            }
                        ))
                        }
                        renderInput={(params) => <TextField {...params} label="Select Service" />}

                    >
                    </Autocomplete>
   
            </Box> :
            <Alert severity="error">
                <Typography variant='body1' align='center'>Please select one service from the main screen before using the advanced attribute feature</Typography>
            </Alert>
    );
}

export default AdvancedServices;