import TextField from '@mui/material/TextField';


// functional component for the custom text field


const CustomMuiTextField = ({ label, error = false, helperText = '', disabled = false, inputRef, handleKeyDown, handleKeyUp, type }: any) => {

    
    return (
        <TextField
            key={'textField_'+type}
            type={type}
            error={error}
            label={label}
            helperText={helperText}
            disabled={disabled}
            autoFocus
            autoComplete="off"
            fullWidth
            inputRef={inputRef}
            variant="standard"
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            id="fullWidthTextField"
        />
    )
}

export default CustomMuiTextField;