//functional component to display options of a question

import { Box, Stack, Button, Typography } from "@mui/material"
import { green, grey } from "@mui/material/colors"

type AppProps = {
    collectionText: string,
    options: Array<string>,
    handleOptionClick: (option: string) => void,
    assignedKey: string
}

const OptionsCard = ({ collectionText, options, handleOptionClick, assignedKey }: AppProps) => {


    //return a MUI box element with the options
    //a mui typography element asking a user to select an option

    return (
        <Box key={assignedKey + '_options'} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'left', width: '100%', height: '100%' }}>

            <div style={{ display: "flex" }} key={assignedKey+'div'}>
                <Typography variant='body2' color='green' noWrap>&gt;&nbsp;</Typography>
                <Typography variant='body2'>Select on of the following {collectionText}:</Typography>
            </div>
            <Stack sx={{ paddingLeft: '1em'}} justifyContent="flex-start" spacing={2} direction="row">
                {options.map((option: string, index: number) => {
                    return (
                        <Button key={assignedKey + '_option_' + index} variant="contained" onClick={() => handleOptionClick(option)}>{option}</Button>
                    )
                }
                )}
            </Stack>
        </Box>
    )

}

export default OptionsCard;