import React from 'react';
import Box from '@mui/material/Box';

import Paper from '@mui/material/Paper';

import Typography from '@mui/material/Typography';

import LinearProgress from '@mui/material/LinearProgress';
import Service from '../../SelectServices/Service';

import Tree from '../Tree/Tree';

import { useFetchJson } from '../../data/ServiceComponent'





function DecisionReportHelper(props) {

    let originalRequest = useFetchJson(props.identifier, props.environment);
    // for some reason WPRD doesn't come with the KEES_ :/
    const [service, setServiceName] = React.useState(props.program == 'WPRD' ? 'KEES' + '_' + props.program + '_' + props.environment.toUpperCase() : props.program + '_' + props.environment.toUpperCase());

    function formatRequest(attr) {


        console.log('attempting to format request', attr, originalRequest.outcomes);

        let request = { outcomes: [], cases: [] };

        request = originalRequest;

        console.log('original request', request);

        request.outcomes = [
            // ...request.outcomes.filter(outcome => outcome !== attr),
            {
                id: attr,
                knownOutcomeStyle: 'decision-report',
                unknownOutcomeStyle: 'decision-report',
                resolveIndecisionRelationships: false,
                showInvisible: false,  // parse this!!  relationships
                showSilent: false // maybe really bad?
            }];

        console.log('show newRequest', request);
        return JSON.stringify([request]);
    }


    console.log('test value of service', props.service, service)
    if (service === '') {
        return (
            <Paper sx={{ width: '100%', padding: '3em' }}>
                <Typography variant="body1">In order to run a decision report please select a deployment below:</Typography>
                <Service setServiceName={setServiceName} />
                <Typography variant="caption">*keep that the value may change depending on the deployment selected</Typography>
            </Paper>
        )
    }
    // else (originalRequest === null) {
    else if (originalRequest === null) {
        return (
            <Box sx={{ width: '100%' }} >
                <LinearProgress />
            </Box >
        )

    } else {


        const createRowData = formatRequest(props.attribute);

        return (
            <Paper elevation={24} style={{ display: 'inline-block', minWidth: '100%' }} >
                <Typography sx={{ textAlign: 'center', fontStyle: 'italic' }}>{`${props.service} Decision Report`}</Typography>
                <Tree
                    title={service}
                    json_request={createRowData}
                    attribute={props.attribute}
                    completeData={props.completeData}
                    value={props.value}
                />
            </Paper>
        )
    }

}

export default DecisionReportHelper;