

import React, { forwardRef, useEffect, useState, useRef, useCallback } from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MaterialTable from '@material-table/core';
import { ExportCsv } from '@material-table/exporters';
import Accordion from '@mui/material/Accordion';
import Paper from '@mui/material/Paper';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';


import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import JSONViewer from '../Components/JSONViewer';
import JsonCompare from '../Components/jsonCompare/JsonCompare';
import Tree from '../Components/Tree/Tree';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import FileDownload from '../Components/FileDown/FileDownload'
// import { bendiserverer } from '../data'
import { AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn } from "@mui/icons-material";
const axios = require('axios');
const CancelToken = axios.CancelToken;
let source = CancelToken.source();



const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

// const useStyles = makeStyles((theme) => ({
//     root: {
//       width: '100%',
//     },
//     heading: {
//       fontSize: theme.typography.pxToRem(15),
//       flexBasis: '33.33%',
//       flexShrink: 0,
//     },
//     secondaryHeading: {
//       fontSize: theme.typography.pxToRem(15),
//       color: theme.palette.text.secondary,
//     },
//   }));


function Table(props) {
    const [externalDiffs, setExternalDiffs] = useState([]);
    const [externalData, setExternalData] = useState([]);
    const [errorResponse, setErrorResponse] = useState(null);
    const [selectedServices, setSelectedServices] = useState([]);
    const [totalCases, setTotalCases] = useState(props.numberOfCases);
    const [nextButtonEnabled, setNextButtonEnabled] = useState(false)

    const [requestedCases, setRequestedCases] = useState(props.startLimit);

    const [expanded, setExpanded] = React.useState(false);

    const isMounted = useRef(false);

    const [selectedCaseRows, setSelectedCaseRows] = useState([]);
    const [selectedDiffRows, setSelectedDiffRows] = useState([]);

    const [fewerCasesThanRequested, setFewerCasesThanRequested] = useState(false);
    const [caseCounter, setCaseCounter] = useState(0);

    let [reqNum, setReqNum] = useState(1);

    let caseColumns = [
        {
            field: 'download', title: 'JSON', render: (rowData) => <FileDownload JSON={rowData.JSON_REQ} CASE_NAME={rowData.CASE_NAME} PROGRAM={rowData.PGM_CODE}/>

        },
        { title: 'Case Name', field: 'CASE_NAME' },
        { title: 'Program Type', field: 'PGM_CODE' },
        { title: 'Serial Number', field: 'SERIAL_NUM_IDENTIF' },
        { title: 'Run Time', field: 'CREATED_ON', type: "datetime", dateSetting: { locale: 'en-US' } },
        { title: 'Benefit Month', field: 'BEG_DATE', type: "date", dateSetting: { locale: 'en-US' } }
    ];

    let diffColumns = [
        { title: 'Case Name', field: 'caseName' },
        { title: 'Attribute', field: 'attribute', defaultGroupOrder: 0 },
        { title: selectedServices[0], field: 'lhs' },
        { title: selectedServices[1], field: 'rhs' }
    ];

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const exportColumns = [
        {
            field: "caseName",
            tableData: {
                additionalWidth: 0,
                columnOrder: 0,
                filterValue: undefined,
                groupOrder: undefined,
                groupSort: "asc",
                id: 0,
                initialWidth: "calc((100% - 0px) / 4)",
                width: "calc((100% - 0px) / 4)",
                widthPx: NaN
            },
            title: "Case Name"
        },
        {
            field: "serialNum",
            tableData: {
                additionalWidth: 0,
                columnOrder: 1,
                filterValue: undefined,
                groupOrder: undefined,
                groupSort: "asc",
                id: 0,
                initialWidth: "calc((100% - 0px) / 4)",
                width: "calc((100% - 0px) / 4)",
                widthPx: NaN
            },
            title: "Serial Number"
        },
        {
            field: "case_id",
            tableData: {
                additionalWidth: 0,
                columnOrder: 2,
                filterValue: undefined,
                groupOrder: undefined,
                groupSort: "asc",
                id: 0,
                initialWidth: "calc((100% - 0px) / 4)",
                width: "calc((100% - 0px) / 4)",
                widthPx: NaN
            },
            title: "JSON ID"
        },
        {
            field: "attribute",
            tableData: {
                additionalWidth: 0,
                columnOrder: 3,
                filterValue: undefined,
                groupOrder: undefined,
                groupSort: "asc",
                id: 0,
                initialWidth: "calc((100% - 0px) / 4)",
                width: "calc((100% - 0px) / 4)",
                widthPx: NaN
            },
            title: "Attribute"
        },
        {
            field: "edbc_id",
            tableData: {
                additionalWidth: 0,
                columnOrder: 2,
                filterValue: undefined,
                groupOrder: undefined,
                groupSort: "asc",
                id: 0,
                initialWidth: "calc((100% - 0px) / 4)",
                width: "calc((100% - 0px) / 4)",
                widthPx: NaN
            },
            title: "EDBC ID"
        }
    ]


    useEffect(() => {
        // setting all differences here for advanced extraction scenarios
        props.setDifferencesForAdvancedFeatures(externalDiffs)
    }, [externalDiffs]);

    function formatRequest(case_id, attr) {
        //  console.log(externalData[case_location]);
        let request = { outcomes: [], cases: [] };

        let findSON = externalData.filter(item => {
            // console.log('testing each item', item)
            if (item.id === case_id) {
                console.log('locate item', item);
                return item.JSON_REQ
            } else {
                return false
            }

        })

        request = [JSON.parse(findSON[0].JSON_REQ)];

        // console.log('original request', request);

        request[0].outcomes = [
            ...request[0].outcomes.filter(outcome => outcome !== attr),
            {
                id: attr,
                knownOutcomeStyle: 'decision-report',
                unknownOutcomeStyle: 'decision-report',
                resolveIndecisionRelationships: false,
                showInvisible: false,  // parse this!!  relationships
                showSilent: false // maybe really bad?
            }];

        console.log('show newRequest', request);
        return JSON.stringify(request);
    }



    const callRulesService = (services, cases, returnAll, env, starting, ending, rules, numDiffs, callback) => {
        console.log('calling rule service!')
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACK_END_URL}/api`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                services: services,
                cases: cases,
                returnAll: returnAll,
                environment: env,
                starting: starting,
                caseLimit: ending,
                rulebase: rules,
                startingNumDiffs: numDiffs,
                reqNum: reqNum,
                outcomes: props.outcomes
            },
            cancelToken: source.token
        }).then(res => {
                console.log('all cases', res.data.cases);
            console.log('all Differences', res.data.differences);

            if (!res.data.errorResponse) {
                callback(res.data.differences, res.data.cases, services, cases, returnAll, env, starting, ending, rules)
            }
            else {
                props.processingMode(false);
                setErrorResponse(res.data);
            }

            //  console.log("external diffs", res.data.differences)
        }).catch(thrown => {
            if (axios.isCancel(thrown)) {
                console.log('Request canceled', thrown.message)
            }
        })

    }


    let update = useCallback((newDifferences, newCases, services, cases, returnAll, env, starting, ending, rules) => {

        let currentCounter = 0;
        let totalDiffs = 0;

        // console.log('cases', newCases);
        // console.log('diffs', newDifferences);
        setExternalDiffs(prevState => {
            totalDiffs = prevState.length + newDifferences.length;

            return [...prevState, ...newDifferences]
        });

        setCaseCounter(prevCounter => {
            currentCounter = prevCounter + ending;
            return prevCounter + ending
        })
        setExternalData(prevState => {
            //currentCounter = prevState.length + newCases.length;

            return [...prevState, ...newCases]
        });

        let flagForStopProcessing = true;
        if (newCases.length < ending && false) {
            setFewerCasesThanRequested(true)
            flagForStopProcessing = false;
        }

        // console.log('counter check', 100 + externalData.length, totalCases);
        // if( 100 + previousCount <= totalCases && ( props.specificCases === null || props.specificCases === "" )) {
        //     // callRulesService(props.getServices, props.specificCases, !props.returnAllCases, props.environment, newCases.length + externalData.length, 100, props.rulebase, externalDiffs.length, update)
        // } else {

        // }

        setRequestedCases(currentCounter);

        // if the returned cases is less than the requested cases then we are out of cases to test against and we should NOT itterate // && newCases.length === ending
        if (currentCounter < totalCases && (cases === null || cases === "") && flagForStopProcessing) {
            // calling updateRulesServices Again
            callRulesService(services, cases, returnAll, env, currentCounter + props.startLimit, ending, rules, totalDiffs, update)
            setReqNum(reqNum++);
        } else {
            console.log('next button enabled!')
            props.processingMode(false);
            setNextButtonEnabled(true);
        }

    }, [setRequestedCases, totalCases, callRulesService, props, reqNum])

    useEffect(() => {

        console.log('use effect has fired', props.reset)
        if (props.cancelled) {
            source.cancel('Operation canceled');
            props.setIsCancelled(false);
            props.processingMode(false);
            setNextButtonEnabled(true);
            source = CancelToken.source();
            isMounted.current = true;
        }

        if (isMounted.current) {

            if (props.reset === true) {
                console.log('reset triggered')
                setFewerCasesThanRequested(false)
                setSelectedServices(props.getServices);
                setExternalDiffs([]);
                setExternalData([]);
                setTotalCases(props.numberOfCases)
                setCaseCounter(0);
                setErrorResponse(null);
                setSelectedCaseRows([]);
                setSelectedDiffRows([]);
                isMounted.current = false;
                setRequestedCases(props.startLimit);
                setNextButtonEnabled(false);
                props.setResetTable(false);
                // setLocalReset(false);

            }

        } else {
            isMounted.current = true;
            setSelectedCaseRows([]);
            setSelectedDiffRows([]);
            props.setResetTable(false);
            setSelectedServices(props.getServices);
            // setLocalReset(false)
            console.log('callRulesService Use Effect method called!')
            console.log('counter check', requestedCases, totalCases);

            callRulesService(props.getServices, props.specificCases, !props.returnAllCases, props.environment, requestedCases, 100, props.rulebase, externalDiffs.length, update);


        }


    }, [props.getServices, selectedServices, props.specificCases, props.returnAllCases, props.rulebase, props.numberOfCases, props.environment, externalData.length, externalDiffs.length, requestedCases, setRequestedCases, totalCases, 100, update, callRulesService, props]);



    const handlePullNextButtton = () => {
        console.log('calling next')
        props.processingMode(true)
        let startingPosition = totalCases + 0
        isMounted.current = false;
        setTotalCases(startingPosition + 100);
        setNextButtonEnabled(false);
    }

    const responseMessage = errorResponse?.errorResponse.map((error, index) => {
        const serviceMessage = error.service ? 'Error Calling Service>: ' + error.service : '';

        return (
            <div key='alrt'>
                <Alert key={index} severity="error">{serviceMessage} <b key={index}> Message: </b> {error.error_detail} </Alert>
            </div>
        )
    });



        const handleCaseSelectionChange = (rows) => {

            if (rows.length >= 1) {
                setSelectedDiffRows(externalDiffs.filter(diff => rows.map(row => row.ID).indexOf(diff.case_id) >= 0));
            }
            else {
                setSelectedDiffRows([]);
            }

        }

        const handleDiffSelectionChange = (rows) => {
            // console.log('confirm this fires and does nothing', rows.length, rows.map(row => row.case_id))
            if (rows.length >= 1) {
                setSelectedCaseRows(externalData.filter(caseRow => rows.map(row => row.case_id).indexOf(caseRow.ID) >= 0));
            }
            else {
                setSelectedCaseRows([]);
            }
            // console.log(selectedCaseRows);
        }

        if (!nextButtonEnabled && externalData.length === 0 && errorResponse === null && requestedCases === 0) {
            return (

                <div style={{ maxWidth: '100%' }}>
                    <CircularProgress />
                </div>

            );

        } else if (externalData.length === 0 && errorResponse === null && requestedCases === 0) {
            return (
                <div style={{ maxWidth: '100%' }}>
                    No Data Found for rulebase {props.rulebase} {props.specificCases !== null ? "and cases " + props.specificCases : ""}
                </div>
            )
        } else {
            return (
                <div>
                   { errorResponse ? responseMessage : ''}

                    <div style={{ display: 'flex' }}>
                        {  //  Label for number of cases found
                            !fewerCasesThanRequested ?
                                <Typography>{!props.specificCases?.length > 0 ? `Comparing: ${caseCounter} of ${totalCases}` : `Cases Found: ${externalData.length}`}</Typography>
                                : <Typography>{`Found ${externalData.length} Cases. This appears to be all that exist for this program type`}</Typography>
                        }   &nbsp;
                        {nextButtonEnabled && !props.specificCases && !fewerCasesThanRequested ?

                            <Button
                                style={{ padding: '0' }}
                                onClick={handlePullNextButtton}
                                endIcon={<NavigateNextIcon />} >
                                Pull Next
                            </Button> :
                            !props.specificCases && !fewerCasesThanRequested ?
                                <CircularProgress size={20} /> : ''
                        }

                    </div>
                    <div>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <ArrowForwardIosSharpIcon style={expanded ? { transform: 'rotate(90deg)' } : { transform: '' }} />
                                <Typography variant='body1' >Change Summary - </Typography>
                                {!expanded ?
                                    <Typography variant='body1'> Expand to see all changed values</Typography> :
                                    <Typography variant='body1'> Select attributes to filter cases in the table below</Typography>
                                }

                            </AccordionSummary>
                            <AccordionDetails style={{ display: 'block' }}>
                                <MaterialTable
                                    title="All Differences"
                                    icons={tableIcons}
                                    columns={diffColumns}
                                    data={selectedDiffRows.length >= 1 ? selectedDiffRows : externalDiffs}
                                    options={{
                                        grouping: true,
                                        selection: true,
                                        exportMenu: [{
                                            label: 'Export CSV',
                                            exportFunc: (cols, datas) => {
                                                // console.log('columns', cols);
                                                ExportCsv([...exportColumns,
                                                {
                                                    field: "lhs",
                                                    tableData: {
                                                        additionalWidth: 0,
                                                        columnOrder: 3,
                                                        filterValue: undefined,
                                                        groupOrder: undefined,
                                                        groupSort: "asc",
                                                        id: 0,
                                                        initialWidth: "calc((100% - 0px) / 4)",
                                                        width: "calc((100% - 0px) / 4)",
                                                        widthPx: NaN
                                                    },
                                                    title: props.getServices[0]
                                                },
                                                {
                                                    field: "rhs",
                                                    tableData: {
                                                        additionalWidth: 0,
                                                        columnOrder: 3,
                                                        filterValue: undefined,
                                                        groupOrder: undefined,
                                                        groupSort: "asc",
                                                        id: 0,
                                                        initialWidth: "calc((100% - 0px) / 4)",
                                                        width: "calc((100% - 0px) / 4)",
                                                        widthPx: NaN
                                                    },
                                                    title: props.getServices[1]
                                                }], externalDiffs, `${props.getServices[0]}_${props.getServices[1]}_${Date.now()}`)
                                            }
                                        }]
                                    }}
                                    onSelectionChange={(rows) => handleDiffSelectionChange(rows)}
                                    detailPanel={[{
                                        icon: CompareArrowsIcon,
                                        tooltip: 'view decision report',
                                        render: rowData => {

                                            // console.log('did it work?')
                                            const createRowData = formatRequest(rowData.rowData.case_id, rowData.rowData.attribute);

                                            return (
                                                <div style={{ maxWidth: '100%', display: 'flex' }}>
                                                    <div style={{ display: 'inline-block', minWidth: '50%' }} >
                                                        <Typography >{props.getServices[0]}</Typography>

                                                        <Paper elevation={24} style={{ display: 'inline-block', minWidth: '50%' }} >
                                                            <Typography sx={{ textAlign: 'center', fontStyle: 'italic' }}>{`${props.getServices[0]} Decision Report`}</Typography>
                                                            <Tree
                                                                title={props.getServices[0]}
                                                                json_request={createRowData}
                                                                attribute={rowData.rowData.attribute}
                                                                completeData={rowData.rowData}
                                                                value={rowData.rowData.lhs}
                                                            />
                                                        </Paper>
                                                    </div>
                                                    <div style={{ display: 'inline-block', minWidth: '50%' }} >
                                                        <Typography >{props.getServices[1]}</Typography>

                                                        <Paper elevation={24} style={{ display: 'inline-block', minWidth: '50%' }} >
                                                            <Typography sx={{ textAlign: 'center', fontStyle: 'italic' }} >{`${props.getServices[1]} Decision Report`}</Typography>
                                                            <Tree
                                                                title={props.getServices[1]}
                                                                json_request={createRowData}
                                                                attribute={rowData.rowData.attribute}
                                                                completeData={rowData.rowData}
                                                                value={rowData.rowData.rhs}

                                                            />
                                                        </Paper>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                        ,
                                        // {
                                        //     icon: CompareArrowsIcon,
                                        //     tooltip: 'compare results',
                                        //     render: rowData => {

                                        //         const createRowData = formatRequest(rowData.rowData.case_id, rowData.rowData.attribute);

                                        //         return (

                                        //             <JsonCompare
                                        //                 titles={props.getServices}
                                        //                 json_request={createRowData}
                                        //             />

                                        //         )
                                        //     }
                                        // }
                                    ]
                                    }






                                />
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    <div style={{ maxWidth: '100%' }}>
                        <MaterialTable
                            // tableRef={tableReference}
                            columns={caseColumns}
                            data={ // selectedCaseRows.length >= 1 ? selectedCaseRows :  externalData }
                                selectedCaseRows.length >= 1 ? selectedCaseRows : !props.returnAllCases ? (externalData.filter(caseRow => externalDiffs.map(diff => diff.case_id).indexOf(caseRow.ID) >= 0)) : externalData
                            }
                            title='Case Data & Compare'
                            type='boolean'
                            icons={tableIcons}
                            // onRowClick={(evt, selectedRow) => {
                            //     setSelectedRow(selectedRow.tableData.id);
                            //     console.log('selectedRow', selectedRow);
                            //     props.selectedCases(selectedRow.JSON_REQ);
                            // }
                            // }
                            options={{
                                rowStyle: rowData => ({
                                    backgroundColor: props.returnAllCases ? (externalDiffs.some(diff => diff.case_id === rowData.tableData.ID) ? '#ae679c' : '#FFF') : '#FFF',  //   selectedRow === rowData.tableData.id ? '#67aeae' : '#FFF' ||

                                }),
                                selection: true
                            }}
                            onSelectionChange={(rows) => handleCaseSelectionChange(rows)}
                            detailPanel={[
                                {
                                    render: rowData => {
                                        // console.log("rowData: ", JSON.parse(rowData.rowData.JSON_REQ));
                                        return (
                                            <JSONViewer
                                                json={rowData.rowData.JSON_REQ}
                                            />
                                        )
                                    }
                                },
                                {
                                    icon: CompareArrowsIcon,
                                    tooltip: 'compare results',
                                    render: rowData => {
                                        return (

                                            <JsonCompare
                                                titles={props.getServices}
                                                json_request={rowData.rowData.JSON_REQ}
                                                outcomes={props.outcomes}
                                            />

                                        )
                                    }
                                },

                            ]}
                        />
                    </div>
                </div>

            );


        }
    }



export default Table;
