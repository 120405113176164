import React, { useEffect } from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, Label, Tooltip, ResponsiveContainer } from 'recharts';
import Title from './Title';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import TextField from '@mui/material/TextField';
import MaterialTable from '@material-table/core';
import { useStatistics, useOpaStatisticsData } from '../../../data/ServiceComponent'
import ReportStats from './ReportStats'
import ErrorReport from './ErrorReport'
import MonthPicker from './MonthPicker'

const columns = [
  { title: 'Value', field: 'value' },
  { title: 'Series', field: 'series' },
  {
    title: 'Date', field: 'category', defaultGroupOrder: 0
  }
]


export default function Chart(props) {

  const { metrics, timePeriod, groupBy, actionType, deployments, name, setTime, setName, setReportObjectName } = props;

  // console.log('am i passing in deployments?', deployments)

  const theme = useTheme();
  const [openChartData, setOpenChartData] = React.useState(false);
  const [showCustomTimes, setShowCustomTimes] = React.useState(false);
  const [showTotals, seShowTotals] = React.useState(false);
  const [chartName, setChartName] = React.useState('');
  const [chartTimePeriod, setChartTimePeriod] = React.useState([]);
  const [chartGroupBy, setChartGroupBy] = React.useState([]);
  const [customTimes, setCustomTimes] = React.useState([]);
  const [customStart, setCustomStart] = React.useState(null);
  const [customEndMonth, setCustomEndMonth] = React.useState(null);

  let changingName = '';

  const [editName, setEditName] = React.useState(false);

  const [statistics] = useStatistics(metrics, chartTimePeriod, chartGroupBy, actionType, deployments);

  const [timeButtons] = useOpaStatisticsData("timePeriod", metrics.map(item => item.value), chartGroupBy.map(item => item.value), actionType.map(item => item.value), timePeriod.map(item => item.value))
  const [groupByButtons] = useOpaStatisticsData("groupBy", metrics.map(item => item.value), groupBy.map(item => item.value), actionType.map(item => item.value), chartTimePeriod.map(item => item.value))


  //console.log('theme test', theme.palette)
  const colorArray = [
    theme.palette.primary.main,
    theme['palette'].secondary.main,
    theme['palette'].error.main,
    theme['palette'].warning.main,
    theme['palette'].info.main,
    theme['palette'].success.main,
    theme['palette'].primary.light,
    theme['palette'].secondary.light,
    theme['palette'].error.light,
    theme['palette'].warning.light,
    theme['palette'].info.light,
    theme['palette'].success.light,
    theme['palette'].primary.dark,
    theme['palette'].secondary.dark,
    theme['palette'].error.dark,
    theme['palette'].warning.dark,
    theme['palette'].info.dark,
    theme['palette'].success.dark
  ]


  // maybe someday?
  // const CustomTooltip = ({ active, payload, label }) => {

  //   console.log('testing tooltip', active, payload, label )
  //   if (active && payload && payload.length) {
  //     return (
  //       <div className="custom-tooltip">
  //         <p className="label">{`${label} : ${payload[0].value}`}</p>
  //         <p className="desc">Anything you want can be displayed here.</p>
  //       </div>
  //     );
  //   }
  //   return null;
  // }

  function handleValueData() {
    openChartData ? setOpenChartData(false) : setOpenChartData(true)
  }

  function handleShowTotals() {
    showTotals ? seShowTotals(false) : seShowTotals(true)
  }

  const handleChangeName = event => {
    // console.log(event.target.value)

    changingName = event.target.value;
    setName(event.target.value);
  }

  const handleEditNameButton = () => {
    // if we're saving the name, apply it to the reports object from the list component
    if(editName){
      setReportObjectName(changingName, chartName)
      setChartName(changingName);
    }
    setEditName(!editName)
    
  }

  const handleTimeButton = button => {
    setTime([button])
    setChartTimePeriod([button]);
    setCustomTimes([])
    setShowCustomTimes(false);
    console.log('testing value of time', chartTimePeriod, timePeriod);
  }

  const handleCustomTimeButton = () => {
    setShowCustomTimes(true);
    setCustomTimes([customTimes.length > 0 ? customTimes[0] : statistics?.data[0].category, customTimes.length > 0 ? customTimes[1] : statistics?.data[statistics?.data.length - 1].category])
  }


  const handleGroupByButton = button => {
    //set GroupBy
    console.log('testing button thing', chartGroupBy)
    setChartGroupBy([button]);

  }

  useEffect(() => {
    setChartName(name);
    setChartTimePeriod(timePeriod);
    setChartGroupBy(groupBy);
  }, [timePeriod, groupBy, name])



  const handleApplyCustomDate = () => {


    // console.log('handleApplyCustomDate just ran')
    let beginMonth = null;
    let endMonth = null;
    let beginDay = null;
    let endDay = null;
    let beginHour = null;
    let endHour = null;
    let customBeginDateFormatted = null;
    let customEndMonthDateFormatted = null;

    if (groupBy.findIndex(item => item.value === "month") >= 0) {
      beginMonth = (customStart?.getMonth() + 1)
      endMonth = (customEndMonth?.getMonth() + 1)

      customBeginDateFormatted = customStart ?
        customStart.getFullYear() + '-' + (beginMonth >= 10 ? beginMonth : '0' + beginMonth) : statistics?.data[0].category

      customEndMonthDateFormatted = customEndMonth ?
        customEndMonth.getFullYear() + '-' + (endMonth >= 10 ? endMonth : '0' + endMonth) : statistics?.data[statistics?.data.length - 1].category
    } else if (groupBy.findIndex(item => item.value === "hour") >= 0) {

      beginMonth = (customStart?.getMonth() + 1)
      endMonth = (customEndMonth?.getMonth() + 1)
      beginDay = (customStart?.getDate())
      endDay = (customEndMonth?.getDate())
      beginHour = (customStart?.getHours())
      endHour = (customEndMonth?.getHours())
      customBeginDateFormatted = customStart ?
        customStart.getFullYear() + '-' +
        (beginMonth >= 10 ? beginMonth : '0' + beginMonth) + '-' +
        (beginDay >= 10 ? beginDay : '0' + beginDay) + ' ' +
        (beginHour >= 10 ? beginDay: '0' + beginHour) + ':00'
        : statistics?.data[0].category

      customEndMonthDateFormatted = customEndMonth ?
        customEndMonth.getFullYear() + '-' +
        (endMonth >= 10 ? endMonth : '0' + endMonth) + '-' +
        (endDay >= 10 ? endDay : '0' + endDay) + ' ' +
        (endHour >= 10 ? endHour: '0' + endHour) + ':00'
        : statistics?.data[statistics?.data.length - 1].category
    }

    else {

      // day
      beginMonth = (customStart?.getMonth() + 1)
      endMonth = (customEndMonth?.getMonth() + 1)
      beginDay = (customStart?.getDate())
      endDay = (customEndMonth?.getDate())

      customBeginDateFormatted = customStart ?
        customStart.getFullYear() + '-' +
        (beginMonth >= 10 ? beginMonth : '0' + beginMonth) + '-' +
        (beginDay >= 10 ? beginDay : '0' + beginDay)
        : statistics?.data[0].category

      customEndMonthDateFormatted = customEndMonth ?
        customEndMonth.getFullYear() + '-' +
        (endMonth >= 10 ? endMonth : '0' + endMonth) + '-' +
        (endDay >= 10 ? endDay : '0' + endDay)
        : statistics?.data[statistics?.data.length - 1].category
    }

    // console.log('testing these values', customStart, customEndMonth, [customBeginDateFormatted, customEndMonthDateFormatted])
    setCustomTimes([customBeginDateFormatted, customEndMonthDateFormatted])

    if (chartTimePeriod.findIndex(item => item.value === "last2Years") >= 0) {
      setChartTimePeriod(previousValue => {
        previousValue[0].name = "custom";
        return previousValue;
      })
    } else if(chartGroupBy.findIndex(item => item.value === "hour") >= 0) {
      setChartTimePeriod([{
        "name": "custom",
        "value": "last30Days"
      }])
    }
    else {
      setChartTimePeriod([{
        "name": "custom",
        "value": "last2Years"
      }]);
    }

    setShowCustomTimes(false);
    console.log('testing value of custom time', chartTimePeriod)
  }

  // restructure data so that each date has a single object and an entry for each series
  const formattedData = statistics && !statistics.error ? statistics?.data.filter(item => customTimes.length === 0 || (item.category >= customTimes[0] && item.category <= customTimes[1])).reduce((previousValue, data) => {

    // console.log('testing previous value', previousValue)
    const findElementIndex = previousValue.findIndex(element => element.date === data.category)

    if (findElementIndex > -1) {
      const newElement = previousValue[findElementIndex];
      return [...previousValue.filter(element => element.date !== data.category), { ...newElement, [data.series]: data.value }]
    } else {
      return [...previousValue, { date: data.category, [data.series]: data.value }]
    }

  }, []) : 'Error - Mo data'


  // console.log('data', formattedData)

  const uniqueSeries = statistics && !statistics.error ? statistics?.data.reduce((previousValue, currentValue) => {
    if (previousValue.indexOf(currentValue.series) >= 0) {
      return previousValue
    } else {
      return [...previousValue, currentValue.series]
    }
  }, []) : [];

  //  console.log('testing unique series builder', uniqueSeries)

  const lines =
    statistics && !statistics.error && uniqueSeries ? uniqueSeries.map((deployment, index) => {


      const indexCalc = index % colorArray.length;

      const numberOfLoops = ~~(index / colorArray.length)

      let dashStroke = 0;
      if (numberOfLoops === 1) {
        dashStroke = "4"
      } else if (numberOfLoops === 2) {
        dashStroke = "4 1"
      } else if (numberOfLoops === 3) {
        dashStroke = "4 1 2"
      } else if (numberOfLoops === 4) {
        dashStroke = "3 4 5 2"
      } else if (numberOfLoops === 5) {
        dashStroke = "5 1 5 1"
      } else if (numberOfLoops === 6) {
        dashStroke = "1 1 5 2"
      }

      // console.log('number of colors', colorArray.length, 'current index', indexCalc, numberOfLoops)

      return (<Line
        key={deployment + '_line'}
        // isAnimationActive={true}
        type="monotone"
        dataKey={deployment}
        stroke={colorArray[indexCalc]}
        strokeDasharray={dashStroke}
        dot={true}
      />)
    }) :
      <Line
        isAnimationActive={true}
        type="monotone"
        dataKey="number"
        stroke={theme.palette.primary.main}
        dot={true}
      />

  // console.log('testing formatted lines', lines)

  let displayReportStats = statistics && !statistics.error && showTotals ?
    <Grid item xs={12} md={4} lg={3} key={"reportStats_" + props.number}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ReportStats
          statistics={{ ...statistics, data: statistics.data.filter(item => customTimes.length === 0 || (item.category >= customTimes[0] && item.category <= customTimes[1])) }}
        />
      </Paper>

    </Grid > :
    ''




  let dataChart = statistics && !statistics.error && openChartData ?

    <Grid item xs={12} key={"dataTable_" + props.number}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <MaterialTable
          title={statistics.chartTitle}
          columns={columns}
          data={statistics.data.map(((entry, index) => {
            return { ...entry, id: index }
          }))}
          options={{
            grouping: true
          }}
        />
      </Paper>

    </Grid> :
    '';


  let chart = statistics && !statistics.error ?

    <Grid item xs={12} md={showTotals ? 8 : 12} lg={showTotals ? 9 : 12} key={"chart" + props.number}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',

          height: 380,
        }}
      >
        <React.Fragment>
          <div style={{ display: 'flex' }}>
            <div style={{ justifyContent: 'left' }}>
              <Title>
                <Link color="primary" href="#" align='center'
                  onClick={() => handleEditNameButton()}
                >

                  {!editName ?
                    <EditIcon
                      style={{ height: '.7em' }}
                    />
                    :
                    <CheckIcon style={{ height: '.7em' }}
                    />}
                </Link>
                {!editName ? chartName + " - " + statistics.chartTitle
                  : <TextField onChange={handleChangeName}
                    defaultValue={chartName}
                    label="Report Name"
                  >
                  </TextField>}
              </Title>

            </div>

            <div style={{ justifyContent: 'right', marginLeft: 'auto' }}>
              <Link color="primary" href="#" align='center' onClick={handleShowTotals}>
                {!showTotals ? 'Show Totals' : 'Hide Totals'}
              </Link>
            </div>
          </div>
          {!statistics.loading ?
            <ResponsiveContainer width="99%">
              <LineChart
                data={formattedData}
                margin={{
                  top: 16,
                  right: 16,
                  bottom: 0,
                  left: 24,
                }}
              >
                <XAxis
                  dataKey="date"
                  stroke={theme.palette.text.secondary}
                  style={theme.typography.body2}
                />
                <YAxis
                  stroke={theme.palette.text.secondary}
                  style={theme.typography.body2}
                  type="number"
                  domain={[0, 100]}
                >
                  <Label
                    angle={270}
                    position="left"
                    style={{
                      textAnchor: 'middle',
                      fill: theme.palette.text.primary,
                      ...theme.typography.body1,
                    }}
                  >
                    {statistics.valueTitle}
                  </Label>
                </YAxis>
                <Tooltip 
                wrapperStyle={{ zIndex: 200 }} 
                itemSorter={(item) => {
                  return item.value * -1;
                }}/>
                {lines}
              </LineChart>
            </ResponsiveContainer>
            : '...Loading'}
          {/* <Typography color="text.secondary" sx={{ flex: 1 }}>
            {customTimes.length > 0 ? 'Custom: ' + customTimes[0] + ' - ' + customTimes[1] : chartTimePeriod.reduce((previousValue, currentValue, currentIndex) => {
              if (currentIndex === 0) {
                return currentValue.name;
              } else {
                return previousValue + ', ' + currentValue.name;
              }
            }, '')}
                      </Typography> */}
          <div style={{ display: 'flex' }}>
            <Typography color="text.secondary">Time Period:</Typography>
            <ToggleButtonGroup
              size="small"
              aria-label="small button group"
              style={{ paddingLeft: '.5em' }}
              color={!showCustomTimes ? "primary" : "warning"}
              value={!showCustomTimes ? chartTimePeriod.map(time => time.name)[0] == 'custom' ? 'custom' : chartTimePeriod.map(time => time.value)[0] : 'apply' }
              exclusive
            >
              {!showCustomTimes ? timeButtons?.values.map(button =>
                <ToggleButton
                  sx={{ maxHeight: "2em" }}
                  key={button.value}
                  value={button.value}
                  onClick={() => handleTimeButton(button)}
                >
                  {button.name}
                </ToggleButton>
              )
                :
                ''}

              {!showCustomTimes ?
                <ToggleButton
                  sx={{ maxHeight: "2em" }}
                  value='custom'
                  key="customTime"
                  onClick={() => handleCustomTimeButton()}
                >
                  Custom
                </ToggleButton>
                : ''}
              {showCustomTimes && customTimes.length > 0 ?
                <ToggleButton
                  onClick={() => handleApplyCustomDate()}
                  value='apply'
                  key='apply_button'
                  sx={{ maxHeight: "2em" }}
                >
                  Apply
                </ToggleButton>
                : ''}
            </ToggleButtonGroup>



            {showCustomTimes && customTimes.length > 0 ?
              [
                <div key="starting_period_div" style={{ display: 'flex', paddingLeft: '.5em' }}>
                  <Typography key="starting_label" color="text.secondary">Starting:</Typography>
                  <MonthPicker
                    key='startTimePicker'
                    date={customTimes[0]}
                    setNewValue={setCustomStart}
                    month={chartGroupBy.findIndex(item => item.value == "month") >= 0 ? true : false}
                    hour={chartGroupBy.findIndex(item => item.value == "hour") >= 0 ? true : false} />
                </div>,
                <div key="ending_period_div" style={{ display: 'flex', paddingLeft: '.5em' }}>
                  <Typography key="ending_label" color="text.secondary">Ending:</Typography>
                  <MonthPicker
                    key='endMonthTimePicker'
                    date={customTimes[1]}
                    setNewValue={setCustomEndMonth}
                    month={chartGroupBy.findIndex(item => item.value == "month") >= 0 ? true : false}
                    hour={chartGroupBy.findIndex(item => item.value == "hour") >= 0 ? true : false} />
                </div>]
              : ''}

            <Typography color="text.secondary" style={{ paddingLeft: '.5em' }}>Group By:</Typography>
            <div style={{ paddingLeft: '.5em' }}>

              <ToggleButtonGroup
                size="small"
                aria-label="small button group"
                color="primary"
                value={chartGroupBy.map(time => time.value)[0]}
                exclusive >



                {groupByButtons?.values.map(button =>
                  <ToggleButton
                    sx={{ maxHeight: "2em" }}
                    value={button.value}
                    key={button.value}
                    onClick={() => handleGroupByButton(button)}
                  >
                    {button.name}
                  </ToggleButton>
                )}
              </ToggleButtonGroup>
            </div>
            <div style={{ justifyContent: 'right', marginLeft: 'auto' }}>
              <Link color="primary" href="#" align='center' onClick={handleValueData}>
                {!openChartData ? 'View Data' : 'Hide Data'}
              </Link>
            </div>
          </div>
        </React.Fragment>
      </Paper>
    </Grid >
    :
    '...Loading'

  const displayValue = !statistics?.error ? [chart, displayReportStats, dataChart] : <ErrorReport text={statistics?.translation} />;

  return (
    displayValue
  );
}