import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
// import GroupWorkIcon from '@mui/icons-material/GroupWork';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
import Collapse from '@mui/material/Collapse';
import Chip from '@mui/material/Chip';

import Dialog from './Dialog';

const ChipVersions = styled(Chip)(({ versions }) => ({
    "&::before": {
        content: `"versions: ${versions}"`,
        position: "absolute",
        bottom: '-1.5em',
        left: '0'
    }
}));


export function Report(props) {


    const [open, setOpen] = React.useState(false);
    const [collapse, setCollapse] = React.useState(false);
    const [reportName, setReportName] = React.useState('');
    const [metrics, setMetrics] = React.useState([]);
    // const [series, setSeries] = React.useState([]);
    const [timePeriod, setTimePeriod] = React.useState([]);
    const [groupBy, setGroupBy] = React.useState([]);
    const [actionType, setActionType] = React.useState([]);
    const [dialogs, setDialog] = React.useState([]);
    const [deployments, setDeployments] = React.useState({
        prd: false,
        allChecked: false,
        requestSeries: false,
        values: []
    });



    const reportHasValues = () => {
        // console.log('testing actual values here in ReportHasValues', metrics, metrics.length,timePeriod, timePeriod.length,groupBy,groupBy.length, actionType, actionType.length)
        if (
            metrics &&
            metrics.length > 0 &&
            //  series.length > 0 && 
            timePeriod &&
            timePeriod.length > 0 &&
            groupBy &&
            groupBy.length > 0 &&
            actionType &&
            actionType.length > 0 &&
            (deployments.values.length > 0 || deployments.allChecked)
        ) {
            return true;
        } else {
            return false;
        }
    }

    const updateMetricHandler = (value) => {
        // console.log('testing update metric handler', value)
        setMetrics(value)
    }

    const detectIsReportComplete = () => {

        console.log('testing this', open,
            metrics.length,
            //  series.length , 
            timePeriod.length,
            groupBy.length,
            actionType.length,
            deployments.values.length, (
            !open &&
            metrics.length > 0 &&
            //  series.length > 0 && 
            timePeriod.length > 0 &&
            groupBy.length > 0 &&
            actionType.length > 0 &&
            (deployments.values.length > 0 || deployments.allChecked)))
        if (
            reportHasValues()) {

            props.applyReport(previousReports => {

                const existingReportIndex = previousReports.findIndex(report => report.name === reportName)

                if (existingReportIndex >= 0) {
                    previousReports[existingReportIndex].metrics = metrics;
                    previousReports[existingReportIndex].timePeriod = timePeriod;
                    previousReports[existingReportIndex].groupBy = groupBy;
                    previousReports[existingReportIndex].actionType = actionType;
                    previousReports[existingReportIndex].deployments = deployments;

                    return [...previousReports];

                }
                else {
                    return [...previousReports.filter(rep => rep.name !== reportName),
                    {
                        metrics: metrics,
                        timePeriod: timePeriod,
                        groupBy: groupBy,
                        actionType: actionType,
                        deployments: deployments,
                        name: reportName,
                        setTime: setTimePeriod,
                        setName: setReportName,
                        setReportObjectName: props.setReportObjectName
                    }]
                }
            })
            // props.applyReport([{metrics: metrics, timePeriod: timePeriod, groupBy: groupBy, actionType: actionType, deployments: deployments, name: reportName}]) 
        }
    }

    useEffect(() => {
        console.log('updated UseEffect', reportHasValues())
        detectIsReportComplete();
    }, [metrics, timePeriod, groupBy, actionType, deployments]);



    useEffect(() => {
        console.log('use effect report', reportHasValues())
        if (!reportHasValues()) {
            console.log('running this once')
            setReportName(props.name)
            setMetrics(props.metrics ? props.metrics : []);
            setTimePeriod(props.timePeriod ? props.timePeriod : []);
            setGroupBy(props.groupBy ? props.groupBy : []);
            setActionType(props.actionType ? props.actionType : []);
            setDeployments(props.deployments ? props.deployments : {
                prd: false,
                allChecked: false,
                requestSeries: false,
                values: []
            })
        }
        // test new report
        // detectIsReportComplete();

    }, [
        props.metrics,
        props.timePeriod,
        props.groupBy,
        props.actionType,
        props.deployments,
        // timePeriod.length,
        // metrics.length,
        // groupBy.length,
        // actionType.length,
        // deployments.values.length
    ]


    );



    let handleMainListItemClick = (itemName) => {


        if (itemName === 'metrics') {
            setDialog([{ "selectionType": 'singleSelect', "pointerFunction": updateMetricHandler, "searchValue": itemName }])
            setOpen(true)
        } else if (itemName === "series") {
            // setDialog([{ "selectionType": 'multiSelectChip', "pointerFunction": setSeries, "searchValue": itemName }])
            setOpen(true)
        } else if (itemName === "timePeriod") {
            setDialog([{ "selectionType": 'singleSelect', "pointerFunction": setTimePeriod, "searchValue": itemName }])
            setOpen(true)
        } else if (itemName === "groupBy") {
            setDialog([{ "selectionType": 'singleSelect', "pointerFunction": setGroupBy, "searchValue": itemName }])
            setOpen(true)
        } else if (itemName === "actionType") {
            setDialog([{ "selectionType": 'multiSelectChip', "pointerFunction": setActionType, "searchValue": itemName }])
            setOpen(true)
        } else if (itemName === "deployments") {
            setDialog([{ "selectionType": 'deploymentsComp', "pointerFunction": setDeployments, "searchValue": itemName }])
            setOpen(true)
        }
    }




    const toggleCollapse = () => {
        setCollapse(collapse ? false : true)
    }

    const handleClose = () => {
        setOpen(false);
        detectIsReportComplete();
    };

    const handleRemoveMetric = (metric) => {
        setMetrics(metrics.filter(item => item.name !== metric.name))
    }

    const handleRemoveTimePeriod = (thing) => {
        setTimePeriod(timePeriod.filter(item => item.name !== thing.name))
    }

    // const handleRemotelySetTimePeriod = (timePeriodList) => {
    //     setTimePeriod(timePeriodList);
    //     detectIsReportComplete();
    // }



    // const handleRemoveSeries = (thing) => {
    //     setSeries(series.filter(item => item.name !== thing.name))
    // }

    const handleRemoveGroupBy = (thing) => {
        setGroupBy(groupBy.filter(item => item.name !== thing.name))
    }

    const handleRemoveActionType = (thing) => {
        setActionType(actionType.filter(item => item.name !== thing.name))
    }

    const handleRemoveDeployment = (thing) => {
        setDeployments(previousValue => {
            return ({ ...previousValue, values: previousValue.values.filter(item => item.name !== thing.name) })
        })
    }

    let displayMetricChips = metrics?.map((metric) => (
        <ListItemText key={`${metric.value}+list_item`}>
            <Chip key={metric.value} label={metric.name} onClick={() => handleRemoveMetric(metric)} />
        </ListItemText>

    ));

    // let displaySetricChips = series?.map((metric) => (
    //     <ListItemText key={`${metric.value}+list_item`}><Chip key={metric.value} label={metric.name} onClick={() => handleRemoveSeries(metric)} />
    //     </ListItemText>

    // ));

    let displayTimeChips = timePeriod?.map((metric) => (
        <ListItemText key={`${metric.value}+list_item`}>
            <Chip key={metric.value} label={metric.name} onClick={() => handleRemoveTimePeriod(metric)} />
        </ListItemText>

    ));

    let displayGroupByChips = groupBy?.map((metric) => (
        <ListItemText key={`${metric.value}+list_item`}>
            <Chip key={metric.value} label={metric.name} onClick={() => handleRemoveGroupBy(metric)} />
        </ListItemText>

    ));

    let displayActionTypeChips = actionType?.map((metric) => (
        <ListItemText key={`${metric.value}+list_item`}>
            <Chip key={metric.value} label={metric.name} onClick={() => handleRemoveActionType(metric)} />
        </ListItemText>

    ));


    let displayDeploymentsChips = deployments?.values.map((deployment, index) => {
        if (!deployment.versions) {
            return (
                <ListItemText key={`${deployment.value}+list_item_${index}`}>
                    <Chip key={deployment.value} label={deployment.name} onClick={() => handleRemoveDeployment(deployment)} />
                </ListItemText>
            )
        } else {
            return (
                <ListItemText key={`${deployment.value}+list_item_${index}`} sx={{ paddingBottom: '1em' }}>
                    <ChipVersions key={deployment.value} label={deployment.name} versions={deployment.versions.join(',')} onClick={() => handleRemoveDeployment(deployment)} />
                </ListItemText>
            )
        }


    });

    const displayProdChip = deployments.prd ?
        <ListItemText key={'list_item_prd'}>
            <Chip key="production" label="Production" />
        </ListItemText> : '';

    const displayAllChip = deployments.allChecked ?
        <ListItemText key={'list_item_all'}>
            <Chip key="allDeployments" label="All Deployments" />
        </ListItemText> : '';


    let renderDialog = dialogs?.map((item) =>
        <Dialog
            reportName={reportName}
            key={`${item.selectionType}_dialog`}
            type={item.selectionType}
            searchValue={item.searchValue}
            open={open}
            updateValue={item.pointerFunction}
            metrics={metrics.map(item => item.value)}
            groupBy={groupBy.map(item => item.value)}
            includedActionTypes={actionType.map(item => item.value)}
            timePeriod={timePeriod.map(item => item.value)}
            prd={deployments.prd}
            versions={deployments.values && deployments.values.findIndex(value => value.versions) >= 0}
            onClose={handleClose}

        />);

    return (
        <div>
            <React.Fragment>
                <ListItemButton onClick={toggleCollapse} >
                    <ListItemIcon>
                        <SummarizeIcon />
                    </ListItemIcon>
                    <ListItemText primary={reportName} />
                </ListItemButton>
                <Collapse in={collapse} >
                    <div style={{ marginLeft: '2em' }}>
                        <ListItemButton onClick={() => handleMainListItemClick("metrics")} >
                            <ListItemIcon>
                                <BarChartIcon />
                            </ListItemIcon>
                            <ListItemText primary="Metrics" />
                        </ListItemButton>
                        {props.displayChips ? displayMetricChips : ''}
                        {/* <ListItemButton onClick={() => handleMainListItemClick("series")}>
                            <ListItemIcon>
                                <AccountTreeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Series" />
                        </ListItemButton>
                        {props.displayChips ? displaySetricChips : ''} */}
                        <ListItemButton onClick={() => handleMainListItemClick("deployments")}>
                            <ListItemIcon>
                                <AccountTreeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Deployments" />
                        </ListItemButton>
                        {props.displayChips ? displayProdChip : ''}
                        {props.displayChips ? displayDeploymentsChips : ''}
                        {props.displayChips ? displayAllChip : ''}
                        <ListItemButton onClick={() => handleMainListItemClick("timePeriod")}>
                            <ListItemIcon>
                                <AccessTimeFilledIcon />
                            </ListItemIcon>
                            <ListItemText primary="Time Period" />
                        </ListItemButton>
                        {props.displayChips ? displayTimeChips : ''}
                        <ListItemButton onClick={() => handleMainListItemClick("groupBy")}>
                            <ListItemIcon>
                                <LayersIcon />
                            </ListItemIcon>
                            <ListItemText primary="Group By" />
                        </ListItemButton>
                        {props.displayChips ? displayGroupByChips : ''}
                        <ListItemButton onClick={() => handleMainListItemClick("actionType")}>
                            <ListItemIcon>
                                <BatchPredictionIcon />
                            </ListItemIcon>
                            <ListItemText primary="Action Type" />
                        </ListItemButton>
                        {props.displayChips ? displayActionTypeChips : ''}
                    </div>
                </Collapse>
            </React.Fragment>
            {renderDialog}
        </div>
    );
}


export default Report;