import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import {
  Draggable,
  DragDropContext,
  Droppable
} from 'react-beautiful-dnd';


// import GroupWorkIcon from '@mui/icons-material/GroupWork';
import AssignmentIcon from '@mui/icons-material/Assignment';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Report from './Report';
import savedReport from '../../../data/saved_reports.json'

export function MainListItems(props) {

  const [reports, setReports] = React.useState([]);

  const handleSavedReport = (name, metrics = [], timePeriod = [], groupBy = [], actionType = [], deployments = []) => {
    setReports(previousValues => {
      const duplicateNameCheck = previousValues.filter(item => item.name === name).length;
      const transformedName = duplicateNameCheck > 0 ? name + " #" + (duplicateNameCheck + 1) : name

      return [...previousValues, { name: transformedName, metrics: metrics, timePeriod: timePeriod, groupBy: groupBy, actionType: actionType, deployments: deployments, id: (previousValues.length + 1) }]
    })
  }

  const handleAddReport = () => {
    setReports(previousValues => {
      return [...previousValues, { name: 'Report #' + (previousValues.length + 1), id: (previousValues.length + 1) }]
    })
  }


  const handleSetReportObjectName = (newName, oldName) => {
    setReports(previousValues => {

      const changingReportNameIndex = previousValues.findIndex(report => report.name === oldName);

      console.log(previousValues, newName, oldName)
      previousValues[changingReportNameIndex].name = newName;

      console.log(newName, oldName, previousValues, changingReportNameIndex, previousValues[changingReportNameIndex].name);



      return [...previousValues]
    })

    props.applyReport(previousValues => {
      const changingReportNameIndex = previousValues.findIndex(report => report.name === oldName);

      console.log(previousValues, newName, oldName)
      previousValues[changingReportNameIndex].name = newName;

      console.log(newName, oldName, previousValues, changingReportNameIndex, previousValues[changingReportNameIndex].name);



      return [...previousValues]
    })

  }


  const onDragEnd = ({ destination, source }) => {
    console.log('testing drop delete', destination, source)
    if (destination.droppableId == 'droppable-delete') {
      let deletedReportName = '';
      const updatedItems = reports.filter((report, index) => {

        if (index === source.index) {
          deletedReportName = report.name
        }

        return index !== source.index
      })

      console.log('testing delete function', updatedItems)
      setReports(updatedItems)

      props.applyReport(previous => {
        return [...previous.filter(report => report.name !== deletedReportName)]
      });


    }
    else if (!destination) return;
    else {

      console.log('testing onDragEndf', reports, source.index, destination.index)
      const newItems = reorder(reports, source.index, destination.index);


      console.log('testing report sort order', newItems)
      setReports(newItems);

      // resort all displayed reports on right side
      // 
      props.applyReport(previous => {
        const newSortedList = previous.sort((report, oReport) => {
          return (newItems.findIndex(row => report.name === row.name) - newItems.findIndex(row => oReport.name === row.name))
        })

        console.log('testing new sort order', previous)
        return [...newSortedList]
      })
    }


  };

  const reorder = (
    list,
    startIndex,
    endIndex
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // console.log('reordered reports', result)
    return result;
  };

  const dispalySavedReports = savedReport.reports.map((report, index) => {
    return (

      <ListItemButton key={"saved_report_btn_" + index}
        onClick={() => handleSavedReport(
          report.name,
          report.metrics,
          report.timePeriod,
          report.groupBy,
          report.actionType,
          report.deployments
        )}  >
        <ListItemIcon key={"saved_report_icon_" + index}>
          <AssignmentIcon key={"saved_report_asgn_" + index} />
        </ListItemIcon>
        <ListItemText
          key={"saved_report_list_item_" + index}
          primary={report.name}
        />
      </ListItemButton>
    )
  })


  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {reports?.map((report, index) => {
                return (
                  <Draggable key={report.id} draggableId={'dragItem_' + report.id} index={index} >
                    {provided => (
                      <div ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <Report
                          name={report.name}
                          metrics={report.metrics}
                          timePeriod={report.timePeriod}
                          groupBy={report.groupBy}
                          actionType={report.actionType}
                          deployments={report.deployments}
                          displayChips={props.displayChips}
                          setReportObjectName={handleSetReportObjectName}
                          index={index}
                          key={'dragItem_' + report.id}
                          applyReport={props.applyReport} />

                      </div>
                    )}
                  </Draggable>)

              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable droppableId="droppable-delete">
          {provided => (
            <Box sx={{ flexGrow: 1, paddingTop: '1em' }} ref={provided.innerRef} {...provided.droppableProps}>
              <Grid
                container
                columns={1}
                spacing={1}
                direction="column"
                justifyContent="center"
                alignItems="center">
                <Grid item xs={1} style={{ width: "80%" }} >
                  <Tooltip title="Click to add an empty report">
                    <Button
                      variant="contained"
                      tooltip='Click to add Report'
                      disabled={false}
                      fullWidth
                      style={{ paddingLeft: '1em', paddingRight: '1em', width: '100%', minWidth: '5%' }}
                      onClick={() => handleAddReport()}
                    >
                      {!props.displayChips ?
                        <div align='center'><AddIcon /></div> :
                        <Typography>Add Report</Typography>
                      }
                    </Button>
                  </Tooltip>
                </Grid >
                <Grid item style={{ width: "80%" }} >
                  <Tooltip title="Drag and drop the report here to delete">
                    <Box tooltip='drag reports here to delete' sx={{ p: 1, border: '1px dashed grey', width: '100%' }}>
                      {!props.displayChips ?
                        <div align='center'><DeleteIcon /></div> :
                        <Typography align='center'>DELETE REPORT</Typography>
                      }
                    </Box>
                  </Tooltip>
                </Grid>
              </Grid>
              {provided.placeholder}

            </Box>
          )}
        </Droppable>
      </DragDropContext>

      <Divider sx={{ my: 1 }} />
      <React.Fragment>
        <ListSubheader component="div" inset>
          Saved reports
        </ListSubheader>

        {dispalySavedReports}

      </React.Fragment>
    </div >
  );
}
