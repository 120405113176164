import React, { useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import Grow from '@mui/material/Grow';


import { promiseHub } from '../../data/opa_hub_async';
import Deployments from './Deployments';
import DeploymentsDrawerItems from './DeploymentsDrawerItems';
import { useOpaDeleteDeployments, useOpaUpdateDeployments } from '../../data/ServiceComponent.js';
import MaterialTable from '@material-table/core';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https:/sagasystechnologygroup.com/">
                Sagasys Technology Group
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 280;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const mdTheme = createTheme();

function DeploymentsManagerContent() {
    const [open, setOpen] = React.useState(true);
    const [selectedDeployments, setSelectedDeployments] = React.useState([]);
    const [deloymentsForDeletion, setDeploymentsForDeletion] = React.useState([]);
    const [workspaceForUpdate, setWorkpaceForUpdate] = React.useState([]);
    const [servicesForUpdate, setServicesForUpdate] = React.useState([]);
    const [successDelDeployments, setSuccessDelDeployments] = React.useState([]);
    const [deploymentForUpdate, setDeploymentsForUpdate] = React.useState([]);
    const [successUpdateDep, setSuccessUpdateDep] = React.useState([]);
    const [pendingMassUpdate, setPendingMassUpdate] = React.useState(false);
    const [selectedWorkspace, setSelectedWorkspace] = React.useState([]);
    const [selectedServices, setSelectedServices] = React.useState([]);
    const [hasItems, setHasItems] = React.useState(false);
    const [showVersionInfo, setShowVersionInfo] = React.useState(false);
    const [showIndividualServices, setShowIndividualServices] = React.useState(false);

    const [bulkChange, setBulkChange] = React.useState(false);

    const [refreshDeployments, setRefreshDeployments] = React.useState(true);

    const [combinedMassChangedItems, setCombinedMassChangedItems] = React.useState([]);

    let [deletedDeployments] = useOpaDeleteDeployments(deloymentsForDeletion);
    let [updatedDeployments] = useOpaUpdateDeployments(deploymentForUpdate, workspaceForUpdate, servicesForUpdate);


    useEffect(() => {

        console.log('selected combined mass change items')

        setCombinedMassChangedItems(previousValue => {

            const transformedUpdateDeployments = successUpdateDep.map(update => {


                const actionType = selectedWorkspace.length > 0 && selectedServices.length > 0 ? 'Update Workspace & Service' : selectedWorkspace.length > 0 ? 'Update Workspace' : 'Update Service'




                const calcualtedResponse = update?.response_code ?
                    
                    update.response_code : !update.errors ? 'pending' : update.errors?.length === 0 ? '200' : '555'

                const calcualtedResponseText = update?.errors ?
                    ( update.response_reason && update.response_code === '204' ) || update?.errors?.length === 0  ? 'Updated Deployment!' : 'Error'  : 'pending'

                console.log('testing response text', update, calcualtedResponse);
                return ({
                    name: update.name,
                    action: actionType,
                    response_code: calcualtedResponse,
                    response_reason: calcualtedResponseText
                })
            })

            const transformedDeletedDeployments = successDelDeployments.map(deleted => {


                return ({
                    name: deleted.name,
                    action: 'Delete Deployment',
                    response_code: deleted.response_code
                })
            })

            return [...transformedUpdateDeployments, ...transformedDeletedDeployments]

        })

    }, [successUpdateDep, successDelDeployments])




    const toggleDrawer = () => {
        setOpen(!open);
    };


    useEffect(() => {

        console.log('selected deployment data', selectedDeployments, selectedDeployments.values.length)
        if (selectedDeployments.values.length > 0 && !hasItems) {
            setHasItems(true)
        } else if ((!selectedDeployments.values || selectedDeployments.values.length === 0) && hasItems) {
            setHasItems(false)
        }

    }, [selectedDeployments])


    useEffect(() => {

        console.log('testing identify updated deployments', updatedDeployments)
        if (updatedDeployments.data && updatedDeployments.data[0] && updatedDeployments.data[0].errors) {
            setRefreshDeployments(!refreshDeployments);
        }
        setSuccessUpdateDep(previousValues => {

            // don't update the same deployment again while waiting for a response
            const otherDeployments = previousValues.filter(item => updatedDeployments.data.findIndex(del => del.name == item.name) === -1)
            console.log('updated complete list of updated deployments', [...otherDeployments, ...updatedDeployments.data])

            return ([...otherDeployments, ...updatedDeployments.data]);

        })

    }, [updatedDeployments])



    useEffect(() => {



        console.log('testing identify deleted Deployments', deletedDeployments)
        setSuccessDelDeployments(previousValues => {

            const otherDeployments = previousValues.filter(item => deletedDeployments.findIndex(del => del.name == item.name) === -1)
            console.log('updated complete list of deleted deployments', [...otherDeployments, ...deletedDeployments])

            return ([...otherDeployments, ...deletedDeployments]);

        })
    }, [deletedDeployments])




    const handleDeleteDeployments = () => {
        setDeploymentsForDeletion(selectedDeployments.values);
        setSuccessDelDeployments(previousValues => [...previousValues, ...selectedDeployments.values]);
    }

    const updateDeploymentWorkspaceFunction = () => {
        setPendingMassUpdate(true);
        setDeploymentsForUpdate(selectedDeployments.values);
        setWorkpaceForUpdate(selectedWorkspace);
        setServicesForUpdate(selectedServices);
        setSuccessUpdateDep(previousValues => [...previousValues, ...selectedDeployments.values]);
        // toooo do - want ot toggle this to clear selection
        // setBulkChange(false)
        // setBulkChange(true)


    }


    const handleDeleteSingleDeployment = (deployment) => {
        console.log('testing delete single deployment', deployment)
        return promiseHub.deleteHub([{ name: deployment.name, value: deployment.name }])
    }

    const handleUpdateSingleDeployment = (deployment, workspace) => {
        console.log('testing updating single deployment', deployment, workspace)
        return promiseHub.updateDeployment(deployment, workspace);
    }


    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        {open ? <Typography style={{ flexGrow: '1', textAlign: 'center' }}>Deployments Panel</Typography> : ''}
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        <DeploymentsDrawerItems
                            isDrawerOpen={open}
                            deleteDeploymentsFunction={handleDeleteDeployments}
                            updateDeploymentWorkspaceFunction={updateDeploymentWorkspaceFunction}
                            setSelectedWorkspace={setSelectedWorkspace}
                            setSelectedServices={setSelectedServices}
                            setBulkUpdateFlag={setBulkChange}
                            hasItems={hasItems}
                            setShowVersionInfo={setShowVersionInfo}
                            setShowIndividualServices={setShowIndividualServices} />
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Container maxWidth={"xl"} sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        minHeight: 640,
                                    }}
                                >
                                    <Deployments
                                        updateValue={setSelectedDeployments}
                                        showVersionDescription={true}
                                        filterDataItems={[...successDelDeployments, ...deloymentsForDeletion]}
                                        editable={true} deleteFunction={handleDeleteSingleDeployment}
                                        updateFunction={handleUpdateSingleDeployment}
                                        refresh={refreshDeployments}
                                        enableSelection={bulkChange}
                                        showVersionInfo={showVersionInfo}
                                        showIndividualServices={showIndividualServices} />
                                </Paper>
                            </Grid>
                            <Grow in={combinedMassChangedItems.length > 0}>
                                <Grid item xs={12}>
                                    <MaterialTable
                                        title='Mass Update Status'
                                        columns={
                                            [
                                                { title: 'Name', field: 'name' },
                                                { title: 'Action', field: 'action' },
                                                {
                                                    title: 'Success', field: 'response_code', render: rowData => {
                                                        if (!rowData.response_code || rowData.response_code == 'pending') {
                                                            return <CircularProgress />
                                                        }
                                                        if (rowData.response_code && ( rowData.response_code === '204' || rowData.response_code == '200' )) {
                                                            return <DoneIcon style={{ color: 'green' }} />
                                                        }
                                                        else if (rowData.response_code && (rowData.response_code == '409' || rowData.response_code == '555')){
                                                            return <ErrorIcon style={{ color: 'red' }} />
                                                        }
                                                        else return rowData.response_code
                                                    }
                                                },
                                                { title: 'Response Code', field: 'response_code' }
                                                ,
                                                { title: 'Response Text', field: 'response_reason' }
                                            ]}
                                        data={combinedMassChangedItems}
                                    />
                                </Grid>
                            </Grow>
                        </Grid>
                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider >
    );
}

export default function DeploymentsManager() {

    return (
        <div>
            <DeploymentsManagerContent />
        </div>

    );
}
