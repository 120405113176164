import { HtmlHTMLAttributes, useEffect, useState } from 'react';
import { useOpaInterview } from '../../../data/ServiceComponent'
import { convertTextToTerminalOutput } from '../HelperFunctions/HelperFunctions';
import { Submission, InterviewItem, InterviewResponse } from '../HelperFunctions/OPADataTypes'

interface AppProps {
    assignedKey: string,
    serviceName: string,
    investigateUrl?: string,
    seedData?: Array<any>,
    params?: Array<any>,
    submission?: Submission,
    setCollectingInterviewData: (collectingInterviewData: InterviewItem) => void,
}


// create functional component for the interviews
const InterviewHandler = ({ assignedKey, serviceName, investigateUrl, seedData, params, submission, setCollectingInterviewData }: AppProps) => {

    const [interviewResponse] = useOpaInterview(serviceName, investigateUrl, seedData, params, submission)


    // handle a new submission
    useEffect(() => {
        if (interviewResponse?.items.length > 0) {

            //find first item of type input on the interviewResponse object
            let firstInputItem: InterviewItem | null = interviewResponse?.items.find(
                (item: InterviewItem) => item?.kind === "input" && item?.text !== undefined);
   

            firstInputItem!.activeInterview = true;
            firstInputItem!.investigateUrl = interviewResponse.investigateUrl;    
            setCollectingInterviewData(firstInputItem);
        

        }

    }, [interviewResponse])

    let questions: JSX.Element[] = interviewResponse.items ? interviewResponse.items.filter((item: InterviewItem) => item?.kind === "input" && item.text !== undefined).map((item: InterviewItem, index: number) => {

        return (
            convertTextToTerminalOutput(item?.text, index, 'green')
        )

    })
        : [];

    if (interviewResponse?.loading) {

        return (
            <div key={assignedKey + '_loading'}>
                {convertTextToTerminalOutput("loading...", 1, "green")}
            </div>
        )
    } else {



        return (
            <div key={assignedKey + '_questions'}>
                {questions}
            </div>

        )
    }
}


export default InterviewHandler;