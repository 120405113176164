import ReactJson from 'react-json-view'


function JSONViewer(props) {

    return(
        <ReactJson src={JSON.parse(props.json)} />
    )
}

export default JSONViewer

