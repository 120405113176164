import axios from 'axios';


const promiseHub = {
    // Make search an async function
    deleteHub: async deployment => {
      return axios({
        method: 'post',
        url: `${process.env.REACT_APP_BACK_END_URL}/deleteDeployment`,
        headers: { 'Content-Type': 'application/json' },
        data: {
          deployments: deployment
        }

      }).then(res => {
        console.log('delete response', res)
        return(res.data)

      }).catch(err => {
        return(err)
      })

    },
    updateDeployment: async (deployment, workspace) => {
        return axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACK_END_URL}/updateDeployment`,
            headers: { 'Content-Type': 'application/json' },
            data: {
              deployments: [deployment],
              workspaces: workspace
            }
    
          }).then(res => {
            console.log('update response', res)
            return(res.data)
    
          }).catch(err => {
            return(err)
          })
    
    },

  };

  export {promiseHub};