import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MaterialTable from '@material-table/core';
import { useDeployments, useWorkspaces } from '../../data/ServiceComponent.js';

import Versions from './Versions'


function Deployments(props) {
    //   const classes = useStyles();
    const {
        updateValue,
        enableSelection,
        prd,
        showVersionDescription, // difference in display between Deployments page and Statistics page
        filterDataItems = [],   // filtering out deleted items rather than refreshing
        editable = false,       // workspace is editable on the deployments page
        deleteFunction,         // prop function to handle when the delete button is clicked for a deployment
        updateFunction,         // prop to handle saving a changed workspace
        showVersionInfo,        // prop to include version number 
        refresh, // toggle used to trigger refresh
        showIndividualServices  // prop to show individual services as columns
    } = props;
    const [prodChecked, setProdChecked] = React.useState(false);
    const [internalRefresh, setInternalRefresh] = React.useState(false)
    const [data, setData] = React.useState([]);

    let [deployments] = useDeployments(prodChecked, showVersionInfo, internalRefresh);
    let [workspaces] = useWorkspaces();


    // console.log('deployments', deployments?.data)
    const columns = [
        { title: 'Name', field: 'name', editable: 'never' },
        {
            title: 'Workspace',
            field: 'workspace',
            editable: editable ? 'onUpdate' : 'never',
            lookup:
                workspaces ? workspaces.data.reduce((object, space) => {
                    return Object.assign(object, { [space.name]: space.name })
                }, {}) : {}

        },
        { title: 'Services', field: 'servicesAsString', editable: 'never' }
    ];


    const servicesColumns = [
       
        { title: 'Mobile', field: 'mobile', editable: 'never'},
        { title: 'Interview', field: 'interview'},
        { title: 'Web Service Assess', field: 'webserviceAssess'},
        { title: 'Web Service Interview', field: 'webserviceInterview'},
        { title: 'Services', field: 'servicesAsString', editable: 'never', hidden: true}
    ]

    const columnsWithVersionsInfo = [
        { title: 'Acitve', field: 'latestVersionIsactive', editable: 'never'},
        { title: 'Git Message', field: 'versionDescription', editable: 'never'},
        { title: 'Date', field: 'versionDate', type: "datetime", dateSetting: { locale: 'en-US' }, editable: 'never' },
        { title: 'Max Version', field: 'versionNum', editable: 'never'},
    ];




    useEffect(() => {
        console.log('toggle refresh on deployments')
        setInternalRefresh(!internalRefresh);

    }, [refresh, showVersionInfo, showIndividualServices]);


    useEffect(() => {
        setData(deployments.data);

    }, [deployments]);


    const editableRowConfig = {
        onRowDelete: (oldData) => {
            return (
                new Promise((resolve, reject) => {
                    deleteFunction(oldData).then(response => {
                        console.log('am i waiting for a response???', response, data)
                        setData(previousData => {
                            const index = oldData.tableData.id;
                            previousData.splice(index, 1);
                            return ([...previousData])
                        });
                        resolve();
                    })
                })
            )
        },
    }


    const determineSelectedColumns = (showVersionInfo, showIndividualServices) => {
       
        if(showVersionInfo && showIndividualServices){
            return [...columns.filter(item => item.title !== "Services"), ...columnsWithVersionsInfo, ...servicesColumns];
        } else if(showVersionInfo){
            return [...columns, ...columnsWithVersionsInfo];
        } else if(showIndividualServices){
            return [...columns.filter(item => item.title !== "Services"), ...servicesColumns];
        } else {
            return columns;
        }

    }

    const columnLevelEdit = {

        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            return (
                new Promise((resolve, reject) => {
                    updateFunction(rowData, newValue).then(response => {
                        console.log('am i waiting for a response???', response, columnDef)
                        setInternalRefresh(!internalRefresh)
                        resolve();
                    })
                })
            )
        }

    }


    const handleCaseSelectionChange = (rows) => {

        console.log('row data', rows)

        const updatedDeploymentsList = deployments.data.filter(diff => rows.map(row => row.name).indexOf(diff.name) >= 0).map(item => {
            return ({ "value": item.name, "name": item.name });
        })

        updateValue(previousValue => {
            return ({ ...previousValue, values: updatedDeploymentsList })
        })
    }

    const handleProdSwitchClicked = (event) => {
        // console.log('testing toggle', event.target.checked)
        setProdChecked(event.target.checked);
        updateValue(previousValue => {
            return ({ ...previousValue, prd: event.target.checked, values: [] })
        })
    }


    const handleSelectedVersion = (versions, deployment) => {

        updateValue(previousValue => {
            console.log('selected version', versions, versions.length, previousValue.values)
            if (versions.length === 0) {
                const filteredValues = previousValue.values.filter(row => row.value !== deployment);
                return ({ ...previousValue, values: filteredValues })
            }
            else if (previousValue.values.findIndex(row => row.value === deployment) >= 0) {
                const filteredValues = previousValue.values.filter(row => row.value !== deployment).filter(row => row.versions && row.versions.length > 0);
                return ({ ...previousValue, values: [...filteredValues, { "value": deployment, "name": deployment, versions: versions }] })
            } else {
                return ({ ...previousValue, values: [...previousValue.values, { "value": deployment, "name": deployment, versions: versions }] })
            }

        })


    }

    const materialValue = deployments && deployments.data ?
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, minHeight: '35em' }}>
            <Grid container sx={{ marginLeft: '2em', marginBottom: '1em' }}>
                <Grid item xs={2}>
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={prodChecked} onChange={handleProdSwitchClicked} inputProps={{ 'aria-label': 'controlled' }} />} label={!prodChecked ? 'Non-Prod' : "Production" } />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <MaterialTable
                        title='Deployments'
                        columns={determineSelectedColumns(showVersionInfo, showIndividualServices)}
                        data={data.filter(row => filterDataItems.findIndex(filter => filter.name === row.name) === -1)}
                        onSelectionChange={(rows) => handleCaseSelectionChange(rows)}
                        editable={editable ? editableRowConfig : {}}
                        cellEditable={editable ? columnLevelEdit : {}}
                        options={{
                            selection: enableSelection
                          }}
                        detailPanel={[
                            {
                                render: rowData => {

                                    console.log('row Data asdf', rowData.rowData.name)
                                    return (
                                        <Versions deployment={rowData.rowData.name} handleSelection={handleSelectedVersion} prd={prd} showVersionDescription={showVersionDescription} />
                                    )
                                }
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        </Box> :
        '...Loading'

    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    {materialValue}
                </Grid>
            </Grid>
        </Box >
    );
}

export default Deployments;