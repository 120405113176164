import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import JSONViewer from './Components/JSONViewer';
import Compare from "./Components/Compare/Compare";
import Strangle from "./Components/Strangle/Strangle";
import Statistics from "./Components/Statistics/Statistics";
import Search from "./Components/Search/Search";
import Deployments from "./Components/Deployments/DeploymentsManager";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from "react-router-dom";
import "./App.css";
import {
  RequireAuth,
  AuthProvider,
  useAuth,
} from "./Components/RequireAuth/RequireAuth";
import { LoginScreen } from "./Components/Login/LoginScreen";
import Chatbot from "./Components/Chatbot/Chatbot";
const theme = createTheme();



function MainToolbar() {
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const openMenu = Boolean(anchorElMenu);
  const auth = useAuth();
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          aria-expanded={openMenu ? "true" : undefined}
          onClick={handleClickMenu}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorElMenu}
          open={openMenu}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleCloseMenu}>
            <Link to="/">Home</Link>
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <Link to="/Statistics">Statistics</Link>
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <Link to="/Compare">Compare</Link>
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <Link to="/Search">Search</Link>
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <Link to="/Deployments">Deployments</Link>
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <Link to="/NOAs">NOAs</Link>
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              onClick={(e) => {
                e.preventDefault();
                auth?.signout();
              }}
              href=""
            >
              Logout
            </a>
          </MenuItem>
        </Menu>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Bendicator - because every difference counts
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

function PageTracking() {
  const location = useLocation();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_title: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);

  return null;
}

function App() {
  return (
    <Router>
      <PageTracking />
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/login" element={<LoginScreen />} />
          </Routes>
          <RequireAuth>
          
            <MainToolbar />
            <Chatbot />
            <Routes>
              <Route path="/" element={<Compare />} />
              <Route path="/Statistics" element={<Statistics />} />
              <Route path="/Compare" element={<Compare />} />
              <Route path="/Search" element={<Search />} />
              <Route path="/Deployments" element={<Deployments />} />
              <Route path="/NOAs" element={<Strangle />} />
            </Routes>
          </RequireAuth>
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
