import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useWorkspaces } from '../../data/ServiceComponent.js';



export default function SelectLabels(props) {
    const { setSelectedWorkspace, setHasWorkspaceValue } = props;
    const [workspace, setWorkspace] = React.useState('');

    let [workspaces] = useWorkspaces(false);

    const handleChange = (event) => {
        setWorkspace(previousValue => {

            if(previousValue === "" && event.target.value !== "") {
                console.log('setting workspace value flag')
                setHasWorkspaceValue(true)
            } else {
                setHasWorkspaceValue(false)
            }

            return event.target.value
        });
        setSelectedWorkspace(event.target.value);
        
    };


    const workspaceItems = workspaces ?
        workspaces?.data.map((workspace, index) => {
            return (
                <MenuItem key={index + '_workspace'}
                    value={workspace.name}>{workspace.name}</MenuItem>
            )
        })
        : '';


    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="simple-select-helper-label">Workspace</InputLabel>
                <Select
                    labelId="simple-select-helper-label"
                    id="simple-select-helper"
                    value={workspace}
                    label="Workspace"
                    onChange={handleChange}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {workspaceItems}
                </Select>
                <FormHelperText>Select a workspace</FormHelperText>
            </FormControl>
        </div>
    );
}
