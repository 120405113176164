import Table from '../../Table/Table'
import React from 'react';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import JSONViewer from './Components/JSONViewer';
import Button from '@mui/material/Button';
import SelectServices from '../../SelectServices/SelectServices'
import CaseCollector from '../CaseCollector'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CompareDrawer from '../AdvancedCompareDrawer/CompareDrawer';

import EnvironmentSelector from '../EnvironmentSelector/EnvironmentSelector';


const theme = createTheme();

function Compare() {

  const [serviceOne, setServiceOne] = React.useState(null);
  const [serviceTwo, setServiceTwo] = React.useState(null);
  const [selectCases, setSelectCases] = React.useState(null);
  const [returnAll, setReturnAll] = React.useState(false);
  const [selectedServices, setSelectedServices] = React.useState([]);
  const [environment, setEnvronment] = React.useState("sit2");
  const [rulebase, setRulebase] = React.useState("");
  const [casesPerQuery, setCasesPerQuery] = React.useState(50);
  const [differences, setDifferences] = React.useState(null);


  let [isProcessing, setIsProcessing] = React.useState(false);
  let [isCancelled, setIsCancelled] = React.useState(false);


  const setEnvironmentFromMenu = (env) => {
    setEnvronment(env);
    // handleCloseEnv();
  }


  const [caseLimit, setCaseLimit] = React.useState(1000);
  const [startLimit, setStartLimit] = React.useState(0);
  const [anchorElStartLimit, setAnchorEStartlLimit] = React.useState(null);
  const [anchorElLimit, setAnchorElLimit] = React.useState(null);
  const [resetTable, setResetTable] = React.useState(false);
  const [openAdvancedDrawer, setOpenAdvancedDrawer] = React.useState(false);
  const [outcomes, setOutcomes] = React.useState([]);
  const openLimit = Boolean(anchorElLimit);
  const openStartLimit = Boolean(anchorElStartLimit);

  const handleCloseLimit = () => {
    setAnchorElLimit(null);
  };

  const handleCloseStartLimit = () => {
    setAnchorEStartlLimit(null);
  };


  const handleClickLimit = (event) => {
    setAnchorElLimit(event.currentTarget);
  };

  const handleClickStartLimit = (event) => {
    setAnchorEStartlLimit(event.currentTarget);
  };

  const setCaseLimitFromLimit = (env) => {
    setCaseLimit(env);
    handleCloseLimit();
  }

  const setCaseLimitFromStartLimit = (env) => {
    setStartLimit(env);
    handleCloseStartLimit();
  }





  const getServices = () => {


    setSelectedServices([]);
    setIsCancelled(false);

    if (Array.isArray(selectCases)) {
      if (selectCases.length === 0) {
        setSelectCases(null)
      }
    }
    //  console.log([serviceOne, serviceTwo]);
    setSelectedServices([serviceOne, serviceTwo]);
    setIsProcessing(true)
    setResetTable(true);
  }

  // useEffect(() => {
  //   console.log(serviceOne !== null, serviceTwo !== null);
  //   if( serviceOne !== null && serviceTwo !== null ) {
  //     setSelectedServices([serviceOne, serviceTwo]);
  //   }

  // });

  const returnAllHandler = (event) => {
    setReturnAll(!returnAll);
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: '1em' }}>
        <Typography variant="h4" component="div" gutterBottom>Compare Two Deployments</Typography>
        <div style={{ marginLeft: '1em' }} >
          <SelectServices
            servOne={setServiceOne}
            servTwo={setServiceTwo}
            rulebase={setRulebase}
            casesPerQuery={setCasesPerQuery}

          >
          </SelectServices>
          <div style={{ display: 'flex' }} >

            <Button
              id="basic-limit"
              aria-controls="basic"
              aria-haspopup="true"
              aria-expanded={openLimit ? 'true' : undefined}
              disabled={selectCases !== null}
              onClick={handleClickLimit}
            >

              {caseLimit !== null ? caseLimit + ' cases' : '200 cases'}
            </Button>
            <Button
              id="basic-start-limit"
              aria-controls="basic"
              aria-haspopup="true"
              aria-expanded={openStartLimit ? 'true' : undefined}
              disabled={selectCases !== null}
              onClick={handleClickStartLimit}
            >

              {startLimit !== null && startLimit !== 0 ? 'Skipping the first ' + startLimit : 'Skipping None'}
            </Button>

            <Menu
              style={{ alignItems: 'center', display: 'flex' }}
              id="basic-menu-start-limit"
              anchorEl={anchorElStartLimit}
              open={openStartLimit}
              onClose={handleCloseStartLimit}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => setCaseLimitFromStartLimit(0)}>None</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromStartLimit(200)}>200</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromStartLimit(500)}>500</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromStartLimit(1000)}>1000</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromStartLimit(1500)}>1500</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromStartLimit(2000)}>2000</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromStartLimit(2500)}>2500</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromStartLimit(5000)}>5000</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromStartLimit(10000)}>10000</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromStartLimit(15000)}>15000</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromStartLimit(20000)}>20000</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromStartLimit(25000)}>25000</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromStartLimit(30000)}>30000</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromStartLimit(35000)}>35000</MenuItem>
            </Menu>
            <Menu
              style={{ alignItems: 'center', display: 'flex' }}
              id="basic-menu-limit"
              anchorEl={anchorElLimit}
              open={openLimit}
              onClose={handleCloseLimit}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => setCaseLimitFromLimit(200)}>200</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromLimit(500)}>500</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromLimit(1000)}>1000</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromLimit(1500)}>1500</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromLimit(2000)}>2000</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromLimit(2500)}>2500</MenuItem>
              <MenuItem onClick={() => setCaseLimitFromLimit(5000)}>5000</MenuItem>

            </Menu>
            <Typography variant="button" gutterBottom style={{ alignItems: 'center', display: 'flex', padding: '6px 8px', marginBottom: '0px', paddingRight: '2em' }}>Or </Typography>
            <CaseCollector
              setCases={setSelectCases}
              onEnter={getServices}
              submitDisabled={!(serviceOne !== null && serviceTwo !== null) || isProcessing} />

          </div>
          <div style={{ display: 'flex' }} >
            <FormControlLabel
              style={{ marginRight: '1px' }}
              control={
                <Switch
                  checked={!returnAll}
                  onChange={returnAllHandler}
                  name="returnAll"
                  color="primary"
                />
              }
              label={<Typography style={{ alignItems: 'center', display: 'flex', padding: '6px 8px', marginBottom: '0px' }} variant="button" gutterBottom >only show differences</Typography>}
            />
            <EnvironmentSelector environmentSetter={setEnvironmentFromMenu} />
            {/* <Button
              style={{ alignItems: 'center', display: 'flex', padding: '6px 8px', marginBottom: '0px' }}
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={openEnv ? 'true' : undefined}
              onClick={handleClickEnv}
            >
              {environment !== null ? "Env - " + environment : "Environment"}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElEnv}
              open={openEnv}
              onClose={handleCloseEnv}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => setEnvironmentFromMenu("uat1")}>UAT1</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("uat2")}>UAT2</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("uat3")}>UAT3</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("sit3")}>SIT3</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("sit2")}>SIT2</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("sit1")}>SIT1</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("dev2")}>DEV2</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("stg")}>STG</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("local")}>Local</MenuItem>
            </Menu> */}
            <CompareDrawer
              open={openAdvancedDrawer}
              setOpen={setOpenAdvancedDrawer}
              services={[serviceOne, serviceTwo]}
              setOutcomes={setOutcomes}
              outcomes={outcomes}
              differences={differences}
            />
          </div>
        </div>

        <div style={{ marginTop: '2em', marginLeft: '1em', marginBottom: '1em' }}>
          <Button
            variant="contained"
            onClick={() => {
              window.gtag('event', 'compare_services_button_clicked');
              getServices()}
            }
            tooltip='click to compare'
            disabled={!(serviceOne !== null && serviceTwo !== null) || isProcessing}
          >
            Compare
          </Button>

          <Button
            sx={{ marginLeft: '6px' }}
            variant="outlined" color="error"
            onClick={() => setIsCancelled(true)}
            tooltip='click to cancel current run'
            disabled={!isProcessing}
          >
            Cancel
          </Button>
        </div>
        {selectedServices.length > 1 &&
          <div>
            <Table
              getServices={selectedServices}
              specificCases={selectCases}
              returnAllCases={returnAll}
              environment={environment}
              startLimit={startLimit}
              numberOfCases={caseLimit}
              rulebase={rulebase}
              casesPerQuery={casesPerQuery}
              processingMode={setIsProcessing}
              cancelled={isCancelled}
              setIsCancelled={setIsCancelled}
              reset={resetTable}
              setResetTable={setResetTable}
              outcomes={outcomes}
              setDifferencesForAdvancedFeatures={setDifferences}

            />
          </div>
        }


        {/* <div>
          {opaResults === null ? 'compare something' : <JSONViewer json={opaResults} />}
        </div> */}
      </div>
    </ThemeProvider>

  );
}

export default Compare;
