import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDeployments } from '../data/ServiceComponent';


function Service(props) {
    //   const classes = useStyles();

    console.log('testing prop values', props)

    const [services] = useDeployments(false);


    const handleChangeService = (event, value) => {
        if(value === null) {
            props.setServiceName("");
           console.log('testing this prop', props.triggerNextStep) 
        } else {
            props.setServiceName(value.key);
        }
        
    };

    return (
        <Box sx={{ minWidth: '10em', maxWidth: '25em' }}>
            <Box >
                <Autocomplete
                    disablePortal
                    id="combo-box-serv-one"
                    onChange={(event, value) => handleChangeService(event, value)}
                    isOptionEqualToValue={(option, value) => option.key === value.key}
                    options={services.data.map((serv) => (
                        {
                            "label": serv.name,
                            "key": serv.name
                        }
                    ))
                    }
                    renderInput={(params) => <TextField {...params} label="Select Service" />}

                >
                </Autocomplete>
            </Box>

        </Box>
    );
}

export default Service;