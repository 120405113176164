import React, { useEffect} from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import MultiSelectChip from './MultiSelectChip';
import Deployments from './Reporting_Deployments';
import SingleSelect from './SingleSelect'
import { useOpaStatisticsData } from '../../../data/ServiceComponent';

export default function SimpleDialog(props) {
    const { reportName, type, searchValue, open, onClose, metrics, groupBy, includedActionTypes, timePeriod, updateValue, prd, versions } = props;

    const [values] = useOpaStatisticsData(searchValue, metrics, groupBy, includedActionTypes, timePeriod);

    console.log('testing data stuff', values, searchValue, metrics, groupBy, includedActionTypes, timePeriod)

    const [collector, setCollector] = React.useState('');

    const handleClose = () => {
        onClose();
    };

    useEffect( () => {

        if(type === 'multiSelectChip') {
            setCollector(<MultiSelectChip updateValue={updateValue} type={searchValue} values={values}/>)
        } else if(type === 'deploymentsComp') {
            setCollector(<Deployments updateValue={updateValue} prd={prd} versions={versions} includeAllToggle={true}/>)
        } else if( type === 'singleSelect') {
            setCollector(<SingleSelect updateValue={updateValue} type={searchValue} values={values} />)
        }
    
    }, [reportName, type, searchValue, open, onClose, metrics, groupBy, includedActionTypes, timePeriod, SingleSelect]); 


    const dialog = type !== 'deploymentsComp' ? `${values.name} for ${reportName}` : 'Select Deployments';


    return (
        <Dialog onClose={handleClose} open={open} maxWidth='xl'> 
            <DialogTitle>{dialog}</DialogTitle>
            {collector}
        </Dialog>
    );
}
