import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Dashboard from './dashboard/Dashboard';

function Statistics(props) {



    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <Dashboard />
                </Grid>
            </Grid>
        </Box>
    );
}

export default Statistics;