import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MaterialTable from '@material-table/core';
import InputContainer from '../DataCollection/InputContainer'
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useDataModel } from '../../data/ServiceComponent';



function DataModel(props) {
    //   const classes = useStyles();
    
    const [formattedDatamodel, setFormattedDatamodel] = React.useState(null);
    const [collectInputs, setCollectInputs] = React.useState(false);
    const [selectedRows, setCollectedRows] = React.useState([]);
    const [resetDefaults, setResetDefaults] = React.useState(false);


    let [dataModel, defaultOutcomes] = useDataModel(props.service, props.populateDefaults, resetDefaults);



    const columns = [

        { title: 'Name', field: 'Name', checked: true },
        { title: 'Type', field: 'Type' },
        { title: 'Entity', field: 'Entity'},
        { title: 'Inferred', field: 'Inferred' },
        collectInputs ? 
        {
            title: 'Value', field: 'Value', render: (rowdata) => {

                let collectionElement = null;

                if (props.currentSearchAttributes[rowdata.Name]) {
                    collectionElement =
                        <InputContainer
                            key={'inputContainer' + rowdata.Name}
                            currentSearchAttributes={props.currentSearchAttributes}
                            setSearchAttribute={props.setSearchAttribute}
                            rowName={rowdata.Name}
                            parentId={rowdata.parentId ? rowdata.parentId : 0}
                        />
                } else {
                    collectionElement =
                        <InputContainer
                            key={'inputContainer' + rowdata.Name}
                            currentSearchAttributes={props.currentSearchAttributes}
                            setSearchAttribute={props.setSearchAttribute}
                            rowName={rowdata.Name}
                            parentId={rowdata.parentId ? rowdata.parentId : 0}
                        />
                }

                return (
                    collectionElement
                )
            }



        } : {}
    ];



    let attributes = [];
    let counter = 1;

    const formatDataModel = (dJson, parentId = 0, entityName, callback) => {
        // console.log('testing rolling state variable', dataModel);
        let parentEntities = [];
        for (let key in dJson) {
            // console.log('testing values', dJson[key], counter, parentId);

            let newNode = {}
            if (parentId === 0) {
                newNode = {
                    "id": counter,
                    "Name": key,
                    "Type": dJson[key].type,
                    "Inferred": dJson[key].inferred,
                    "Entity": entityName
                }
            }
            else {
                newNode = {
                    "id": counter,
                    "Name": key,
                    "Type": dJson[key].type,
                    "Inferred": dJson[key].inferred,
                    "parentId": parentId,
                    "Entity": entityName
                }
            }

            //  console.log('testing default outcomes', props.populateDefaults)
            if(props.populateDefaults) {
                if(defaultOutcomes.indexOf(newNode.Name) >= 0) {
                    newNode = {
                        ...newNode,
                        tableData: {checked: true}
                    }
                }
            } else {
                if(props.outcomes.indexOf(newNode.Name) >= 0) {
                    newNode = {
                        ...newNode,
                        tableData: {checked: true}
                    }
                }
            }


            attributes = attributes.concat([newNode]);




            // console.log('check for properties', dJson[key]['properties']);
            if (dJson[key]['properties']) {
                // console.log('we made it inside the check!' )
                parentEntities.push([key, counter]);
            }
            counter++;
        }

        if (parentEntities.length > 0) {
            for (let parentNodeList in parentEntities) {
                // console.log('testing this child node', parentNodeList, dJson[parentEntities[parentNodeList][0]]['properties']);
                formatDataModel(dJson[parentEntities[parentNodeList][0]]['properties'], parentEntities[parentNodeList][1], parentEntities[parentNodeList][0], function () {
                    // console.log("do nothing")
                });
            }
        }

        callback();

    }

    useEffect(() => {

        console.log('testing value of populate defaults', props.populateDefaults, )

        if(props.populateDefaults){
            setResetDefaults(previous => !previous)
        }

    }, [props.populateDefaults]);


    useEffect(() => {

        console.log('testing value of outcomes', props.outcomes)
        if (!dataModel?.loading) {
            // console.log('testing values in use effect', dataModel.properties)
            formatDataModel(dataModel.properties, 0, 'Global', () => {
                // console.log(attributes);
                setFormattedDatamodel(attributes);
            })
        }

    }, [dataModel]);

    // useEffect(() => {

    //     console.log('testing value of outcomes', props.outcomes, tableRef)
    //     if(props.outcomes.length > 0 && tableRef.current !== null) {
    //         tableRef.current.dataManager.data = tableRef.current?.dataManager?.data?.map(row => {
    //             if(props.outcomes.indexOf(row.Name) >= 0){
    //                 return {
    //                     ...row,
    //                     tableData: {
    //                         ...row.tableData,
    //                         checked: true
    //                     }
    //                 }

    //             } else {
    //                 return row
    //             }
    //         })
    //     }

    // }, [formattedDatamodel]);


    const handleSelectAttribute = (rows) => {
        console.log('testing select rows', rows)
        setCollectedRows(rows.map(row => row.Name));
        props.setOutcomes(rows.map(row => row.Name));
    }

    return (
        <Box>
            <Grid container >
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    {dataModel?.loading || formattedDatamodel === null ?
                        <CircularProgress />
                        :
                        <MaterialTable
                            columns={columns}
                            title={<Typography>{`Data Model for ${props.service.toUpperCase()}`}</Typography>}
                            data={formattedDatamodel.filter(attribute => attribute.Type !== 'containmentRelationship')}
                            // parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                            onSelectionChange={(rows) => handleSelectAttribute(rows)}
                            options={{
                                grouping: true,
                                search: true,
                                selection: true,
                                // selectionProps: rowData => ({
                                //     // containmentRelationships produce a 500 error if provided as a goal
                                //     disabled: rowData.Type === 'containmentRelationship',
                                //     color: 'primary'
                                //   })
                            }}
                        // editable={{
                        //     onRowUpdate: (newData, oldData) => {
                        //         console.log('new data', newData)
                        //     }
                        // }}
                        />
                    }

                </Grid>
                <Grid item xs={12}>

                </Grid>
            </Grid>
        </Box >
    );
}

export default DataModel;