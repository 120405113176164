import IconButton from '@mui/material/IconButton';
import TerminalIcon from '@mui/icons-material/Terminal';

type AppProps = {
    toggleAction: () => void;
}

const Launcher = ( {toggleAction}: AppProps) => {


    const handleClick = () => {
        // console.log("Clicked");
        toggleAction();
    }

    return (
        <IconButton 
           // fix to the bottom right of the screen
            aria-label='chatbot'
            size="large"
            sx={{ bottom: 0, right: 0, position: 'fixed', margin: 2 }}    
            className="launcherButton"
            onClick={handleClick}
            >
            <TerminalIcon fontSize='inherit'/>
        </IconButton>
    )

}
export default Launcher;