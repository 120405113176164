import React, { useRef, useEffect, useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer-continued';
// import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
const axios = require('axios');


// const a = 10
// const b = 10
// const c = () => console.log('foo')

// if(a > 10) {
//   console.log('bar')
// }

// console.log('done')
// `;



// const useStyles = makeStyles((theme) => ({
//     root: {
//       display: 'flex',
//       '& > * + *': {
//         marginLeft: theme.spacing(2),
//       },
//     },
//   }));


function JsonCompare(props) {

  const [oldCode, setOldCode] = useState(null);
  const [newCode, setNewCode] = useState(null);
  // const [oldCodeWithSort, setOldCodeWithSort] = useState(null);
  // const [newCodeWithSort, setNewCodeWithSort] = useState(null);
  const isMounted = useRef(false);

  function decisionParser(path, res, location) {

    //console.log('starting parser', path, res, location);
    if (path.length > location + 1) {
      return decisionParser(path, res[path[location]], ++location)
    }
    else {
      console.log('testing response mapper', res['@decisionReports'][path[location]])
      return (res['@decisionReports'][path[location]])
    }
  
  }


  useEffect(() => {
    if (isMounted.current) {

    } else {


      if (!props.lhsData) {
        try {
          isMounted.current = true;
          //  console.log('Requests', props.json_request)
          //    console.log('titles', props.titles)
          axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACK_END_URL}/compare`,
            headers: { 'Content-Type': 'application/json' },
            data: {
              request: props.json_request,
              services: props.titles,
              outcomes: props.outcomes
            }

          }).then(res => {
            // console.log('JSON.stringify(res.data[0][0], null,', JSON.stringify(res.data[0][0], null, '\t'));
            if (props.decision) {
              if(!res.data[0][0]['@errors']){
                console.log('parsing decision for 0 0', props.completeData.path, JSON.stringify(decisionParser(props.completeData.path, res.data[0][0], 1), null, '\t'))
                setOldCode(JSON.stringify(decisionParser(props.completeData.path, res.data[0][0], 1), null, '\t'));
              } else {
                console.log('setting errors for 0 0', JSON.stringify(res.data[0][0]['@errors'][0].detail))
                setOldCode(JSON.stringify(res.data[0][0]['@errors'][0].detail))
              }

              if(!res.data[1][0]['@errors']){
                console.log('parsing decision for 0 1', props.completeData.path, JSON.stringify(decisionParser(props.completeData.path, res.data[1][0], 1), null, '\t'))
                setNewCode(JSON.stringify(decisionParser(props.completeData.path, res.data[1][0], 1), null, '\t'));
              } else {
                console.log('setting errors for 0 1', res.data[1][0]['@errors'][0].detail)
                setNewCode(JSON.stringify(res.data[1][0]['@errors'][0].detail))
              }

            } else {
              setOldCode(JSON.stringify(res.data[0][0], null, '\t'));
              setNewCode(JSON.stringify(res.data[1][0], null, '\t'));
            }
            // setOldCodeWithSort(JSON.stringify(res.data[2][0], null, '\t'));
            // setNewCodeWithSort(JSON.stringify(res.data[3][0], null, '\t'));
          })
        } catch (error) {
          console.log(error);
        }
      } else {
        setOldCode(JSON.stringify(props.lhsData, null, '\t'));
        setNewCode(JSON.stringify(props.rhsData, null, '\t'));
      }


    }

  }, [props.lhsData, props.rhsData, props.json_request, props.titles]);








  let leftTitle = props.titles[0];
  let rightTitle = props.titles[1];
  ///const classes = useStyles();


  if (oldCode !== null && newCode !== null) {

    return (
      <div>
        <ReactDiffViewer
          oldValue={oldCode}
          newValue={newCode}
          splitView={true}
          showDiffOnly={true}
          leftTitle={leftTitle}
          rightTitle={rightTitle}
        />
        {/* <div style={{color: 'red', fontSize: 40, textAlign: 'center', padding: 20}}>With Sorted Data</div>
            <ReactDiffViewer 
              oldValue={oldCodeWithSort} 
              newValue={newCodeWithSort} 
              splitView={true} 
              showDiffOnly={true}
              leftTitle={`${leftTitle} (with sorted data)`} 
              rightTitle={`${rightTitle} (with sorted data)`}
            /> */}
      </div>
    );
  }
  else {

    return (
      <CircularProgress />
    )
  }
}

export default JsonCompare