import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';


export default function ErrorReport(props) {
    return (
        <Grid item xs={12}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 640,
                }}
            >

                <pre>
                    <code>{`
                                                             
                                                             ⠀⠀  ⠀⠀
                                                                ⢱⣆⠀⠀⠀   ⠀⠀
                                                             ⠀⠀⠈⣿⣷⡀⠀⠀⠀ ⠀⠀  
                                                      /^\\     ⠀⢸⣿⣿⣷⣧⠀⠀⠀⠀
                                                /\\    "V"⠀⠀  ⡀⢠⣿⡟⣿⣿⣿⡇⠀  
                                               /__\\\\   I     ⠀⣳⣼⣿⡏⢸⣿⣿⣿⢀⠀⠀⠀ 
                                              //..\\\\   I     ⣰⣿⣿⡿⠁⢸⣿⣿⡟⣼⡆      
                                              \\].\`[/   I  ⢰⢀⣾⣿⣿⠟⠀⠀⣾⢿⣿⣿⣿⣿
                                              /l\\/j\\  (]  ⢸⣿⣿⣿⡏⠀⠀⠀⠃⠸⣿⣿⣿⡿ 
                                             /. ~~ ,\\/ I   ⢳⣿⣿⣿⠀⠀⠀⠀⠀⠀⢹⣿⡿⡁⠀
                                             \\\\L__j^\\/ I   ⠹⣿⣿⡄⠀⠀⠀⠀⠀⢠⣿⡞⠁
                                              \\/--v}   I   ⠀ ⠈⠛⢿⣄⠀⠀⠀⣠⠞⠋⠀⠀    ⠀⠀⠀
                                              |    |   I       _________
                                              |    |   I     c(\`       ')o
                                              |    l   I       \\.     ,/
                                            _/j  L l\\_ !     _//^---^\\\\_
                                            
                         Oh no! The Report has failed.  ${ props.text ? props.text : 'I can\'t figure out why'}
                                            `


                    }</code>
                </pre>
               
            </Paper>
        </Grid>
    );
}
