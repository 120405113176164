import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name, selection, theme) {
    return {
        fontWeight:
            selection.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function MultipleSelectChip(props) {
    const { updateValue, values } = props;
    const theme = useTheme();
    const [selection, setSelection] = React.useState([]);




    const handleChange = (event) => {

        const {
            target: { value },
        } = event;

        let selections = typeof value === 'string' ? value.split(',') : value;
        let jsonValues = selections.map(selection => {
            return (values.values.filter(row => row.name === selection)[0])
            })
      
        // console.log('json test', jsonValues, selections)

        setSelection(
            // On autofill we get a stringified value.
            // typeof value === 'string' ? value.split(',') : value,
            selections
        )
        updateValue(jsonValues);
    };

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-chip-label">Select</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={selection}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {values.values.map((metric) => (
                        <MenuItem
                            key={metric.value}
                            value={metric.name}
                            style={getStyles(metric.name, selection, theme)}
                        >
                            {metric.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
