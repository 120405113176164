import { useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import { ExportCsv } from '@material-table/exporters';
import Typography from '@mui/material/Typography';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { utils, writeFile } from 'xlsx';

function AdvancedDifferenceTable(props) {
    
    const [formatedData, setFormatedData] = useState([]);

    const handleSelectAttribute = (rows)  => {
        console.log('selected a thing', rows)
    }


    useEffect(() => {
        console.log('testing values of matchAttributes', props.matchAttributes, props.data)


    },[props.data])


    const appendSheet = (child, wb) => {
        let values = [];
        props.data.forEach(diff => {
            console.log('testing diff element', diff)
            values = values.concat(diff[child].map(row => {
                return ( {
                    edbc_id: diff.edbc_id,
                    match: diff.match,
                    ...row
                })
            }))
        })
        let childWs = utils.json_to_sheet(values);
        utils.book_append_sheet(wb, childWs, child);
    }

    return (

        <MaterialTable
            columns={props.columns}
            title={<Typography>{`Complex Data Extraction`}</Typography>}
            data={props.data.map(row => {
                let convertedRow = {...row};
                props.childObjects.forEach(child => delete convertedRow[child])
                return convertedRow;
            })}
            onSelectionChange={(rows) => handleSelectAttribute(rows)}
            actions={[
                {
                  icon: () => <FileDownloadIcon />,
                  tooltip: 'Download Data',
                  isFreeAction: true,
                  onClick: (event) => {
                    let wb = utils.book_new();
                    let ws = utils.json_to_sheet(props.data);
                    utils.book_append_sheet(wb, ws, props.attribute);
                    props.childObjects.forEach(child => appendSheet(child, wb))
                    writeFile(wb, `${props.attribute}_${props.services[0]}_${props.services[1]}_${Date.now()}.xlsx`)
                    
                }
                }
              ]}
            options={{
                grouping: true,
                search: true,
                // exportMenu: [{
                //     label: 'Export CSV',
                //     exportFunc: (cols, datas) => {
                //         // console.log('columns', cols);
                //         ExportCsv([
                //             ...cols,
                //        ], props.data, `${props.attribute}_${props.services[0]}_${props.services[1]}_${Date.now()}`)
                //     }
                // }],

            }}
            />
                
    )
}

export default AdvancedDifferenceTable;