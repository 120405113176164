import React, { useRef, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';






function TextInput(props) {
    //   const classes = useStyles();
    const textRef = useRef(null);
    let [editMode, setEditMode] = React.useState(false);

    let defaultValue = "";
    // console.log('testing data', props.defaultValue)
    if (props.defaultValue) {
        defaultValue = props.defaultValue;
    }



    useEffect(() => {
        console.log('use effect TextInput', props.editMode, textRef)
        if (props.editMode !== editMode && props.editMode === false) {
            setEditMode(false);
            // console.log('current value', textRef.current.value !== "", textRef.current.value);
            if (textRef.current.value !== "") {
                props.setSearchAttribute(prevState => {

                    console.log('save button clicked', textRef.current, prevState)

                    if (prevState[props.rowName]) {
                        return {
                            ...prevState,
                            [props.rowName]: {
                                ...prevState[props.rowName],
                                [props.theIdentifier]: {
                                    'value': textRef.current.value,
                                    'parentId': props.parentId
                                }
                            }
                        }
                    } else {

                        return {
                            ...prevState,
                            [props.rowName]: {
                                [props.theIdentifier]: {
                                    'value': textRef.current.value,
                                    'parentId': props.parentId
                                }
                            }
                        }
                    }

                })
            }
        } else if (props.editMode !== editMode && props.editMode === true) {
            setEditMode(true);
        }

    }, [props, props.editMode, editMode]);


    return (

        <Grid item xs={10}>
            <TextField
                id="standard-basic"
                inputRef={textRef}
                label="Search Value"
                variant="standard"
                disabled={!props.editMode}
                defaultValue={defaultValue}
            />
            {props.editMode &&
                < IconButton onClick={props.handleDelete} color="error" aria-label="remove">
                    <RemoveIcon />
                </IconButton>
            }
        </Grid>

    );
}

export default TextInput;