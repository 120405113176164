import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useServices } from '../../data/ServiceComponent.js';



export default function SelectLabels(props) {
    const { setSelectedServiceValues, setHasServiceValue } = props;
    const [services, setSelectedServices] = React.useState([]);
    const [servicesValues, setSectedServicesValues] = React.useState([]);

    let availableServices = useServices();

    const handleChange = (event) => {
        setSelectedServices(previousValue => {

            if (event.target.value !== "") {
                console.log('setting service value flag')
                setHasServiceValue(true)
            } else {
                setHasServiceValue(false)
            }

            console.log('testing target value of service selector', event.target.value.map(service => availableServices.data.filter(item => item.name == service)[0].value))
            return event.target.value
        });
        if(event.target.value.length > 0) {
            setSelectedServiceValues(event.target.value.map(service => availableServices.data.filter(item => item.name == service)[0].value));
        } else {
            setSelectedServiceValues([]);
        }
        

    };


    console.log('wtf', availableServices)

    const serviceItems = availableServices ?
        availableServices?.data.map((service, index) => {
            return (
                <MenuItem key={index + '_service'}
                    value={service.name}>{service.name}</MenuItem>
            )
        })
        : '';


    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 220 }}>
                <InputLabel id="simple-select-helper-label">Services</InputLabel>
                <Select
                    labelId="simple-select-helper-label"
                    id="simple-select-helper"
                    value={services}
                    label="Services"
                    multiple={true}
                    onChange={handleChange}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {serviceItems}
                </Select>
                <FormHelperText>Select a services</FormHelperText>
            </FormControl>
        </div>
    );
}
