//new react functional component
import React, { useEffect, useRef } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import TextField from './CustomMuiTextField/CustomMuiTextField';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import Deployments from "../../Deployments/Deployments";
import { convertTextToTerminalGuideText, convertTextToTerminalOutput, validValueForInputType } from '../HelperFunctions/HelperFunctions';

import InterviewHandler from '../InterviewHandler/InterviewHandler';
import { InterviewItem, Submission } from '../HelperFunctions/OPADataTypes';
import Link from '@mui/material/Link';
import OptionsCard from '../OptionsCard/OptionsCard';

const terminalLoadText: JSX.Element = convertTextToTerminalOutput("Welcome to the terminal. Type 'help' for a list of commands.", "yellowTerminal", "green");


type AppProps = {
    setShowTerminalFunction: (showTerminal: boolean) => void,
    showTerminal: boolean

}

const terminalCommands = ['deployments', 'about', 'help', 'projects', 'interview', 'clear', 'exit', 'date', 'number', 'date-time', 'boolean', 'time of day', 'options'];



const Terminal = ({ setShowTerminalFunction, showTerminal }: AppProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [terminalOutput, setTerminalOutput] = React.useState<JSX.Element[]>([terminalLoadText])
    const [issuedCommands, setIssuedCommands] = React.useState<string[]>([]);
    const [terminalSuggestions, setTerminalSuggestions] = React.useState<string[]>([]);
    // selected index of the terminal suggestions
    const [currentCommandLineText, setCurrentCommandLineText] = React.useState<string>('');
    const [suggestionIsActive, setSuggestionIsActive] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [upArrowCoutner, setUpArrowCounter] = React.useState(0);
    const [interviewResponse, setInterviewResponse] = React.useState<InterviewItem>({ loading: false, activeInterview: false, id: '' });
    const [inputCollectionType, setInputCollectionType] = React.useState<string>('text');
    const [inputValidationError, setInputValidationError] = React.useState<boolean>(false);
    const [inputValidationMessage, setInputValidationMessage] = React.useState<JSX.Element[]>([]);
    // mandatory will be set from the interviewResponse
    const [mandatoryQuestion, setMandatoryQuestion] = React.useState<boolean>(false);
    // handle setCollectingInterview For Child Components



    const handleInterviewResponse = (interviewResponseData: InterviewItem) => {
        console.log('handleSetCollectingInterviewData called, collectingInterviewData: ', interviewResponseData);
        setInterviewResponse(interviewResponseData);
    }

    // when the interviewResponse changes, if there is an activeInterview underway
    // apply validation to the textField based on the input type
    useEffect(() => {
        console.log('setting type of inputCollectionType to ', interviewResponse?.type, ' based on interviewResponse: ', interviewResponse)
        // always a safe default

        if (interviewResponse?.activeInterview) {
            if (interviewResponse?.type === 'number') {
                setInputCollectionType('number');
            } else if (interviewResponse?.type === 'date') {
                setInputCollectionType('date');
            } else if (interviewResponse?.type === 'datetime') {
                setInputCollectionType('datetime-local');
            } else if (interviewResponse?.type === 'time of day') {
                setInputCollectionType('time');
            } else if (interviewResponse?.type === 'boolean') {
                setInputCollectionType('checkbox');
            } else {
                setInputCollectionType('text');
            }
        } else {
            setInputCollectionType('text');
        }
        console.log('checking value on the way out', inputCollectionType)
    }, [interviewResponse])



    useEffect(() => {

        setInputValidationMessage([convertTextToTerminalGuideText("Please enter a "+inputCollectionType, 1)]);
        if (inputCollectionType === 'number') {
          
        } else if (inputCollectionType === 'date') {

        } else if (inputCollectionType === 'date-time') {

        } else if (inputCollectionType === 'time') {

        } else if (inputCollectionType === 'boolean') {

        } 
        else {
            setInputValidationMessage([]);
        }

    }, [inputCollectionType])

    // set suggestions when the currentCommandLineText changes
    useEffect(() => {
        // console.log('testing use effect values, currentCommandLineText: ', currentCommandLineText, ' terminalSuggestions: ', terminalSuggestions, ' selectedIndex: ', selectedIndex, ' suggestionIsActive: ', suggestionIsActive)
        let newTerminalSuggestions = terminalCommands.filter(el => {
            return el.toLowerCase().includes(currentCommandLineText.toLowerCase())
        }).sort((a, b) => {
            // sort the commands based on the index of the currentCommandLineText
            return a.toLowerCase().indexOf(currentCommandLineText.toLowerCase()) - b.toLowerCase().indexOf(currentCommandLineText.toLowerCase())
        })

        setTerminalSuggestions(newTerminalSuggestions)
        setSelectedIndex(0);
        if (currentCommandLineText.length > 0 && newTerminalSuggestions.length > 0 && !suggestionIsActive) {
            setSuggestionIsActive(true);
        } else if (suggestionIsActive && (currentCommandLineText.length === 0 || newTerminalSuggestions.length === 0)) {
            setSuggestionIsActive(false);
        }
    }, [currentCommandLineText]);


    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setShowTerminalFunction(open);
            };


    const handleKeyDown = (event: React.KeyboardEvent) => {
        // if tab is pressed, apply the selected list item to the CommandLineText
        if (event.key === 'Tab' && suggestionIsActive) {
            event.preventDefault();
            if (suggestionIsActive) {
                let selectedSuggestion = terminalSuggestions[selectedIndex];
                applyTextToCommandLineText(selectedSuggestion)
            }
        } else if (event.key === 'ArrowUp' && suggestionIsActive) {
            event.preventDefault();
            let currentCounter = Math.min(issuedCommands.length, upArrowCoutner + 1)
            setUpArrowCounter(currentCounter);
            if (!suggestionIsActive && issuedCommands.length > 0) {
                let previousCommand = issuedCommands[issuedCommands.length - currentCounter]
                applyTextToCommandLineText(previousCommand);
            }
        } else if (event.key === 'ArrowDown' && suggestionIsActive) {
            event.preventDefault();
            let currentCounter = Math.max(0, upArrowCoutner - 1)
            setUpArrowCounter(currentCounter);
            if (!suggestionIsActive && issuedCommands.length > 0 && currentCounter > 0) {
                let previousCommand = issuedCommands[issuedCommands.length - currentCounter]
                applyTextToCommandLineText(previousCommand);
            } else if (!suggestionIsActive && currentCounter === 0) {
                applyTextToCommandLineText('');
            }
        }
    }

    const handleKeyUp = (event: React.KeyboardEvent) => {

        //  console.log('testing key up ', event.key, currentCommandLineText)

        if (event.key === 'ArrowUp') {
            if (suggestionIsActive) {
                setSelectedIndex(Math.max(selectedIndex - 1, 0))
            }
        }
        else if (event.key === 'ArrowDown') {
            setSelectedIndex(Math.min(selectedIndex + 1, terminalSuggestions.length - 1))
        }
        else if (event.key === 'Enter') {

            // validation for things like dates and numbers
            // someday we may want to write a function that will validate dynamically based on the input item
            if (inputCollectionType !== 'text') {
                if (validValueForInputType(currentCommandLineText, inputCollectionType) === false) {
                    setInputValidationError(true);
                    return;
                }
                setInputValidationError(false);

            }
            // clear the terminalInput and create a new div with the terminalInput
            // assign unique key to each div
            // applyCommandLineTextToTerminal(currentCommandLineText);
            registerIssuedCommand(currentCommandLineText);

            // process issued command
            if (interviewResponse?.activeInterview) {
                setInputCollectionType('text');
                let dateValue = new Date(currentCommandLineText)
                let formattedDateValue = `${dateValue.getFullYear() - 1}-${dateValue.getMonth()}-${dateValue.getDate()}`
                let submission: Submission = { "id": interviewResponse!.id, "value": formattedDateValue, "operation": "submit" };
                setTerminalOutput(oldTermValues => [...oldTermValues, <InterviewHandler key={"interviewHandler_" + terminalOutput.length} assignedKey={"interviewHandler_" + terminalOutput.length} serviceName="TestInterview" investigateUrl={interviewResponse.investigateUrl} setCollectingInterviewData={handleInterviewResponse} submission={submission} />])
            }
            else if (currentCommandLineText.toLowerCase() === 'clear') {
                setTerminalOutput([]);
                setCurrentCommandLineText('');
            }
            else if (currentCommandLineText.toLowerCase() === 'date') {
                setInputCollectionType('date');
                setCurrentCommandLineText('');
            }
            else if (currentCommandLineText.toLowerCase() === 'date-time') {
                setInputCollectionType('datetime-local');
                setCurrentCommandLineText('');
            }
            else if (currentCommandLineText.toLowerCase() === 'time of day') {
                setInputCollectionType('time');
            }
            else if (currentCommandLineText.toLowerCase() === 'number') {
                setInputCollectionType('number');
                setCurrentCommandLineText('');
            }
            else if (currentCommandLineText.toLowerCase() === 'options') {
                // simulate a response providing some options
                setTerminalOutput(oldTermValues => [...oldTermValues, <OptionsCard key={"optionsCard_" + terminalOutput.length} assignedKey={"optionsCard_" + terminalOutput.length} options={['testing', 'options']} handleOptionClick={(option)=>{console.log('your mom', option)}} collectionText="things"/>])
                setCurrentCommandLineText('');
                setInputValidationMessage([convertTextToTerminalGuideText("Please select an option from the list above.", 1)]);
            }
            else if (currentCommandLineText.toLowerCase() === 'help') {
                // create the element which will be displayed in the terminal
                const basicHelpText = "Start typing to see a filtered list of options matching your input. Press tab to select the highlighted option. Press enter to execute the command. Press up and down to navigate through the command history."
                const newTerminalOutput = convertTextToTerminalOutput(basicHelpText, "help_text_Display" + terminalOutput.length + 1, "green");
                const clearAndExitText = "clear: clears the terminal. exit: exits the terminal."
                const newTerminalOutput2 = convertTextToTerminalOutput(clearAndExitText, "help_text_Display" + terminalOutput.length + 2, "green");
                const newTerminalOutput3 = convertTextToTerminalOutput("Available commands: " + terminalCommands.join(', '), "help_text_Display" + terminalOutput.length + 3, "green");
                const ionlyRespond = "I only respond to the available commands listed.  I'm not a chatbot, I'm a terminal."
                const newTerminalOutput4 = convertTextToTerminalOutput(ionlyRespond, "help_text_Display" + terminalOutput.length + 4, "green");
                setTerminalOutput(oldTermValues => [...oldTermValues, newTerminalOutput, newTerminalOutput2, newTerminalOutput3, newTerminalOutput4])
                setCurrentCommandLineText('');
            }
            else if (currentCommandLineText.toLowerCase() === 'exit') {
                setShowTerminalFunction(false);
            } else if (currentCommandLineText.toLowerCase() === 'deployments') {
                // create the element which will be displayed in the terminal
                setTerminalOutput(oldTermValues => [...oldTermValues, <Deployments key={"terminal_Deployments_" + terminalOutput.length} />])
                setCurrentCommandLineText('');
            } else if (currentCommandLineText.toLowerCase() === 'interview') {
                // initiate the interview
                // attempting to initial the interview
                setTerminalOutput(oldTermValues => [...oldTermValues, <InterviewHandler key="testkey" assignedKey="testkey" serviceName="TestInterview" setCollectingInterviewData={handleInterviewResponse} />])
                setCurrentCommandLineText('');
            } else {
                let unknownTermValue = (convertTextToTerminalOutput("Unknown Command", "unknown_command" + terminalOutput.length + 1, "green"))
                setTerminalOutput(oldTermValues => [...oldTermValues, unknownTermValue])
            }

        } else {
            // update the currentCommandLineText
            //  console.log('current command line text else ', inputRef.current!.value)

            // date and date-time validators seem to have an issue when setting the currentCommandLineText from inputRef.current!.value
            if ((inputCollectionType !== 'date' && inputCollectionType !== 'datetime-local' && inputCollectionType !== 'time') || inputRef.current!.value.length > 1) {
                console.log('setting current command line text ', inputRef.current!.value)
                setCurrentCommandLineText(inputRef.current!.value);
            }
        }


    }


    const registerIssuedCommand = (issuedCommand: string) => {
        setSuggestionIsActive(false);
        console.log('testing values ', inputRef.current!.value)
        inputRef.current!.value = '';
        setIssuedCommands([...issuedCommands, issuedCommand]);
        applyCommandLineTextToTerminal(issuedCommand);
    }


    const applyCommandLineTextToTerminal = (issuedCommand: string) => {
        // create the element which will be displayed in the terminal
        const newTerminalOutput = convertTextToTerminalOutput(issuedCommand, terminalOutput.length);
        setTerminalOutput(oldTermValues => [...oldTermValues, newTerminalOutput])
    }

    const applyTextToCommandLineText = (text: string) => {
        console.log('im doing something! ', text)
        inputRef.current!.value = text;
        setCurrentCommandLineText(text);
    }


    function handleListItemClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number): void {
        console.log("handleListItemClick", index)
        setSelectedIndex(index);
        const lineItemValue = terminalSuggestions[index]
        applyTextToCommandLineText(lineItemValue)
    }

    return (
        <div className="terminal">
            <SwipeableDrawer
                anchor='bottom'
                open={showTerminal}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}                >
                <Box
                    key='terminal_box'
                    sx={{
                        maxWidth: '100%',
                        padding: 2,
                    }}
                >
                    <Typography variant='h6'>Sagasys Terminal</Typography>

                    {suggestionIsActive ?
                        <List
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Suggested Commands:
                                </ListSubheader>
                            }
                        >
                            {terminalSuggestions.map((command, index) => (
                                <ListItemButton
                                    key={index + '_button'}
                                    dense
                                    selected={selectedIndex === index}
                                    onClick={(event) => handleListItemClick(event, index)}>
                                    <ListItemText key={index + '_text'} secondary={command} />
                                </ListItemButton>
                            ))}
                        </List>
                        : null
                    }


                    {terminalOutput}



                    <TextField
                        key={"terminal_input" + inputCollectionType + inputValidationError}
                        type={inputCollectionType}
                        autoComplete="off"
                        inputRef={inputRef}
                        variant="standard"
                        handleKeyDown={handleKeyDown}
                        handleKeyUp={handleKeyUp}
                        error={inputValidationError}
                    />
                    <Box key='validationbox'>
                        {inputValidationMessage}

                        {/* at some point we will want to add a exit interview option maybe? */}
                        {!mandatoryQuestion && inputCollectionType !== 'text' ? 
                        <Typography sx={{ padding: '1em'}} variant="caption" onClick={()=>{
                            registerIssuedCommand('Skip Question');
                            setInputCollectionType('text');
                        }}><Link href="#">Skip Question</Link></Typography> 
                        : ''}
                    </Box>

                </Box>
            </SwipeableDrawer>
        </div>
    )


}


export default Terminal;

