import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';


import Button from '@mui/material/Button';

import AdvancedDialog from '../Compare/AdvancedDialog'

const drawerWidth = 500;



const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export default function PersistentDrawerRight(props) {

    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogType, setDialogType] = React.useState('');

    const theme = useTheme();

    const handleDrawerOpen = () => {
        props.setOpen(true);
    };

    const handleDrawerClose = () => {
        props.setOpen(false);
    };

    const toggleDrawer = () => {
        props.open ? handleDrawerClose() : handleDrawerOpen();
    }

    const handleListItemButton = (type) => {
        console.log('testing list item button', type)
        setDialogType(type)
        setOpenDialog(true)
    }

    return (
        <div>
            <Button onClick={toggleDrawer}>Advanced</Button>

            <Box sx={{ display: 'flex' }}>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                        },
                    }}
                    variant="persistent"
                    anchor="right"
                    open={props.open}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                        <Typography align='center' variant='h6'>Advanced Options</Typography>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        <ListItem key={'attribute'} disablePadding>
                            <ListItemButton onClick={() => handleListItemButton('attribute')}>
                                <ListItemIcon>
                                    <PlaylistRemoveIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Change Compare Attributes'} />
                                {props.outcomes.length > 0 ? 
                                <Typography align='right' variant='h6' color={theme.palette.info.main}>{props.outcomes.length}</Typography> :
                                    ''}
                            </ListItemButton>
                        </ListItem>



                    </List>
                    <Divider />
                    <ListItem key={'extractData'} disablePadding>
                            <ListItemButton onClick={() => handleListItemButton('extractData')}>
                                <ListItemIcon>
                                    <FileDownloadIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Extract Data Options'} />
                            </ListItemButton>
                        </ListItem>
                </Drawer>
            </Box>
            <AdvancedDialog
                open={openDialog}
                close={() => { setOpenDialog(false) }}
                type={dialogType}
                services={props.services}
                outcomes={props.outcomes}
                setOutcomes={props.setOutcomes}
                differences={props.differences}
            />

        </div>
    );
}
