import { Button, Grid, Paper, TextField } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import React, { FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../RequireAuth/RequireAuth';

export function LoginScreen() {
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    let auth = useAuth();
    const navigate = useNavigate();
    const handleSubmit = (event: FormEvent) => {
        // Prevent page reload
        setErrorMessage('');
        event.preventDefault();
        setLoading(true);
        var { username: { value: username }, password: { value: password } } = document.forms[0];


        auth.signin(username, password, (error?: string) => {
            if(error) {
                setErrorMessage(error);
                setLoading(false);
            } else {
                window.gtag("event", "login_sucess", {
                    method: "Login Screen", event_callback: () => {
                        document.cookie = `username=${username}`;
                        navigate('/')
                        setLoading(false);
                    }
                });
            }

        })

    };
    const btnstyle = { margin: '8px 0' }

    return (
        <Grid>
            <Paper elevation={10} style={{ padding: 20, width: 280, margin: "20px auto", marginTop: 400 }}>
                <Grid>
                    <h2>Sign In</h2>
                </Grid>
                <form onSubmit={handleSubmit}>
                    <TextField name='username' style={{ marginBottom: 20 }} label='Username' placeholder='Enter username' variant="outlined" fullWidth required />
                    <TextField name='password' style={{ marginBottom: 20 }} label='Password' placeholder='Enter password' type='password' variant="outlined" fullWidth required />
                    {loading? <CircularProgress /> : <Button type='submit' color='primary' variant="contained" style={btnstyle} fullWidth>Sign in</Button>}
                    <Typography variant="body2" gutterBottom color="red">{errorMessage}</Typography>
                    
                </form>
            </Paper>
        </Grid>
    )
};