import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MaterialTable from '@material-table/core';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
// import Tree from '../Tree/Tree';
import DecisionReportHelper from './DecisionReportHelper';
// import { useFetchJson } from '../../data/ServiceComponent'
// // import { textTransform } from '@mui/system';


function CaseDetails(props) {
    //   const classes = useStyles();
    // const [filterApplied, setFilterApplied] = React.useState(false);
    // let originalRequest = useFetchJson(props.identifier, props.environment);


    const columns = [
        // { title: 'Id', field: 'id' },
        // { title: 'parentId', field: 'parentId' },
        // { title: 'Path', field: 'path' },
        {
            title: 'Attribute', field: 'Attribute'
            // , render: (rowData) => rowData.Attribute
            // ,
            // customFilterAndSearch: (term, rowData) => {
            //     console.log('testing filter', rowData, rowData.tableData)
            //     if (term.length > 0)
            //         setFilterApplied(true)
            //     else
            //         setFilterApplied(false)
            //     return inspectAttributeAndChild(term, rowData);

            //     // (rowData.Attribute.toLowerCase().indexOf(term.toLowerCase()) > -1)
            // }

            // , customFilterAndSearch: (term, rowData) => {

            //     if(rowData.tableData.childRows != null && rowData.tableData.childRows.length > 0){
            //         for(let child in rowData.tableData.childRows){
            //             if(String(rowData.tableData.childRows[child].Attribute).toLowerCase().indexOf(term.toLowerCase()) > -1) {
            //                 console.log('nested Child!', rowData.tableData.childRows[child].Attribute, term, rowData);
            //                 return false
            //             }
            //         }
            //         return false;
            //     } else if(rowData.parentId){
            //         console.log('has parent ID!', rowData)
            //         return false;
            //     } else if(String(rowData.Attribute).toLowerCase().indexOf(term.toLowerCase()) == -1){
            //         console.log('this attribute', rowData.Attribute, term, rowData);
            //         return true
            //     } else {
            //         return false;
            //     }



            // } 
        },
        { title: 'Value', field: 'Value' },
        { title: 'Inferred', field: 'Inferred' }
    ];


    const inspectAttributeAndChild = (term, rowData) => {
        if (rowData.Attribute.toLowerCase().indexOf(term.toLowerCase()) > -1) {
            console.log('!!!inspectAttributeAndChild!!!!!', rowData.Attribute.toLowerCase().indexOf(term.toLowerCase()))
            return true;
        } else if (rowData.tableData.childRows) {
            // test children
            rowData.tableData.childRows.forEach(element => {
                console.log('testing child rowdata', element)
                if (inspectAttributeAndChild(term, element)) {
                    return true;
                }
            })
        }
        console.log('completed child test and returning value')
        return false;

    }


    const rowDataDrill = (term, rowData) => {
        if(magicTextCheckCheck(term, rowData.Attribute)){
            return false;
        } else if(rowData.tableData.childRows && rowData.tableData.childRows.length > 0) {
            const mapOfMatches = rowData.tableData.childRows.map(child => rowDataDrill(term, child));
            console.log('testing values of map', mapOfMatches)
            if(mapOfMatches.find(row => row === true)){
                console.log('found match')
                return false
            } 
        } else {
            return true;
        }
    }

    const magicTextCheckCheck = (term, attribute) => {
        const regex = new RegExp(term.toUpperCase())
        return regex.test(attribute.toUpperCase())
    }


    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <MaterialTable
                        columns={columns}
                        title='Attribute Explorer'
                        data={props.json}
                        parentChildData={!props.filteringEnabled ? (row, rows) => {
                            // console.log('test the parentChildThing', row)  
                            return rows.find(a => {
                                // console.log('test the parentChildThing', a)    
                                return (a.id === row.parentId)
                            })
                        } : (row, rows) => {
                            return;
                        }
                        }

                        detailPanel={

                            [{
                                icon: CompareArrowsIcon,
                                tooltip: 'view decision report',
                                render: rowData => {
                                    // console.log('testing rowdata', rowData.rowData.IsParent)
                                    return (
                                        <DecisionReportHelper
                                            service={props.service}
                                            attribute={rowData.rowData.Attribute}
                                            completeData={rowData.rowData}
                                            value={rowData.rowData.Value}
                                            identifier={props.identifier}
                                            environment={props.environment}
                                            program={props.program}
                                        />

                                    )
                                }
                            }]

                        }
                        options={{
                            search: props.filteringEnabled,
                            filtering: props.filteringEnabled,
                            rowStyle: rowData => {
                                // console.log('testing row data', rowData)
                                return  ({backgroundColor: rowData.tableData.isTreeExpanded ? '#F0FFFF' : rowData.IsParent ? '#EEE' : '#FFF' })
                              }
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default CaseDetails;