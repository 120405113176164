import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';

import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import TextInput from './TextInput';





function InputContainer(props) {

    const [editMode, setEditMode] = React.useState(false);
    let [textInputs, setTextInputs] = React.useState([]);
    let [simpleCounter, setSimpleCounter] = React.useState(1);


    let handleEditButton = (boolean) => {

        // when saving - first clear current state
        if (!boolean) {
            clearStateForRow();
        }

        // if you enter edit mode and there are zero text inputs, add one.
        if (boolean && textInputs.length === 0) {
            setTextInputs([{ theIdentifier: simpleCounter }]);
            setSimpleCounter(simpleCounter + 1)
        }

        // after state is cleared above, this prop change will trigger the input elements to save themselves state
        setEditMode(boolean)
    }


    let clearStateForRow = () => {
        props.setSearchAttribute(prevState => {

            console.log('clearing existing values', prevState)
            if (prevState[props.rowName]) {
                return delete prevState[props.rowName]
            } else {
                return { ...prevState }
            }

        })
    }


    let handleAddButton = () => {

        console.log('testing add button')
        setTextInputs(prevState => [...prevState, { theIdentifier: simpleCounter }])
        setSimpleCounter(simpleCounter + 1)
        // props.setSearchAttribute(prevState => {

        //     // console.log('test the list', allReferences[1])
        //     let updatedValue = prevState[props.rowName].push('')

        //     return {
        //         ...prevState,
        //         [props.rowName]: updatedValue
        //     }
        // })
    }


    let handleDeleteButton = (key) => {
        console.log('handle Delete Button', key, textInputs.length)
        if (textInputs.length === 1) {
            clearStateForRow();
            setTextInputs([]);

        } else {
            setTextInputs(prevState => {
                console.log('previous state', prevState)
                let modifiedList = [...prevState];
                let nextModifiedList = modifiedList.filter((item, index) => {
                    console.log(item, key, item.parentId !== key)
                    if (item.theIdentifier !== key) {
                        return true
                    } else {
                        return false
                    }
                })
                console.log('setting this modified list', nextModifiedList);
                return (nextModifiedList)
            })
        }


    }


    useEffect(() => {

        console.log('use effect fired!', props.currentSearchAttributes, props.rowName, props.currentSearchAttributes[props.rowName]);
        // console.log('testing data', props.currentSearchAttributes, props.rowName, props.currentSearchAttributes[props.rowName])
        if (textInputs.length === 0) {

            console.log('inside the reference check')
            if (props.currentSearchAttributes[props.rowName]) {


                for (let item in props.currentSearchAttributes[props.rowName]) {
                    console.log('we made it into the if statement', item, props.currentSearchAttributes[props.rowName][item])
                    setTextInputs(prevState => {

                        return ([...prevState, { theIdentifier: item }])
                    });

                    if (item > simpleCounter) {
                        setSimpleCounter(+item + 1)
                    }
                }

            }
            console.log('all text inputs', textInputs, simpleCounter);
        } else if (!props.currentSearchAttributes[props.rowName] && !editMode) {
            // clear any junk input containers
            setTextInputs([]);
        }



    }, [props.currentSearchAttributes, props.rowName, editMode, textInputs]);

    let displayIcon = editMode ? <IconButton  color="success" onClick={() => handleEditButton(false)}> <CheckIcon /> </IconButton> : <IconButton color="primary" onClick={() => handleEditButton(true)}> <EditIcon /> </IconButton>;


    return (



        <Grid container spacing={.5} justifyContent="center" alignItems="center">
            <Grid item xs={2}>
                {displayIcon}
            </Grid>
            <Grid item xs={10}>
                <Grid container spacing={.5} justifyContent="center" alignItems="center">
                    {textInputs.length > 0 &&
                        textInputs.map((input) => <TextInput defaultValue={props.currentSearchAttributes[props.rowName] ? props.currentSearchAttributes[props.rowName][input.theIdentifier] ? props.currentSearchAttributes[props.rowName][input.theIdentifier].value : '' : ''} editMode={editMode} key={input.theIdentifier} theIdentifier={input.theIdentifier} rowName={props.rowName} setSearchAttribute={props.setSearchAttribute} handleDelete={() => handleDeleteButton(input.theIdentifier)} parentId={props.parentId} />)

                    }
                    {editMode &&
                        <Grid container justifyContent="center">
                            <IconButton color="primary" onClick={() => handleAddButton()}>
                                <AddIcon />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>

    );
}

export default InputContainer;