import React from 'react';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import JSONViewer from './Components/JSONViewer';
import Service from '../../SelectServices/Service';
import rulesData from "../../data/rulebases.json";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import EnvironmentSelector from '../EnvironmentSelector/EnvironmentSelector';
import SizeSelector from '../SizeSelector/SizeSelector';
import RulebaseSelector from '../RulebaseSelector/RulebaseSelector'

import DataModel from '../DataModel/DataModel';
import CaseSearch from '../CaseSearch/CaseSearch';
import CaseCollector from '../CaseCollector'



const axios = require('axios');
// const axios = require('axios');
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

const theme = createTheme();

function Search() {

  const [serviceName, setServiceName] = React.useState("");
  const [rulebase, setRulebase] = React.useState("");
  const [dataModel, setDataModel] = React.useState([]);
  const [searchAttributes, setSearchAttributes] = React.useState({});
  const [caseData, setCaseData] = React.useState({
    cases: []
  });
  const [environment, setEnvronment] = React.useState("sit2");
  const [caseLimit, setCaseLimit] = React.useState(200);
  const [caseSkipping, setCaseSkipping] = React.useState(0);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [selectCases, setSelectCases] = React.useState(null);

  const [filteringEnabled, setFilteringEnabled] = React.useState(false);
  const [searchSpecificRulebase, setSearchSpecificRulebase] = React.useState(false);

  const changeServiceName = (newName) => {
    if (newName !== null) {
      setServiceName(newName);
      if (newName !== null) {
        for (let ruleVal in rulesData[0].rulebases) {
          // console.log("ruleVal", rulesData[0].rulebases[ruleVal], value.key);
          if (newName.includes(rulesData[0].rulebases[ruleVal])) {
            // console.log("ruleVal Inside", rulesData[0].rulebases[ruleVal]);
            setRulebase(rulesData[0].rulebases[ruleVal]);
            // set the size of the query based on rulebase name.
            break;
          }
        }
      }
    } else {
      setRulebase("");
      setServiceName("");
    }
  }

  let attributes = [];
  let counter = 1;


  const formatDataModel = (dJson, parentId = 0, callback) => {
    // console.log('testing rolling state variable', dataModel);
    let parentEntities = [];
    for (let key in dJson) {
      // console.log('testing values', dJson[key], counter, parentId);

      let newNode = {}
      if (parentId === 0) {
        newNode = {
          "id": counter,
          "Name": key,
          "Type": dJson[key].type,
          "Inferred": dJson[key].inferred
        }
      }
      else {
        newNode = {
          "id": counter,
          "Name": key,
          "Type": dJson[key].type,
          "Inferred": dJson[key].inferred,
          "parentId": parentId
        }
      }

      attributes = attributes.concat([newNode]);




      // console.log('check for properties', dJson[key]['properties']);
      if (dJson[key]['properties']) {
        // console.log('we made it inside the check!' )
        parentEntities.push([key, counter]);
      }
      counter++;
    }

    if (parentEntities.length > 0) {
      for (let parentNodeList in parentEntities) {
        // console.log('testing this child node', parentNodeList, dJson[parentEntities[parentNodeList][0]]['properties']);
        formatDataModel(dJson[parentEntities[parentNodeList][0]]['properties'], parentEntities[parentNodeList][1], function () {
          // console.log("do nothing")
        });
      }
    }

    callback();

  }

  const handleDataModelButton = () => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACK_END_URL}/datamodel`,
      headers: { 'Content-Type': 'application/json' },
      data: {
        serviceName: serviceName,
      }

    }).then(res => {
      // console.log('stuff', res.data.properties);

      formatDataModel(res.data.properties, 0, function () {
        // console.log(attributes);
        setDataModel(attributes);
      });

    })
  }

  const handleSearchButton = (env, cases, limit, skipping) => {

    if (env === null) {
      env = environment
    }

    if (cases === null) {
      cases = selectCases
    }

    if (limit === null) {
      limit = caseLimit
    }

    console.log(env, limit, skipping);
    setIsProcessing(true);

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACK_END_URL}/search`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        environment: env,
        cases: cases,
        caseLimit: limit,
        starting: skipping,
        rulebase: searchSpecificRulebase ? rulebase : ""

      },
      cancelToken: source.token

    }).then(res => {
      console.log('response from search', res.data);
      setCaseData(res.data);
      setIsProcessing(false);
    }).catch(thrown => {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message)
      }
    })

  }

  const handleCancelButton = () => {
    source = CancelToken.source();
    setIsProcessing(false);
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }} style={{ padding: '1em' }}>

        <Typography variant="h4" component="div" gutterBottom>EDBC Search {rulebase !== "" ? `for program ${rulebase}` : ""} </Typography>
        <Grid container spacing={1} style={{ marginLeft: '1em', marginTop: '2em', display: 'none' }} >
          <Grid item xs={4} >
            <Service
              setServiceName={changeServiceName}
            />
          </Grid>
          <Grid item xs={2}  >
            <Button
              variant="outlined"
              onClick={handleDataModelButton}
              disabled={serviceName === ""} >
              Show Data Model
            </Button>
          </Grid>
          <Grid item xs={12}>
            {dataModel.length > 0 ?
              <DataModel
                data={dataModel}
                setSearchAttribute={setSearchAttributes}
                currentSearchAttributes={searchAttributes} /> :
              ""
            }
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ margin: '2em', marginLeft: '0em' }}>
          <Grid item xs={1}>
            <SizeSelector disabled={selectCases !== null} caseLimitSetter={setCaseLimit} text={'Cases'} defaultStart={caseLimit} />
          </Grid>
          <Grid item xs={1}>
            <SizeSelector disabled={selectCases !== null} caseLimitSetter={setCaseSkipping} beforeText={'Skipping the First'} defaultStart={caseSkipping} />
          </Grid>
          <Grid item xs={1}>
            <Typography variant="button" gutterBottom style={{ alignItems: 'center', display: 'flex', padding: '6px 8px', marginBottom: '0px', paddingRight: '2em' }}>Or </Typography>
          </Grid>
          <Grid item xs={2} style={{ padding: '0' }}>
            <CaseCollector
              setCases={setSelectCases}
              onEnter={handleSearchButton}
              submitDisabled={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginLeft: '0em', marginTop: '1em' }} >
          <Grid item xs={3}>
            <Switch aria-label='Search for specific program' onChange={() => setSearchSpecificRulebase(prevValue => !prevValue)} />
            <Typography variant='string'>Search For Specific Rulebase</Typography>
          </Grid>
          <Grid item xs={4} style={{ display: searchSpecificRulebase ? 'block' : 'none', padding: '0' }}>
            <RulebaseSelector setRulebaseName={setRulebase} />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginLeft: '0em', marginTop: '.3em' }}>
          <Grid item>
            <EnvironmentSelector environmentSetter={setEnvronment} />
          </Grid>

        </Grid>

        <Grid container spacing={2} style={{ margin: '1em 0 0 0' }} >
          <Grid item xs={1}  >
            <Button
              variant="contained"
              onClick={() => handleSearchButton(environment, selectCases, caseLimit, caseSkipping)}
              tooltip='click to Search'
              disabled={isProcessing}
            >
              Search
            </Button>
          </Grid>
          <Grid item xs={1}>

            <Button
              sx={{ marginLeft: '6px' }}
              variant="outlined" color="error"
              onClick={handleCancelButton}
              tooltip='click to cancel search'
              disabled={!isProcessing}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={10}>
            {caseData.cases?.length > 0 ?
              <Grid container justifyContent="flex-end" >
                <Switch aria-label='Toggle Filter Search' onChange={() => setFilteringEnabled(prevValue => !prevValue)} />
                <Typography style={{ padding: '6px 8px', marginBottom: '0px', paddingRight: '2em' }} variant='string'>Attribute Filtering and Search</Typography>
              </Grid>
              :
              ""
            }
          </Grid>
          <Grid item xs={12}>
            {caseData.cases?.length > 0 ?
              <CaseSearch
                data={caseData}
                searchAttributes={searchAttributes}
                ENV={environment}
                service={serviceName}
                filteringEnabled={filteringEnabled}
              /> :
              ""
            }
          </Grid>
        </Grid>



      </Box>




    </ThemeProvider>

  );
}

export default Search;
