import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
// web.cjs is required for IE11 support
import { useSpring, animated } from '@react-spring/web';
const axios = require('axios');

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (


    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool
};

const StyledTreeItem = styled((props) => (
  <TreeItem nodeId={props.nodeId} label={
    <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
      {/* <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} /> */}
      <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
        {props.labelText}
      </Typography>
      <Typography variant="caption" color="inherit" style={{ marginRight: '2em' }} >
        Nodes# {props.labelInfo}
      </Typography>
    </Box>
  }>{props.children}</TreeItem>
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));


let theMapOfTreeItems = [];

const flattenDescision = (descisionReport) => {
  if (descisionReport['@id']) {
    if (!theMapOfTreeItems.some(item => item.id == descisionReport['@id'])) {
      theMapOfTreeItems.push({ id: descisionReport['@id'], attrId: descisionReport['attributeId'], nodeId: descisionReport['@id'], labelText: descisionReport.text, labelInfo: descisionReport.children?.length });
    }
  }

  if (descisionReport.children) {
    descisionReport.children.map(nextNode => {
      flattenDescision(nextNode)
    })
  }

}

const reportIdFinder = (node, theID) => {
  if (node['@id'] === theID) {
    return node;
  } else if (node.children) {
    node.children.map(child => {
      return reportIdFinder(child, theID)
    })
  }
}


const createStyledTreeItem = (node) => {

}

let counter = 0;

const childrenParser = (children) => {

  // console.log('children called', children)


  let parseValues = children.map((itm, index) => {

    const idThing = ++counter;

    if (itm.children) {
      // console.log('testing this child value', counter, itm)

      return (
        <StyledTreeItem key={`${itm.attributeId}_${idThing}`} nodeId={`${itm.attributeId}_${idThing}`} labelText={itm.text} labelInfo={itm.children.length}>
          {childrenParser(itm.children)}
        </StyledTreeItem>
      )
    }
    else {

      if (itm['@alreadyProvenId'] && theMapOfTreeItems.some(item => item.id == itm['@alreadyProvenId'])) {
        const existingTreeItem = theMapOfTreeItems.reduce((previousValue, item) => {
          if (item.id === itm['@alreadyProvenId']) {
            // console.log('WERE IN THE SCARY PLACE', item, previousValue)
            return [...previousValue, <StyledTreeItem key={`${item.attrId}_${idThing}`} nodeId={`${item.attrId}_${idThing}`} labelText={item.labelText} labelInfo={item.labelInfo} />]
          } else {
            return previousValue
          }

        }, [])
        // console.log('found these existing items', existingTreeItem)
        return existingTreeItem[0];
      } else {
        // console.log('in the land of the else', itm)
        return (<StyledTreeItem key={`${itm.attributeId}_${idThing}`} nodeId={`${itm.attributeId}_${idThing}`} labelText={itm.text} labelInfo="0" />)
      }
    }



    // if(itm.children) childrenParser(itm.children) 
  });
  // console.log('children', children, <StyledTreeItem nodeId='dn:1' label='it is not true that test test test' />, ...parseValues);
  // // let parseValues = <StyledTreeItem nodeId='dn:1' label='it is not true that test test test' />


  return parseValues;

}

async function decisionParser(path, res, location, attribute) {
  console.log('starting parser', path, res, location, attribute);
  if (path?.length > location + 1) {
    return decisionParser(path, res[path[location]], ++location, attribute)
  }
  else {
    console.log('testing response mapper', res['@decisionReports'][path[location]])
    return (res['@decisionReports'][path[location]])
  }
}

export default function Tree(props) {


  const [response, setResponse] = useState(null);
  const [errors, setErrors] = useState(null);
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {

    } else {

      try {
        isMounted.current = true;
        //  console.log('Requests', props.json_request)
        //    console.log('titles', props.titles)
        console.log("props", props.value)

        if (props.value !== null || props.value !== 'No Match') {
          axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACK_END_URL}/decision`,
            headers: { 'Content-Type': 'application/json' },
            data: {
              request: props.json_request,
              service: props.title,
              filter: true
            }

          }).then(res => {
            console.log('testing response structure', res, props.completeData.path, res.data[0]);

            // catching errors from the hub
            if (res.data.error) {
              setErrors(true);
              setResponse(res.data.error?.detail)
            } else {

              // console.log('complete data', props.completeData);
              //  console.log(decisionParser(props.completeData.path, res.data[0][0], 1));

              if (!res.data[0]['@errors']) {
                decisionParser(props.completeData.path, res.data[0], 1, props.attribute).then(responseDesicion => {
                  console.log('set the decision report already', responseDesicion);
                  flattenDescision(responseDesicion);
                  setResponse(responseDesicion);
                  setErrors(false);
                })

              }
              else {
                console.log('caught an error in the decision report', res.data[0]['@errors'][0]?.detail)
                setResponse(res.data[0]['@errors'][0].detail)
                setErrors(true);
              }
            }

          })
        } else {
          // value is null, should not request of decision report of null
          setResponse("No Decision Report");
          setErrors(true);

        }
      } catch (error) {
        console.log('null value in compare');
        console.log(error);
      }

    }

  });

  console.log('is there a response?', response, "errors", errors);
  if (response && errors === false) {

    return (


      <TreeView
        aria-label="customized"
        defaultExpanded={['1']}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
        sx={{ flexGrow: 1, overflowY: 'auto' }}
      >

        <StyledTreeItem key={response['@id']} nodeId={response['@id']} labelText={response.text} labelInfo={response.children?.length} >




          {response.children ? childrenParser(response.children) : ''}


        </StyledTreeItem>


        {/* <StyledTreeItem nodeId="1" labelText="Main">
          <StyledTreeItem nodeId="2" labelText="Hello" />
          <StyledTreeItem nodeId="3" labelText="Subtree with children">
            <StyledTreeItem nodeId="6" labelText="Hello" />
            <StyledTreeItem nodeId="7" labelText="Sub-subtree with children">
              <StyledTreeItem nodeId="9" labelText="Child 1" />
              <StyledTreeItem nodeId="10" labelText="Child 2" />
              <StyledTreeItem nodeId="11" labelText="Child 3" />
            </StyledTreeItem>
            <StyledTreeItem nodeId="8" labelText="Hello" />
          </StyledTreeItem>
          <StyledTreeItem nodeId="4" labelText="World" />
          <StyledTreeItem nodeId="5" labelText="Something something" />
        </StyledTreeItem> */}
      </TreeView>
    );

  }
  else if (errors === true) {
    return (
      <div><a>{response}</a></div>
    )
  }
  else {
    return (
      <div><a>workin on it...</a></div>
    )
  }


}
