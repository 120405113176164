import React from 'react';
import TextField from '@mui/material/TextField';

// const theme = createTheme();

// const useStyles = makeStyles((theme) => ({
//     root: {
//         '& > *': {
//             margin: theme.spacing(1),
//             width: '25ch',
//         },
//     },
// }));

export default function CaseCollector(props) {

    //   const classes = useStyles();
    const onSubmit = (e) => {
        e.preventDefault();
        console.log("refresh prevented");
        if(!props.submitDisabled){
            props.onEnter();
        }
        
    };


    const handleChange = (event) => {

        let resultValue = [...event.target.value.matchAll(/\d{8}/g)].map(item => item[0])

        //console.log(resultValue);
        if (resultValue.length === 0) {
            resultValue = null;
        }
        props.setCases(resultValue)
    }

    return (

        <form noValidate autoComplete="off" onSubmit={onSubmit}>
            <TextField
                variant="standard"
                id="standard-basic"
                label="Provide Specific Cases"
                helperText="Provide 7 digit case numbers with any delimiter"
                // onKeyPress={(e) => {
                //     if (e.key === 'Enter') {
                //         console.log('Enter key pressed');
                //         // write your functionality here
                //     }
                // }}
                onChange={handleChange} />
        </form>

    );
}