import Typography from '@mui/material/Typography';


// helper function to convert a string to terminal output
export const convertTextToTerminalOutput = (text: string | undefined, key: number | string, color?: string): JSX.Element => {
    return (<div style={{ display: "flex" }} key={key}>
        <Typography variant='body2' color={color ? color : 'red'} noWrap>&gt;&nbsp;</Typography>
        <Typography variant='body2'>{text}</Typography>
    </div>);
}

export const convertTextToTerminalGuideText = (text: string | undefined, key: number | string): JSX.Element => {
    return (
      <Typography key={"valid_input"+key} variant='caption'>{text}</Typography>
);
}


export function validValueForInputType (currentCommandLineText: string, inputCollectionType: string): Boolean {
    // console.log('testing date ', currentCommandLineText, new Date(currentCommandLineText).toString())
    if(inputCollectionType === 'date' || inputCollectionType === 'datetime-local'){
        // console.log('testing date ', new Date(currentCommandLineText).toString())
        let dateValue = new Date(currentCommandLineText)
        if( dateValue.toString() === 'Invalid Date' || dateValue.getFullYear() < 1900 || dateValue.getFullYear() > 2100){
            return false;
        }
    
    } else if (inputCollectionType === 'number'){
        if (isNaN(Number(currentCommandLineText)) || currentCommandLineText.length === 0){
            return false;
        }
    } else if (inputCollectionType === 'time'){
        // validate that the inputvalue is a valid time
        const timeReg = new RegExp('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$');
        if(!timeReg.test(currentCommandLineText)){
            return false;
        }   
    }
    return true;
}
