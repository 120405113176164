import React, { } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MaterialTable from '@material-table/core';
import { useVersions } from '../../../data/ServiceComponent'




function Deployments(props) {
    //   const classes = useStyles();
    const { deployment, handleSelection, prd, showVersionDescription } = props;

    let [versions] = useVersions(prd, deployment);


    const versionHistoryColumns = [
        { title: 'Version Number', field: 'versionNo' },
        { title: 'Date', field: 'date', type: "datetime", dateSetting: { locale: 'en-US' } },
        { title: 'Added By', field: 'addedBy' },
        { title: 'Description', field: 'description' },
        { title: 'Active', field: 'activeVersionFlag' },
        { title: 'OPA Version', field: 'opaVersion'},
        { title: 'Active', field: 'activeVersionFlag' },
    ]


    const columns = [
        { title: 'Version Number', field: 'versionNo' },
        { title: 'Active', field: 'activeVersionFlag' },
        { title: 'Date', field: 'date', type: "datetime", dateSetting: { locale: 'en-US' } },
        { title: 'OPA Version', field: 'opaVersion'},
    ];

    const handleCaseSelectionChange = (rows) => {
        
        
        const updatedVersoinsList = versions.data.filter(diff => rows.map(row => row.versionNo).indexOf(diff.versionNo) >= 0).map(item => {
            return (item.versionNo);
        })

        
        handleSelection(updatedVersoinsList, deployment)


    }

    const materialValue = versions && versions.data ?
        <Box sx={{alignContent: "right", alignItems: "right"}}>
            <Grid container>
                <Grid item xs={12} sx={{marginLeft: '1em', marginRight: 0, marginBottom: '3em', marginTop: '1em'}}>
                    <MaterialTable
                        title={'Versions for '+deployment}
                        columns={ !showVersionDescription ? columns : versionHistoryColumns }
                        data={ versions.data }
                        options={{
                            selection: true
                          }}
                        onSelectionChange={(rows) => handleCaseSelectionChange(rows)}
                    />
                </Grid>
            </Grid>
        </Box> :
        '...Loading'

    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    {materialValue}
                </Grid>
            </Grid>
        </Box>
    );
}

export default Deployments;