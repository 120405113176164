import React, { useEffect } from 'react';
import Launcher from './Launcher/Launcher';
import Terminal from './Terminal/Terminal';


function Chatbot(props) {

    let [showTerminal, setShowTerminal] = React.useState(false);

    useEffect(() => {
        //TODO: Add a function to show the chatbot Terminal
        console.log("Chatbot.js useEffect", showTerminal);
    }, [showTerminal]);
    return (
        <div>
            <Launcher
                toggleAction={() => setShowTerminal(!showTerminal)}
            />
            {showTerminal ? <Terminal 
                setShowTerminalFunction={setShowTerminal} 
                showTerminal={showTerminal} /> 
                : null
            }
        </div>
    );
}

export default Chatbot;