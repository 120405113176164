import React, { useEffect }  from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';






function EnvironmentSelector(props) {

    const [anchorElEnv, setAnchorElEnv] = React.useState(null);

    const openEnv = Boolean(anchorElEnv);
    const [environment, setEnvronment] = React.useState("");

    const handleCloseEnv = () => {
        setAnchorElEnv(null);
    };

    const handleClickEnv = (event) => {
        setAnchorElEnv(event.currentTarget);
    };



    const setEnvironmentFromMenu = (env) => {
        setEnvronment(env);
        props.environmentSetter(env);
        handleCloseEnv();
    }


    useEffect(() => {
        if(props.defaultEnvironment) {
            setEnvronment(props.defaultEnvironment);
            props.environmentSetter(props.defaultEnvironment);
        } else {
            setEnvronment('sit2');
            props.environmentSetter('sit2');
        
        } }, [])



    return (
        <div>
            <Button
                style={{ alignItems: 'center', display: 'flex'}}
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={openEnv ? 'true' : undefined}
                onClick={handleClickEnv}
            >
                {environment !== null ? "Env - " + environment : "Environment"}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorElEnv}
                open={openEnv}
                onClose={handleCloseEnv}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
              <MenuItem onClick={() => setEnvironmentFromMenu("uat0")}>UAT0</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("uat1")}>UAT1</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("uat2")}>UAT2</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("uat3")}>UAT3</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("uat4")}>UAT4</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("sit1")}>SIT1</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("sit2")}>SIT2</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("sit3")}>SIT3</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("sit4")}>SIT4</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("dev1")}>DEV1</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("dev2")}>DEV2</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("dev3")}>DEV3</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("stg")}>STG</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("prd")}>PRD</MenuItem>
              <MenuItem onClick={() => setEnvironmentFromMenu("local")}>Local</MenuItem>
            </Menu>
        </div>
    );
}

export default EnvironmentSelector;