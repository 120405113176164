import React, { useEffect } from 'react';
import Box from '@mui/material/Box';

import rulesData from "../data/rulebases.json";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDeployments } from '../data/ServiceComponent';

// const useStyles = makeStyles((theme) => ({
//     formControl: {
//         margin: theme.spacing(1),
//         minWidth: '20em',
//         width: "max-content",
//     },
//     chips: {
//         display: 'flex',
//         flexWrap: 'wrap',
//     },
//     chip: {
//         margin: 2,
//     },
//     noLabel: {
//         marginTop: theme.spacing(3),
//     },
// }));


// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {

//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };


function SelectServices(props) {
 //   const classes = useStyles();
    const [serviceOne, setServiceOne] = React.useState(null);
    const [serviceTwo, setServiceTwo] = React.useState(null);
    const [rulebase, setRulebase] = React.useState("");
    const [services] = useDeployments(false);

    useEffect(() => {

    }, []);

    const handleChangeServOne = (event, value) => {
        if(value !== null)
        {
            setServiceOne(value.key);
            for(let ruleVal in rulesData[0].rulebases)
            {
                // console.log("ruleVal", rulesData[0].rulebases[ruleVal], value.key);
                if(value.key.includes(rulesData[0].rulebases[ruleVal]))
                {
                    // console.log("ruleVal Inside", rulesData[0].rulebases[ruleVal]);
                    setRulebase(rulesData[0].rulebases[ruleVal]);
                    // set the size of the query based on rulebase name.
                    // i've found that child care hits a size limit at 100 in some rare scenarios.
                    props.casesPerQuery( rulesData[0].rulebases[ruleVal] === 'KEES_EDBC_CHILD_CARE_CORE' ? 50 : 50 );
                    props.rulebase(rulesData[0].rulebases[ruleVal]);
                    break;
                }
            }
            props.servOne(value.key);
        } else {
            setServiceOne("");
            setRulebase("");
            props.servOne("");
        }
    };

    const handleChangeServTwo = (event, value) => {
        if(value !== null)
        {
            setServiceTwo(value.key);
            props.servTwo(value.key)
        } else {
            setServiceTwo("");
            props.servTwo("")
        }

    };

    return (
        services ?
        <Box sx={{ minWidth: '10em', maxWidth: '25em' }}>
            <div >
                <Autocomplete
                    disablePortal
                    id="combo-box-serv-one"
                    onChange={(event, value) => handleChangeServOne(event, value)}
                    isOptionEqualToValue={(option, value) => option.key === value.key}
                    options={services.data.filter(serv => serv.name !== serviceTwo  ).map((serv) => (
                        {
                            "label": serv.name,
                            "key": serv.name
                        }
                    ))
                    }
                    renderInput={(params) => <TextField {...params} label="Service One"  /> }

                >
                </Autocomplete>
            </div>
            <div>
                <Autocomplete
                    sx={{marginTop: '12px'}}
                    disablePortal
                    id="combo-box-serv-tw"
                    onChange={(event, value) => handleChangeServTwo(event, value)}
                    isOptionEqualToValue={(option, value) => option.key === value.key}
                    options={services.data.filter( serv => serv.name.includes(rulebase) ).filter( serv => serv.name !== serviceOne ).map((serv) => (
                        {
                            "label": serv.name,
                            "key": serv.name
                        }
                    ))
                    }
                    renderInput={(params) => <TextField {...params} label="Service Two"  /> }
                >
                </Autocomplete>
            </div>
        </Box> : ''
    );
}

export default SelectServices;