import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import AdvancedDifferenceTable from './AdvancedDifferenceTable'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


function AdvancedDifferenceViewer(props) {
    //   const classes = useStyles();

    const [selectedAttribute, setSelectedAttribute] = useState(null);
    // const [collectInputs, setCollectInputs] =useState(false);
    // const [selectedRows, setCollectedRows] = useState([]);
    // const [resetDefaults, setResetDefaults] = useState(false);


    const [columns, setColumns] = useState([]);
    //     { title: 'Case Name', field: 'caseName' },
    //     { title: 'Attribute', field: 'attribute', defaultGroupOrder: 0 },
    //     { title: props.services[0], field: 'lhs' },
    //     { title: props.services[1], field: 'rhs' }
    // ];
    const [complexDataValues, setComplexDataValues] = useState([]);
    const [matchAttributes, setMatchAttributes] = useState([]);

    const [childObjects, setChildObjects] = useState([]);


    const theme = useTheme();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                maxWidth: 450,
            },
        },
    };

    function getStyles(attribute, personName, theme) {
        return {
            fontWeight:
                matchAttributes.indexOf(attribute) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const findFirstMatchingObject = (List, key, side) => {
        if (side === 'left') {
            return List.find(row => row.attribute === key && row?.lhsList.length > 0)
        } else {
            return List.find(row => row.attribute === key && row?.rhsList.length > 0)
        }

    }

    const pullColumnNamesAndListsFromKeys = (object) => {
        let columns = [{ title: 'Match', field: 'match' }, { title: 'Service', field: 'service' }, { title: 'EDBC ID', field: 'edbc_id' }];
        let objects = [];
        Object.entries(object).forEach(([key, value]) => {
            if (!Array.isArray(value)) {
                // don't need this thing which is an artifact from OPA
                if (key !== '@id') {
                    columns.push({ title: key, field: key })
                }
            } else {
                objects.push(key)
            }

        })
        return [columns, objects];
    }


    const cleanComplexDataAndPopulate = (AllMatchingDifferences, services, childObjectsLhs, side) => {

        let rows = [];
        AllMatchingDifferences.forEach((differenceEntry, parentIndex) => {

            const List = side === 'left' ? differenceEntry.lhsList : differenceEntry.rhsList;

            let newRows = List.map((row, index) => {
                let transformedRow = { ...row };
                // listObjects.map(objectKey => delete transformedRow[objectKey])

                return {
                    parentId: parentIndex,
                    edbc_id: differenceEntry.edbc_id,
                    match: false,
                    service: side === 'left' ? services[0] : services[1],
                    ...transformedRow
                }
            })

            // clean up objects for the table.  Objects throw an error
            newRows = newRows.map(row => {
                Object.entries(row).forEach(([key, value]) => {
                    console.log('testing key against child objects', key, childObjectsLhs, key.indexOf(childObjectsLhs))
                    if (typeof value === 'object' && !(key.indexOf(childObjectsLhs) >= 0)) {
                        row[key] = 'Unknown'
                    } else if (key === '@id') {
                        delete row[key];
                    }
                })
                return row;
            })

            rows = rows.concat(newRows);
        })



        return rows;
    }


    const handleChangeAttribute = (event, value) => {
        // reset the matchAttributes
        setMatchAttributes([])
        // console.log('testing change attribute select', event, value)

        // find Columns.  Just starting with LHS fow now.  May want to do something sneaky like combine or let a user pick
        const firstMatchingObject = findFirstMatchingObject(props.differences, value.key, 'left')

        // console.log('testing first match object', firstMatchingObject, firstMatchingObject.lhsList[0])
        // just passing the first instance of the matching object
        let [childColumnsLhs, childObjectsLhs] = pullColumnNamesAndListsFromKeys(firstMatchingObject.lhsList[0]);

        setChildObjects(childObjectsLhs);
        // pull all data
        const complexChildDatalhs = cleanComplexDataAndPopulate(props.differences.filter(row => row.attribute === value.key && row?.lhsList?.length > 0), props.services, childObjectsLhs, 'left')
        const complexChildDatarhs = cleanComplexDataAndPopulate(props.differences.filter(row => row.attribute === value.key && row?.rhsList?.length > 0), props.services, childObjectsLhs, 'right')

        let combinedComplexChildData = complexChildDatalhs.concat(complexChildDatarhs).map((row, index) => {
            return {
                id: index,
                ...row
            }
        });

        console.log('testing child data results', childColumnsLhs, combinedComplexChildData, childObjectsLhs);

        setColumns(childColumnsLhs)
        setComplexDataValues(combinedComplexChildData)

        setSelectedAttribute(value.key)
    }


    const handleMatchAttributesChange = (event) => {
        const {
            target: { value },
        } = event;
        setMatchAttributes(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    useEffect(() => {

        let calculatedData = []

        if (matchAttributes.length > 0) {
            calculatedData = complexDataValues.map(row => {
                const currentId = row.id;
                const parentId = row.parentId;
                const matchingEntry = complexDataValues.find(entry => {
                    let matching = true;
                    if (entry.id !== currentId && entry.parentId === parentId) {
                        matchAttributes.forEach(matchingKey => {
                            // console.log('testing match values', entry[matchingKey], row[matchingKey])
                            if (entry[matchingKey] !== row[matchingKey]) {
                                matching = false;
                            }
                        })
                    } else {
                        matching = false;
                    }
                    return matching;

                })

                if (matchingEntry) {
                    return {
                        ...row,
                        match: true
                    }
                } else {
                    return row
                }

            });
        } else {
            calculatedData = complexDataValues;
        }

        //  console.log('what we3 got here?', calculatedData)
        setComplexDataValues(calculatedData);

    }, [matchAttributes])

    const handleChangeChildAttribute = (event, value) => {
        // reset the matchAttributes
        setMatchAttributes([])

    }

    return (
        <Box>
            {props.differences ?
                <Grid container >

                    <Grid item xs={6} sx={{ textAlign: 'left', paddingBottom: '2em' }}>
                        <Box sx={{ minWidth: '10em', maxWidth: '25em' }}>
                            <FormControl sx={{ m: 1, width: 400 }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-diff-one"
                                    onChange={(event, value) => handleChangeAttribute(event, value)}
                                    isOptionEqualToValue={(option, value) => option.key === value.key}
                                    options={props.differences.filter(row => row.lhsList || row.rhsList).reduce((previousValue, current) => {

                                        if (previousValue.find(row => row.key === current.attribute)) {
                                            return previousValue
                                        } else {
                                            return previousValue.concat({
                                                "label": current.attribute,
                                                "key": current.attribute
                                            })
                                        }

                                    }, [])
                                    }
                                    renderInput={(params) => <TextField {...params} label="Select An Attribute" />}
                                >
                                </Autocomplete>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'middle', paddingBottom: '2em' }}>
                        <Box>
                            <FormControl sx={{ m: 1, width: 300 }}>
                                <InputLabel id="matchingAttributeLabel">{`Matching Attribute(s)`}</InputLabel>
                                <Select
                                    labelId="multipleAttributes"
                                    id="multipleAttributesSe;ect"
                                    multiple
                                    value={matchAttributes}
                                    onChange={handleMatchAttributesChange}
                                    input={<OutlinedInput label="Matching Attribute(s)" />}
                                    MenuProps={MenuProps}
                                >
                                    {columns.filter(column => column.title !== 'Match' && column.title !== 'Service').map((column) => (
                                        <MenuItem
                                            key={column.title}
                                            value={column.title}
                                            style={getStyles(column.title, matchAttributes, theme)}
                                        >
                                            {column.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        {selectedAttribute ?
                            <AdvancedDifferenceTable columns={columns} data={complexDataValues} services={props.services} attribute={selectedAttribute} childObjects={childObjects} />

                            :
                            <Typography>Select an Attribute to view complex differences</Typography>
                        }



                    </Grid>

                </Grid>
                :
                <Typography>First compare two services</Typography>}
        </Box >
    );
}

export default AdvancedDifferenceViewer;