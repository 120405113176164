import React, { useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { MainListItems } from './listItems';
import Chart from './Chart';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https:/sagasystechnologygroup.com/">
        Sagasys Technology Group
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 280;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

function DashboardContent() {
  const [open, setOpen] = React.useState(true);
  const [charts, setCharts] = React.useState([]);
  const [displayCharts, setDisplayCharts] = React.useState([]);
  const toggleDrawer = () => {
    setOpen(!open);
  };


  console.log('checking ')

  useEffect(() => {

    setDisplayCharts(charts?.map((chart, index) => {

      console.log('triggered displayCharts', chart)


      return (<Chart
        number={index} key={`chart-${index}`}
        metrics={chart.metrics ? chart.metrics : []}
        timePeriod={chart.timePeriod ? chart.timePeriod : []}
        groupBy={chart.groupBy ? chart.groupBy : []}
        actionType={chart.actionType ? chart.actionType : []}
        deployments={chart.deployments ? chart.deployments : []}
        name={chart.name}
        setTime={chart.setTime}
        setName={chart.setName}
        setReportObjectName={chart.setReportObjectName} />)
    }
    ));


  }, [charts]);


  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            {open ? <Typography style={{ flexGrow: '1', textAlign: 'center' }}>Reports Panel</Typography> : ''}
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MainListItems displayChips={open} applyReport={setCharts} />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth={"xl"} sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3} sx={{marginBottom: '15em'}} >
              {/* Chart */}
              {displayCharts && displayCharts.length > 0 ? displayCharts :

                <Grid item xs={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 640,
                    }}
                  >
                    <pre>
                      <center>
                        <code>{`
                         .
                     .
                /^\\     .
    /\\    "V"
            /__\\    I      O  o
       //..\\\\   I     .
 \\].\`[/   I
         /l\\/j\\  (]    .  O
           /. ~~ ,\\/ I          .
        \\\\L__j^\\/ I       o
           \\/--v}   I     o   .
             |    |   I   _________
               |    |   I c(\`       ')o
             |    l   I   \\.     ,/
            _/j  L l\\_ !  _//^---^\\\\_
    Pick or Build a Report
`
                        }</code>
                      </center>
                    </pre>
                  </Paper>
                </Grid>
              }
              {/* Recent Deposits */}
              {/* <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Deposits />
                </Paper>
              </Grid> */}
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider >
  );
}

export default function Dashboard() {

  return (
    <div>
      <DashboardContent />
      {/* <Dialog /> */}
    </div>

  );
}
