import Table from '../DatabaseCompare/DatabaseCompare'
import React from 'react';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import JSONViewer from './Components/JSONViewer';
import Button from '@mui/material/Button';
import CaseCollector from '../CaseCollector'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';

import EnvironmentSelector from '../EnvironmentSelector/EnvironmentSelector';

// const axios = require('axios');


const theme = createTheme();

function Strangle() {


  const [selectCases, setSelectCases] = React.useState(null);
  const [returnAll, setReturnAll] = React.useState(false);
  const [environment, setEnvronment] = React.useState("dev3");
  const [casesPerQuery, setCasesPerQuery] = React.useState(50);

  const [dayTimeValue, setDayTimeValue] = React.useState(dayjs().subtract(1, 'day'));

  let [isProcessing, setIsProcessing] = React.useState(false);
  let [showTable, setShowTable] = React.useState(false);
  let [isCancelled, setIsCancelled] = React.useState(false);

  // const [anchorElEnv, setAnchorElEnv] = React.useState(null);

  // const openEnv = Boolean(anchorElEnv);



  // const handleCloseEnv = () => {
  //   setAnchorElEnv(null);
  // };



  // const handleClickEnv = (event) => {
  //   setAnchorElEnv(event.currentTarget);
  // };

  const handleDateTimeChange = (newValue) => {
    setDayTimeValue(newValue);
  };

  const setEnvironmentFromMenu = (env) => {
    setEnvronment(env);
    // handleCloseEnv();
  }


  const [caseLimit, setCaseLimit] = React.useState(1000);
  const [startLimit, setStartLimit] = React.useState(0);
  const [anchorElStartLimit, setAnchorEStartlLimit] = React.useState(null);
  const [anchorElLimit, setAnchorElLimit] = React.useState(null);
  const [resetTable, setResetTable] = React.useState(false);
  const openLimit = Boolean(anchorElLimit);
  const openStartLimit = Boolean(anchorElStartLimit);

  const handleCloseLimit = () => {
    setAnchorElLimit(null);
  };

  const handleCloseStartLimit = () => {
    setAnchorEStartlLimit(null);
  };


  const handleClickLimit = (event) => {
    setAnchorElLimit(event.currentTarget);
  };

  const handleClickStartLimit = (event) => {
    setAnchorEStartlLimit(event.currentTarget);
  };

  const setCaseLimitFromLimit = (env) => {
    setCaseLimit(env);
    handleCloseLimit();
  }

  const setCaseLimitFromStartLimit = (env) => {
    setStartLimit(env);
    handleCloseStartLimit();
  }





  const getServices = () => {

    setIsCancelled(false);

    if (Array.isArray(selectCases)) {
      if (selectCases.length === 0) {
        setSelectCases(null)
      }
    }

    setIsProcessing(true)
    setResetTable(true);
    setShowTable(true);
  }


  const returnAllHandler = (event) => {
    setReturnAll(!returnAll);
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: '1em' }}>
        <Typography variant="h4" component="div" gutterBottom>Compare Noas</Typography>
        <div style={{ marginLeft: '1em' }} >
          <div style={{ display: 'flex' }} >

           
            <CaseCollector
              setCases={setSelectCases}
              onEnter={getServices}
              submitDisabled={isProcessing} />

          </div>
      
          <div style={{ display: 'flex', marginTop: '1em' }} >
            <EnvironmentSelector environmentSetter={setEnvironmentFromMenu} defaultEnvironment="dev3" />
            <span style={{ marginLeft: '1em' }}>


              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="NOA Created On Date"
                  inputFormat="MM/DD/YYYY"
                  value={dayTimeValue}
                  onChange={handleDateTimeChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </span>


          </div>
          <div style={{ display: 'flex', marginTop: '1em' }} >
            <FormControlLabel
              style={{ marginRight: '1px' }}
              control={
                <Switch
                  checked={!returnAll}
                  onChange={returnAllHandler}
                  name="returnAll"
                  color="primary"
                />
              }
              label={<Typography style={{ alignItems: 'center', display: 'flex', padding: '6px 8px', marginBottom: '0px' }} variant="button" gutterBottom >only show differences</Typography>}
            />
          </div>
        </div>

        <div style={{ marginTop: '2em', marginLeft: '1em', marginBottom: '1em' }}>
          <Button
            variant="contained"
            onClick={() => getServices()}
            tooltip='click to compare'
            disabled={isProcessing}
          >
            Compare
          </Button>

          <Button
            sx={{ marginLeft: '6px' }}
            variant="outlined" color="error"
            onClick={() => setIsCancelled(true)}
            tooltip='click to cancel current run'
            disabled={!isProcessing}
          >
            Cancel
          </Button>
        </div>
        <div>
          {showTable &&
            <Table
              specificCases={selectCases}
              returnAllCases={returnAll}
              environment={environment}
              startLimit={startLimit}
              numberOfCases={caseLimit}
              casesPerQuery={casesPerQuery}
              processingMode={setIsProcessing}
              cancelled={isCancelled}
              setIsCancelled={setIsCancelled}
              reset={resetTable}
              setResetTable={setResetTable}
              documentDate={dayjs(dayTimeValue).format('MM-DD-YYYY')}
            />}
        </div>


        {/* <div>
          {opaResults === null ? 'compare something' : <JSONViewer json={opaResults} />}
        </div> */}
      </div>
    </ThemeProvider>

  );
}

export default Strangle;
