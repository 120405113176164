import axios from "axios";
import * as React from "react";
import {
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";

interface AuthContextType {
    user: any;
    signin: (username: string, password: string, callback: VoidFunction) => void;
    signout(): void;
  }
  
let AuthContext = React.createContext<AuthContextType>(null!);
  
export function AuthProvider({ children }: { children: React.ReactNode }) {
    const usernameFromCookie = document.cookie.replace(/(?:(?:^|.*;\s*)username\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    let [user, setUser] = React.useState<{ username: string } | null>(usernameFromCookie ? { username: usernameFromCookie } : null);
    const navigate = useNavigate();
  
    let signin = (username: string, password: string, callback: (error?: string) => void) => {
      axios({
        method: 'post',
        url: "https://bendicator-back-gvavzwvxkq-uc.a.run.app/login",
        headers: { 'Content-Type': 'application/json' },
        data: {
          username,
          password,
        },
        withCredentials: true,
      }).then(res => {
        setUser({ username })
        callback('');
      }).catch(err => {
        console.log('testing the login response when failed', err)
        callback('*Unauthorized');
      })
    }
  
    let signout = () => {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_BACK_END_URL}/logout`,
        headers: { 'Content-Type': 'application/json' },
      }).then(res => {
        // delete username cookie
        document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        setUser(null)
        navigate('/login')
      })
    };
  
    let value = { user, signin, signout };
  
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
  }
  
  export function useAuth() {
    return React.useContext(AuthContext);
  }
  
  export function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();
  
    if (auth.user) return children;

    if (location.pathname !== '/login') {
      return <Navigate to="/login" replace />;
    } else {
      return null;
    }
  }